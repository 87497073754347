.auth-wrapper {
  background: $theme-background;
  .card-body {
    text-align: center;
  }

  .content {
    width: 100%;
    text-align: center;
  }

  form {
    width: 80%;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    padding: 53px 41px 29px 41px;
    margin: auto;
  }

  .input-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 80%;
    margin: auto;
  }

  svg {
    margin-right: 14px;
    z-index: 99;
  }

  img {
    width: 346px;
    margin: auto;
  }

  .form-control {
    display: inline !important;
    width: 388px !important;
    height: 40px !important;
    background: $theme-background;
    border: 1px solid #E0E0E0 !important;
    border-radius: 10px !important;
    padding-left: 40px;
  }

  .login_title {
    font-family: GraphieFontBold;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: $primary-color;
    margin: auto;
    margin-bottom: 40px;
    padding: 0;
  }

  button {
    padding: 10px;
    gap: 10px;
    background: $btn-color;
    border-radius: 10px;
    border-color: $primary-color;
  }

  .save_credential {
    padding: 0;
    margin: 29px 50px;
  }

  svg {
    position: absolute;
    left: 7px;
  }

  .form-control:focus {
    background-color: unset;
  }

  .password svg {
    left: 12px !important;
  }
}
