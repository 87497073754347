//@import "partials/variables";

.sequence-diagram {
  .sequence-legend {
    .title {
      font-size: 14px;
      font-weight: bolder;
      line-height: 1.5;
      text-transform: uppercase;
      color: $primary-color;
      padding-bottom: 10px;
      padding-top: 15px;
    }

    ul.keys {
      list-style: none;
      padding-left: 0;

      li.key {
        float: left;

        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 24px;
        padding-right: 1em;

        span.chiclet {
          float: left;
          width: 20px;
          height: 20px;
          margin: 2px;
          border-radius: 6px;
          padding-top: 4px;
          user-select: none;
        }
      }
    }
  }

  .sequence-graph {
    padding-top: 15px;
    height: 80px;
    white-space: normal;

    ul.points {
      list-style: none;
      padding-left: 0;

      .point {
        position: relative;
        float: left;
        width: 120px;
        height: 40px;
        margin: 2px;
        border-radius: 6px;
        padding: 8px 8px 8px 8px;
        user-select: none;
        vertical-align: middle;
        cursor: pointer;
        color: white;
        text-align: right;

        .count {
          display: block;
          padding: 5px 10px 0 0;
          color: white;
          font-size: 14px;
          font-weight: bold;
          margin-right: 1rem;
          text-align: right;
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }

      #sequence-search-clear {
        width: 30px;
        //color: blue;
        //background-color: green;
        height: 30px;
        position: relative;
        float: left;
      }

      li.point:not(:first-child) {
        margin-left: -15px;
        z-index: 0;
      }
    }
  }
}

table.dataTable {
  td > span.chiclet {
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 6px;
    padding-top: 4px;
    user-select: none;
  }
}
