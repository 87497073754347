table.dataTable {
    width: 100% !important;
    border-spacing: 0;
    border: 1px solid #15C042;
    border-radius: 14px;
    overflow: hidden;
    border-right-width: 1px !important;
}

.table-striped>tbody>tr.odd>* {
    box-shadow: none;
}

table.table-bordered.dataTable {

    th,
    td {
        text-align: center !important;
        padding: 7px 10px !important;
        vertical-align: middle;
    }

    th {
        height: 32px;
        background-color: #14c041 !important;
        border-color: #14c041 !important;
        color: white !important;
        font-weight: bold !important;
        font-family: "GraphieFontBold";
    }

    thead {
        background-color: #F4F5F7;
    }

    tbody {
        background-color: white;
    }
}

.lineAreaDashboard g text title, tspan {
    font: 14px normal GraphieFont, sans-serif;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #E8F9ED;
}

.page-item.active .page-link {
    background: #15C042;
    border-color: #15C042;
}

.page-link {
    color: #15C042;
}

.page-link:hover {
    color: #15C042;
}

.dataTables_length {
    position: relative;
}

.dataTables_length select {
    display: none;
}

.select-selected {
    position: relative;
    width: 74px;
    height: 32px;
}

.select-selected:after {
    position: absolute;
    content: "";
    top: 12px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #212529 transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
    border-color: transparent transparent #212529 transparent;
    top: 7px;
}

.select-items div,
.select-selected {
    padding: 6px 16px;
    cursor: pointer;
    user-select: none;
}

.select-selected {
    border: 1px solid rgba(203, 203, 203, 0.5);
    border-radius: 3px;
}

.select-items {
    position: absolute;
    color: #212529;
    background-color: #fff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    width: 74px;
    border: 1px solid #15C042;
}

.select-selected.select-arrow-active {
    border-color: #15C042;
}

.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: #15C042;
}