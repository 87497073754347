.div__logout__btn {
  text-align: center;
  width: 189px;
  margin: 19px auto;
}


.div_breadcrumb {
  display: flex;
  align-items: center;
}