.date-picker {
  .ui-datepicker {
    width: max-content;
  }

  .ui-widget {
    font-size: 1rem;
    font-family: $graphie-bold;
  }

  .ui-widget-header {
    font-size: 1.3rem;
  }

  .ui-widget-content {
    background: $primary-color;
    color: $light-color;
  }

  .ui-widget.ui-widget-content {
    border: 0;
    border-radius: 2em;
    padding: 5px 17px;
  }

  .ui-datepicker-header {
    background: transparent;
    border: 0;

    span {
      color: $light-color;
    }
  }

  .ui-datepicker th {
    text-transform: uppercase;
  }

  .ui-datepicker-title {
    text-transform: uppercase;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default {
    border: 0;
    background: transparent;
    font-weight: normal;
    color: $light-color;
  }

  .ui-datepicker tr {
    vertical-align: top;
  }

  .ui-datepicker th {
    height: 40px;
    font-size: 0.9rem;
  }

  .ui-datepicker td {
    padding: 3px !important;
    width: 42px;
  }

  .ui-datepicker td span,
  .ui-datepicker td a {
    text-align: center;
    min-width: 36px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .ui-datepicker .ui-datepicker-prev span,
  .ui-datepicker .ui-datepicker-next span {
    background-position: 0px 0px !important;
    background-size: contain;
    margin-left: 0;
    margin-top: 0;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
  }

  .ui-datepicker .ui-datepicker-prev span {
    background-image: url("../images/prev.png") !important;
  }
  
  .ui-datepicker .ui-datepicker-next span {
    background-image: url("../images/next.png") !important;
  }

  .ui-datepicker .ui-datepicker-prev.ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next.ui-datepicker-next-hover {
    background: transparent;
    border: 0;
    top: 2px;
    cursor: pointer;
  }

  .ui-datepicker .ui-datepicker-prev.ui-datepicker-prev-hover {
    left: 2px;
  }

  .ui-datepicker .ui-datepicker-next.ui-datepicker-next-hover {
    right: 2px;
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    border-radius: 100px;
    background: $light-color;
    color: $primary-color;
    width: 100%;
    padding: 0;
    width: 36px;
    height: 36px;
    aspect-ratio: 1 / 1;
  }

  .ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    opacity: 0.5;
  }

  .ui-state-disabled span, .ui-widget-content .ui-state-disabled span, .ui-widget-header .ui-state-disabled span {
    color: $dark-color;
  }
}
