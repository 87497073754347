.reports {
  .title {
    margin-bottom: 34px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #000000;
    font-family: GraphieFontBold, sans-serif;
  }

  .reports_search_form {
    label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 15px;
      font-family: GraphieFontBold, serif;
      font-style: normal;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }

  table {
    th {
      height: 32px;
    }

    th,
    td {
      vertical-align: middle;
    }
  }

  .search_result {
    table {
      width: 100% !important;
      border-spacing: 0;
      border: 1px solid #15C042;
      border-radius: 14px;
      overflow: hidden;
      border-right-width: 1px !important;
     }
     
     th,
     td {
      text-align: center !important;
      padding: 8px 11px !important;
      vertical-align: middle;
     }
     
     th {
      font-family: GraphieFontBold;
     }
     
     th:nth-of-type(2), th:nth-of-type(3) {
      padding-right: 28px !important;
     }
     
     thead {
      background-color: #F4F5F7;
     
     }
     
     tbody {
      background-color: white;
     }
     
     .table-striped tbody tr:nth-of-type(2n + 1) {
      background-color: #E8F9ED;
     }
     
     table#total_plans_table tbody td:nth-of-type(1),
     table#total_balance_table tbody td:nth-of-type(1) {
         text-align: left !important;
     }
     
     table#total_balance_table thead {
       display: none;
     }
  }

  #div_id_show_all {
    margin-top: 2.5rem;
  }

  .invalid-feedback {
    margin-top: -0.75rem;
    margin-bottom: 0.25rem;
  }

  table.dataTable {
    margin-top: 15px !important;
  }
}

.select2-container--default .select2-selection--multiple {
  height: 40px;
}

#add_fund, .reports, #manage_funds {
  .select2-container .select2-selection--single {
    height: 40px;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }
}

.page-link {
  color: #15C042;
  &.active {
    color: #15C042;
    background-color: white;
    border-color: #dee2e6;
    &:hover {
      background-color: #e9ecef;
    }
  }
}