#recurring-payment-form {
  color: $white-color;
  background-color: $primary-dark-color;
  padding: 10px 30px 30px 100px;
  margin-left: -80px;
  z-index: 0;
  border-radius: 2em;
  font-family: $graphie-bold;
  line-height: 1.75;

  p {
    color: $white-color;
    margin-bottom: 0;
  }

  & .disabled p {
    color: $default-color;
  }

  input,
  select {
    font-family: $theme-font-family;
    line-height: 1.5;
  }

  input.small {
    padding: 0;
    max-width: 25px;
    text-align: center;
    margin: 0;
  }
}

.option-set {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 12px;

  input[type="radio"] {
    margin-top: 5px;
  }
}

#calendar-payment-date {
  position: relative;
  z-index: 1;
}
