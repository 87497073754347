.pdf-preview-dialog {
  max-width: 90vw;

  .modal-content {
    height: 100%;

    .preview-header {
      height: 64px;
      padding: 12px 7px 12px 12px;
      background: #000000b3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 7px 7px 0 0;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: white;
      }
    }

    .preview-action {
      height: 64px;
      border-radius: 0;
      border-top-color: grey;
      border-top-width: 1px;
      border-top-style: solid;
      color: white;
      margin: 0;
      padding: 12px 7px 12px 12px;
      background: #000000b3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      input {
        height: 34px;
        margin-left: 5px;
      }
    }

    .preview-footer {
      height: 52px;
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: #000000b3;
      border-radius: 0 0 7px 7px;
    }
  }
}
.btn.btn-save {
  background: white;
  padding: 8px 16px;
}