@charset "UTF-8";
/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
/**  =====================
     Google Font
==========================  **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
@import url("../fonts/feather/css/feather.css");
@import url("../fonts/datta/datta-icon.css");
/**  =====================
      Custom css start
==========================  **/
body {
  font-family: "GraphieFont", sans-serif;
  font-size: 14px;
  color: #888;
  font-weight: 400;
  background: #F4FAFF;
  position: relative; }

*:focus {
  outline: none; }

a {
  text-decoration: none; }
  a:hover {
    outline: none;
    text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
  font-weight: 400; }

.main_title {
  color: #15C042;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "GraphieFontBold", sans-serif;
  font-size: 20px; }
  .main_title.--small {
    font-size: 16px;
    font-weight: 400;
    font-family: "GraphieFont", sans-serif; }

.main-body h4 {
  font-weight: bold; }

.main-body .sub-title {
  font-size: 16px; }

h1 {
  font-size: 48px; }

h2 {
  font-size: 44px; }

h3 {
  font-size: 26px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 14px; }

p {
  font-size: 14px; }

strong {
  font-weight: 400; }

.img-radius {
  border-radius: 50%; }

.page-header-title + .breadcrumb {
  background: transparent;
  padding: 0; }
  .page-header-title + .breadcrumb > .breadcrumb-item a {
    color: #888; }
  .page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
    color: #111;
    font-weight: 600; }

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }

.theme-bg2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }

.bg-body {
  background: #F4FAFF !important; }

/* ==========  card css start  =========== */
.anim-rotate {
  animation: anim-rotate 1s linear infinite; }

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg); } }

.anim-close-card {
  animation: anim-close-card 1.4s linear; }

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3); } }

p.text-muted {
  font-size: 13px; }

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  background: transparent; }
  .card .card-header {
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 25px;
    position: relative; }
    .card .card-header h5 {
      margin-bottom: 0;
      color: #000;
      font-size: 17px;
      font-weight: 400;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative; }
      .card .card-header h5 + small {
        display: block;
        line-height: 1.2;
        margin-top: 8px; }
      .card .card-header h5:after {
        content: "";
        background-color: #15C042;
        position: absolute;
        left: -25px;
        top: 0;
        width: 4px;
        height: 20px; }
    .card .card-header.borderless {
      border-bottom: none; }
      .card .card-header.borderless h5:after {
        display: none; }
    .card .card-header .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-block;
      float: right;
      padding: 0;
      position: absolute; }
      @media only screen and (max-width: 575px) {
        .card .card-header .card-header-right {
          display: none; } }
      .card .card-header .card-header-right .dropdown-menu {
        margin-top: 0; }
        .card .card-header .card-header-right .dropdown-menu li a {
          font-size: 14px;
          text-transform: capitalize; }
      .card .card-header .card-header-right .btn.dropdown-toggle, .card .card-header .card-header-right .dropdown-toggle.introjs-button {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888; }
        .card .card-header .card-header-right .btn.dropdown-toggle i, .card .card-header .card-header-right .dropdown-toggle.introjs-button i {
          margin-right: 0; }
        .card .card-header .card-header-right .btn.dropdown-toggle:after, .card .card-header .card-header-right .dropdown-toggle.introjs-button:after {
          display: none; }
        .card .card-header .card-header-right .btn.dropdown-toggle:focus, .card .card-header .card-header-right .dropdown-toggle.introjs-button:focus {
          box-shadow: none;
          outline: none; }
  .card .card-footer {
    border-top: 1px solid #f1f1f1;
    background: transparent;
    padding: 25px; }
  .card .card-block,
  .card .card-body {
    padding: 30px 25px; }
  .card.card-load {
    position: relative;
    overflow: hidden; }
    .card.card-load .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 999; }
      .card.card-load .card-loader i {
        margin: 0 auto;
        color: #15C042;
        font-size: 24px;
        align-items: center;
        display: flex; }
  .card.full-card {
    z-index: 99999;
    border-radius: 0; }

/* ==========  scrollbar End  =========== */
.scroll-y {
  z-index: 1027; }

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */
.dropdown-menu {
  padding: 20px 0;
  margin-top: 5px !important;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none; }
  .dropdown-menu.show:before {
    content: "\63";
    font-family: "pct";
    position: absolute;
    left: 15px;
    top: -5px;
    z-index: 1001;
    font-size: 40px;
    line-height: 0;
    color: #fff;
    text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12); }
  .dropdown-menu.show.dropdown-menu-end:before {
    left: auto;
    right: 5px; }
  .dropdown-menu[data-popper-placement="top-start"] {
    margin-bottom: 24px !important;
    margin-top: 0 !important; }
    .dropdown-menu[data-popper-placement="top-start"].show:before {
      content: "\64";
      bottom: -5px;
      top: auto;
      text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12); }
  .dropdown-menu > li {
    padding-left: 15px;
    padding-right: 15px; }
    .dropdown-menu > li > a {
      padding: 5px;
      color: #888; }
      .dropdown-menu > li > a i {
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px; }
    .dropdown-menu > li.active, .dropdown-menu > li:active, .dropdown-menu > li:focus, .dropdown-menu > li:hover {
      background: rgba(21, 192, 66, 0.1); }
      .dropdown-menu > li.active > a, .dropdown-menu > li:active > a, .dropdown-menu > li:focus > a, .dropdown-menu > li:hover > a {
        background: transparent; }

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px; } }

@media (max-width: 992px) {
  .container {
    max-width: 100%; } }

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #F4FAFF;
  padding: 15px;
  margin-bottom: 15px; }
  .bd-example-row .row > [class^="col-"],
  .bd-example-row .row > .col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(86, 61, 124, 0.15);
    border: 1px solid rgba(86, 61, 124, 0.2); }
  .bd-example-row .row + .row {
    margin-top: 1rem; }
  .bd-example-row .flex-items-bottom,
  .bd-example-row .flex-items-middle,
  .bd-example-row .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, 0.1); }

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1); }

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0; }
  @media only screen and (max-height: 575px) {
    .bd-example {
      padding: 1.5rem;
      margin-right: 0;
      margin-left: 0;
      border-width: 0.2rem; } }

/* Example modals */
.modal {
  z-index: 1052; }
  .modal .popover,
  .modal .tooltip {
    z-index: 1053; }

.modal-backdrop {
  z-index: 1051; }

.bd-example-modal {
  background-color: #fafafa; }
  .bd-example-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block; }
  .bd-example-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto; }

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem; }

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  @media only screen and (max-height: 575px) {
    .highlight {
      padding: 1.5rem; } }

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px; }
  @media only screen and (max-height: 575px) {
    .bd-content .highlight {
      margin-right: 0;
      margin-left: 0; } }

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0; }

.highlight pre code {
  font-size: inherit;
  color: #333; }

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */
.datta-example {
  position: relative; }
  .datta-example .datta-example-btns {
    position: absolute;
    right: 30px;
    top: -10px;
    opacity: 0;
    transform: rotateX(-90deg);
    transition: all 0.3s ease-in-out; }
    .datta-example .datta-example-btns .datta-example-btn {
      display: inline-block;
      line-height: 1;
      font-weight: 600;
      background: #15C042;
      color: #fff;
      padding: 0.1875rem 0.3125rem;
      border-radius: 2px;
      white-space: nowrap;
      font-size: 11px;
      margin: 0 4px; }
      .datta-example .datta-example-btns .datta-example-btn.copy::before {
        content: "COPY"; }
      .datta-example .datta-example-btns .datta-example-btn.copied {
        background: #15C042 !important;
        color: #fff !important; }
        .datta-example .datta-example-btns .datta-example-btn.copied::before {
          content: "COPIED!"; }
  .datta-example:hover .datta-example-btns {
    top: -21px;
    transform: rotateX(0deg);
    opacity: 1; }

/* Modal */
.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  transform-origin: 50% 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out; }

.datta-example-modal-opened {
  overflow: hidden !important; }
  .datta-example-modal-opened .datta-example-modal {
    transform: scale(1); }

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto; }
  .datta-example-modal-content > pre {
    overflow: hidden;
    width: fit-content; }
    .datta-example-modal-content > pre > code {
      padding: 0;
      background: none;
      font-size: 16px; }

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #15C042;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap; }
  .md-datta-example-modal-copy:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    color: #fff; }
  .md-datta-example-modal-copy.copied::before {
    content: "Copied to Clipboard Successfully ! . . .";
    position: absolute;
    display: block;
    right: 100%;
    margin-right: 10px;
    font-size: 14px;
    background: #15C042;
    line-height: 24px;
    height: 24px;
    border-radius: 3px;
    padding: 0 6px;
    top: 50%;
    margin-top: -12px; }

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  z-index: 1; }
  .datta-example-modal-close:hover {
    color: #fff;
    opacity: 0.9; }

/* Code */
.cui-bottom-spacer {
  height: 12rem; }

/* editor style for model */
.hljs {
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34; }

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic; }

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd; }

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75; }

.hljs-literal {
  color: #56b6c2; }

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379; }

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b; }

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66; }

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee; }

.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: bold; }

.hljs-link {
  text-decoration: underline; }

/* ================================    md animated mode start   ===================== */
.md-show.md-effect-12 ~ .md-overlay {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }

/* ================================    md animated mode End   ===================== */
/* ================================    owl-carousel slider Start  ===================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px; }

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */
.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: #f0f3f6; }
  .bootstrap-tagsinput .tag {
    padding: 5px 12px;
    border-radius: 2px;
    line-height: 37px;
    margin-top: 5px;
    margin-right: 5px; }
    .bootstrap-tagsinput .tag [data-role="remove"] {
      margin-right: -5px; }
      .bootstrap-tagsinput .tag [data-role="remove"]:after {
        content: "\e8f6";
        padding: 0 2px;
        font-family: "feather" !important; }

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */
.ms-container {
  width: 100%; }

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #eaeaea;
  padding: 0.5rem 1rem; }
  .blockquote.text-end {
    border-left: none;
    border-right: 0.25rem solid #eaeaea; }

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0; }
  .ie-warning p {
    font-size: 17px; }
  .ie-warning h1 {
    color: #fff; }
  .ie-warning .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0; }
  .ie-warning .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px; }
    .ie-warning .iew-download > li {
      float: left;
      vertical-align: top; }
      .ie-warning .iew-download > li > a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0; }
        .ie-warning .iew-download > li > a > div {
          margin-top: 10px; }
        .ie-warning .iew-download > li > a:hover {
          background-color: #eee; }

/* ================================    browser  warning  End  ===================== */
/* material icon for material datetime picker */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2"); }

@font-face {
  font-family: GraphieFont;
  src: url("../fonts/Graphie.otf"); }

@font-face {
  font-family: GraphieFontBold;
  src: url("../fonts/Graphie-Bold.otf"); }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; }

/* material icon for material datetime picker */
.lb-cancel {
  background: url("../../images/loading.gif") no-repeat; }

.lb-nav a.lb-prev {
  background: url("../../images/prev.png") left 48% no-repeat; }

.lb-nav a.lb-next {
  background: url("../../images/next.png") right 48% no-repeat; }

.lb-data .lb-caption a {
  color: #7267ef; }

.lb-data .lb-close {
  background: url("../../images/close.png") top right no-repeat; }

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-image: url("../../images/32px.png"); }

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("../../images/throbber.gif") center center no-repeat; }

.jstree-default .jstree-node {
  background-position: -292px -4px;
  background-repeat: repeat-y; }

.media {
  display: flex; }
  .media .media-body {
    flex: 1; }

.form-group {
  margin-bottom: 1rem; }

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto; }

.notifier-container {
  z-index: 1030; }

.notifier-close {
  line-height: 1;
  font-size: 14px;
  padding-bottom: 3px; }

.check-task input:checked ~ * {
  text-decoration: line-through; }

a[data-lightbox] {
  cursor: pointer; }

.calendar a:not([href]):not([tabindex]),
.calendar a:not([href]):not([tabindex]):focus,
.calendar a:not([href]):not([tabindex]):hover {
  color: #111 !important; }

@media screen and (max-width: 768px) {
  .fc .fc-toolbar.fc-header-toolbar {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk + .fc-toolbar-chunk {
      margin-top: 8px; }
    .fc .fc-toolbar.fc-header-toolbar .fc-today-button {
      margin-bottom: 0; }
  .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
    display: none; } }

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  top: 15px; }

.custom-select, .dataTable-selector, .dataTable-input, .form-control {
  padding: 10px 16px;
  font-size: 14px;
  height: auto;
  box-shadow: 0 1px 2px 0 rgba(57, 70, 92, 0.05); }

.form-control:focus {
  border-color: #15C042; }

/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.m-0 {
  margin: 0px; }

.m-t-0 {
  margin-top: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-r-0 {
  margin-right: 0px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.m-5 {
  margin: 5px; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.m-10 {
  margin: 10px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.m-15 {
  margin: 15px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.m-20 {
  margin: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.m-25 {
  margin: 25px; }

.m-t-25 {
  margin-top: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-r-25 {
  margin-right: 25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.m-30 {
  margin: 30px; }

.m-t-30 {
  margin-top: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-r-30 {
  margin-right: 30px; }

.p-35 {
  padding: 35px; }

.p-t-35 {
  padding-top: 35px; }

.p-b-35 {
  padding-bottom: 35px; }

.p-l-35 {
  padding-left: 35px; }

.p-r-35 {
  padding-right: 35px; }

.m-35 {
  margin: 35px; }

.m-t-35 {
  margin-top: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-r-35 {
  margin-right: 35px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.m-40 {
  margin: 40px; }

.m-t-40 {
  margin-top: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-r-40 {
  margin-right: 40px; }

.p-45 {
  padding: 45px; }

.p-t-45 {
  padding-top: 45px; }

.p-b-45 {
  padding-bottom: 45px; }

.p-l-45 {
  padding-left: 45px; }

.p-r-45 {
  padding-right: 45px; }

.m-45 {
  margin: 45px; }

.m-t-45 {
  margin-top: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-r-45 {
  margin-right: 45px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.m-50 {
  margin: 50px; }

.m-t-50 {
  margin-top: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-r-50 {
  margin-right: 50px; }

/*====== Padding , Margin css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-c-blue {
  background: #15C042; }

.text-c-blue {
  color: #15C042; }

.bg-c-red {
  background: #f44236; }

.text-c-red {
  color: #f44236; }

.bg-c-green {
  background: #15C042; }

.text-c-green {
  color: #15C042; }

.bg-c-yellow {
  background: #f4c22b; }

.text-c-yellow {
  color: #f4c22b; }

.bg-c-purple {
  background: #a389d4; }

.text-c-purple {
  color: #a389d4; }

/*====== text-color css ends ======*/
/*====== Card top border css starts ======*/
.card-border-c-blue {
  border-top: 4px solid #15C042; }

.card-border-c-red {
  border-top: 4px solid #f44236; }

.card-border-c-green {
  border-top: 4px solid #15C042; }

.card-border-c-yellow {
  border-top: 4px solid #f4c22b; }

.card-border-c-purple {
  border-top: 4px solid #a389d4; }

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px; }

.f-12 {
  font-size: 12px; }

.f-14 {
  font-size: 14px; }

.f-16 {
  font-size: 16px; }

.f-18 {
  font-size: 18px; }

.f-20 {
  font-size: 20px; }

.f-22 {
  font-size: 22px; }

.f-24 {
  font-size: 24px; }

.f-26 {
  font-size: 26px; }

.f-28 {
  font-size: 28px; }

.f-30 {
  font-size: 30px; }

.f-32 {
  font-size: 32px; }

.f-34 {
  font-size: 34px; }

.f-36 {
  font-size: 36px; }

.f-38 {
  font-size: 38px; }

.f-40 {
  font-size: 40px; }

.f-42 {
  font-size: 42px; }

.f-44 {
  font-size: 44px; }

.f-46 {
  font-size: 46px; }

.f-48 {
  font-size: 48px; }

.f-50 {
  font-size: 50px; }

.f-52 {
  font-size: 52px; }

.f-54 {
  font-size: 54px; }

.f-56 {
  font-size: 56px; }

.f-58 {
  font-size: 58px; }

.f-60 {
  font-size: 60px; }

.f-62 {
  font-size: 62px; }

.f-64 {
  font-size: 64px; }

.f-66 {
  font-size: 66px; }

.f-68 {
  font-size: 68px; }

.f-70 {
  font-size: 70px; }

.f-72 {
  font-size: 72px; }

.f-74 {
  font-size: 74px; }

.f-76 {
  font-size: 76px; }

.f-78 {
  font-size: 78px; }

.f-80 {
  font-size: 80px; }

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100; }

.f-w-200 {
  font-weight: 200; }

.f-w-300 {
  font-weight: 300; }

.f-w-400 {
  font-weight: 400; }

.f-w-500 {
  font-weight: 500; }

.f-w-600 {
  font-weight: 600; }

.f-w-700 {
  font-weight: 700; }

.f-w-800 {
  font-weight: 800; }

.f-w-900 {
  font-weight: 900; }

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.wid-20 {
  width: 20px; }

.hei-20 {
  height: 20px; }

.wid-25 {
  width: 25px; }

.hei-25 {
  height: 25px; }

.wid-30 {
  width: 30px; }

.hei-30 {
  height: 30px; }

.wid-35 {
  width: 35px; }

.hei-35 {
  height: 35px; }

.wid-40 {
  width: 40px; }

.hei-40 {
  height: 40px; }

.wid-45 {
  width: 45px; }

.hei-45 {
  height: 45px; }

.wid-50 {
  width: 50px; }

.hei-50 {
  height: 50px; }

.wid-55 {
  width: 55px; }

.hei-55 {
  height: 55px; }

.wid-60 {
  width: 60px; }

.hei-60 {
  height: 60px; }

.wid-65 {
  width: 65px; }

.hei-65 {
  height: 65px; }

.wid-70 {
  width: 70px; }

.hei-70 {
  height: 70px; }

.wid-75 {
  width: 75px; }

.hei-75 {
  height: 75px; }

.wid-80 {
  width: 80px; }

.hei-80 {
  height: 80px; }

.wid-85 {
  width: 85px; }

.hei-85 {
  height: 85px; }

.wid-90 {
  width: 90px; }

.hei-90 {
  height: 90px; }

.wid-95 {
  width: 95px; }

.hei-95 {
  height: 95px; }

.wid-100 {
  width: 100px; }

.hei-100 {
  height: 100px; }

.wid-105 {
  width: 105px; }

.hei-105 {
  height: 105px; }

.wid-110 {
  width: 110px; }

.hei-110 {
  height: 110px; }

.wid-115 {
  width: 115px; }

.hei-115 {
  height: 115px; }

.wid-120 {
  width: 120px; }

.hei-120 {
  height: 120px; }

.wid-125 {
  width: 125px; }

.hei-125 {
  height: 125px; }

.wid-130 {
  width: 130px; }

.hei-130 {
  height: 130px; }

.wid-135 {
  width: 135px; }

.hei-135 {
  height: 135px; }

.wid-140 {
  width: 140px; }

.hei-140 {
  height: 140px; }

.wid-145 {
  width: 145px; }

.hei-145 {
  height: 145px; }

.wid-150 {
  width: 150px; }

.hei-150 {
  height: 150px; }

/*====== width, Height css ends ======*/
/* new logo start */
.b-brand {
  display: flex;
  align-items: center; }
  .b-brand .b-bg {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .b-brand .b-bg i {
      color: #fff; }
  .b-brand .b-title {
    margin-left: 10px;
    font-weight: 100;
    color: #fff;
    font-size: 18px; }

.navbar-collapsed .b-brand .b-title {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.navbar-collapsed .mobile-menu {
  transform: rotateY(-90deg);
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.navbar-collapsed:hover .b-brand .b-title {
  transform: rotateY(0deg);
  opacity: 1; }

.navbar-collapsed:hover .mobile-menu {
  transition-delay: 0.3s;
  transform: rotateY(0deg);
  opacity: 1; }

/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 1035;
  background-color: #15C042; }
  .loader-bg .loader-track {
    position: relative;
    height: 3px;
    display: block;
    width: 100%;
    overflow: hidden; }
    .loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
      content: '';
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right; }
    .loader-bg .loader-track .loader-fill:before {
      animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .loader-bg .loader-track .loader-fill:after {
      animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s; }

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -35%; } }

@keyframes m_s {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

/* Pre-loader css end */
/* header css start */
.pcoded-header {
  z-index: 1028;
  position: relative;
  display: flex;
  min-height: 100px;
  padding: 4px 42px 4px 33.6px;
  top: 0;
  background: transparent;
  color: #3f4d67;
  width: calc(100% - 256px);
  margin-left: 256px;
  justify-content: space-between;
  transition: all 0.3s ease-in-out; }
  .pcoded-header .m-header {
    display: none; }
    .pcoded-header .m-header .logo-dark,
    .pcoded-header .m-header .logo-thumb {
      display: none; }
  .pcoded-header .input-group {
    background: transparent; }
    .pcoded-header .input-group .input-group-text {
      margin-right: 0; }
  .pcoded-header .input-group {
    background: transparent; }
  .pcoded-header .input-group .input-group-text,
  .pcoded-header a,
  .pcoded-header dropdown-toggle {
    color: #3f4d67; }
    .pcoded-header .input-group .input-group-text:hover,
    .pcoded-header a:hover,
    .pcoded-header dropdown-toggle:hover {
      color: #15C042; }
  .pcoded-header #mobile-header {
    display: none; }
  .pcoded-header .navbar-nav {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    display: inline-block; }
    .pcoded-header .navbar-nav > li {
      display: inline-block; }
      .pcoded-header .navbar-nav > li .nav-link {
        padding: 0; }
      .pcoded-header .navbar-nav > li:last-child {
        padding-right: 16px; }
  .pcoded-header .me-auto .dropdown-menu {
    margin-left: -20px; }
  .pcoded-header .ml-auto {
    float: right; }
    .pcoded-header .ml-auto .dropdown-menu {
      margin-right: -20px; }
  .pcoded-header .main-search .input-group {
    border-radius: 20px;
    padding: 0; }
    .pcoded-header .main-search .input-group .form-control,
    .pcoded-header .main-search .input-group .input-group-text {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0; }
    .pcoded-header .main-search .input-group .search-close {
      display: none; }
    .pcoded-header .main-search .input-group .search-btn {
      border-radius: 50% !important;
      padding: 0;
      background: transparent;
      border-color: transparent;
      box-shadow: none; }
    .pcoded-header .main-search .input-group .form-control {
      transition: all 0.3s ease-in-out;
      width: 0;
      font-size: 14px; }
    .pcoded-header .main-search .input-group .form-control:active, .pcoded-header .main-search .input-group .form-control:focus, .pcoded-header .main-search .input-group .form-control:hover,
    .pcoded-header .main-search .input-group .search-btn:active,
    .pcoded-header .main-search .input-group .search-btn:focus,
    .pcoded-header .main-search .input-group .search-btn:hover {
      outline: none;
      box-shadow: none; }
  .pcoded-header .main-search.open .input-group {
    background: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
    padding: 5px 5px 5px 20px; }
    .pcoded-header .main-search.open .input-group .search-close {
      display: flex; }
    .pcoded-header .main-search.open .input-group .search-btn {
      padding: 5px;
      margin-left: 5px;
      background: #15C042;
      border-color: #15C042; }
      .pcoded-header .main-search.open .input-group .search-btn .input-group-text {
        color: #fff; }
    .pcoded-header .main-search.open .input-group .form-control {
      transition: all 0.3s ease-in-out;
      width: 0; }
    .pcoded-header .main-search.open .input-group .form-control:active, .pcoded-header .main-search.open .input-group .form-control:focus, .pcoded-header .main-search.open .input-group .form-control:hover,
    .pcoded-header .main-search.open .input-group .search-btn:active,
    .pcoded-header .main-search.open .input-group .search-btn:focus,
    .pcoded-header .main-search.open .input-group .search-btn:hover {
      outline: none;
      box-shadow: none; }
  .pcoded-header .dropdown .dropdown-toggle {
    line-height: 100px;
    display: inline-block;
    padding-right: 15px; }
    .pcoded-header .dropdown .dropdown-toggle:after {
      content: "\e842";
      font-family: 'feather';
      font-size: 15px;
      border: none;
      position: absolute;
      top: 0;
      right: 0; }
  .pcoded-header .dropdown .dropdown-menu {
    position: absolute;
    border: none;
    margin-top: -6px;
    min-width: 290px; }
    .pcoded-header .dropdown .dropdown-menu li {
      line-height: 1.2; }
      .pcoded-header .dropdown .dropdown-menu li a {
        padding: 10px;
        font-size: 14px; }
      .pcoded-header .dropdown .dropdown-menu li.active a, .pcoded-header .dropdown .dropdown-menu li:active a, .pcoded-header .dropdown .dropdown-menu li:focus a, .pcoded-header .dropdown .dropdown-menu li:hover a {
        color: #888; }
  .pcoded-header .dropdown.show:before {
    content: "\63";
    font-family: "pct";
    position: absolute;
    left: -5px;
    top: 90px;
    z-index: 1001;
    font-size: 40px;
    line-height: 0;
    color: #fff;
    text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12); }
  .pcoded-header .dropdown .notification {
    width: 350px;
    padding: 0;
    line-height: 1.4; }
    .pcoded-header .dropdown .notification .noti-head {
      border-bottom: 1px solid #f1f1f1;
      padding: 15px 20px; }
      .pcoded-header .dropdown .notification .noti-head a {
        text-decoration: underline;
        font-size: 13px; }
    .pcoded-header .dropdown .notification .noti-body {
      padding: 0; }
      .pcoded-header .dropdown .notification .noti-body img {
        width: 40px;
        margin-right: 20px; }
      .pcoded-header .dropdown .notification .noti-body li {
        padding: 15px 20px;
        transition: all 0.3s ease-in-out; }
        .pcoded-header .dropdown .notification .noti-body li.n-title {
          padding-bottom: 0; }
          .pcoded-header .dropdown .notification .noti-body li.n-title p {
            margin-bottom: 5px; }
        .pcoded-header .dropdown .notification .noti-body li.notification:hover {
          background: rgba(21, 192, 66, 0.1); }
        .pcoded-header .dropdown .notification .noti-body li p {
          margin-bottom: 5px;
          font-size: 13px; }
          .pcoded-header .dropdown .notification .noti-body li p strong {
            color: #222; }
        .pcoded-header .dropdown .notification .noti-body li .n-time {
          font-size: 80%;
          float: right; }
    .pcoded-header .dropdown .notification .noti-footer {
      border-top: 1px solid #f1f1f1;
      padding: 15px 20px;
      text-align: center; }
      .pcoded-header .dropdown .notification .noti-footer a {
        text-decoration: underline;
        font-size: 13px; }
    .pcoded-header .dropdown .notification ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
      .pcoded-header .dropdown .notification ul li {
        padding: 20px 15px; }
  .pcoded-header .dropdown .profile-notification {
    padding: 0;
    line-height: 1.4; }
    .pcoded-header .dropdown .profile-notification .pro-head {
      color: #fff;
      border-radius: 0.25rem 0.25rem 0 0;
      padding: 15px;
      position: relative;
      background: #15C042; }
      .pcoded-header .dropdown .profile-notification .pro-head img {
        width: 40px;
        margin-right: 10px; }
      .pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
        color: #fff;
        padding-right: 0;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .pcoded-header .dropdown .profile-notification .pro-body {
      padding: 20px 0;
      margin-bottom: 0;
      list-style: none; }
      .pcoded-header .dropdown .profile-notification .pro-body li a {
        color: #888;
        font-size: 14px;
        padding: 10px 20px; }
        .pcoded-header .dropdown .profile-notification .pro-body li a i {
          margin-right: 10px; }
      .pcoded-header .dropdown .profile-notification .pro-body li.active, .pcoded-header .dropdown .profile-notification .pro-body li:active, .pcoded-header .dropdown .profile-notification .pro-body li:focus, .pcoded-header .dropdown .profile-notification .pro-body li:hover {
        background: rgba(21, 192, 66, 0.1); }
        .pcoded-header .dropdown .profile-notification .pro-body li.active > a, .pcoded-header .dropdown .profile-notification .pro-body li:active > a, .pcoded-header .dropdown .profile-notification .pro-body li:focus > a, .pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
          background: transparent; }
  .pcoded-header .dropdown.profile-notification.show:before {
    color: #15C042; }

/* header css end */
/* menu[ vartical ] css start */
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 100px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px; }
  .mobile-menu span {
    position: relative;
    display: block;
    width: 100%;
    height: 2.8px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    backface-visibility: hidden;
    border-radius: 2px; }
    .mobile-menu span:after, .mobile-menu span:before {
      content: "";
      position: absolute;
      left: 0;
      height: 2.8px;
      background-color: #fff;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px; }
    .mobile-menu span:after {
      top: 5px;
      width: 70%; }
    .mobile-menu span:before {
      top: -5px;
      width: 40%; }
  .mobile-menu.on span {
    background-color: transparent; }
    .mobile-menu.on span:after, .mobile-menu.on span:before {
      height: 2px;
      width: 100%; }
    .mobile-menu.on span:before {
      transform: rotate(45deg) translate(4px, 4px); }
    .mobile-menu.on span:after {
      transform: rotate(-45deg) translate(3px, -3px); }

.navbar-brand {
  background: #fff; }

.pcoded-navbar {
  display: block;
  z-index: 1029;
  position: fixed;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  width: 256px;
  height: 100vh;
  top: 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.87); }
  .pcoded-navbar ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0; }
  .pcoded-navbar .scroll-div.navbar-content {
    height: calc(100vh - 100px); }
  .pcoded-navbar .header-logo {
    position: relative;
    align-items: center;
    float: left;
    height: 100px;
    text-align: center;
    width: 256px;
    margin-right: 0;
    padding: 20px 0 10px 0;
    transition: all 0.3s ease-in-out; }
    .pcoded-navbar .header-logo .logo-dark {
      display: none; }
    .pcoded-navbar .header-logo .logo-thumb {
      transform: rotateY(-90deg);
      opacity: 0;
      position: absolute;
      transition: unset; }
    .pcoded-navbar .header-logo + .scroll-div {
      float: left;
      display: inline-block; }
  .pcoded-navbar .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 100px;
    position: absolute;
    right: 10px;
    top: 0;
    padding: 0 10px;
    transition: all 0.3s ease-in-out; }
    .pcoded-navbar .mobile-menu span {
      position: relative;
      display: block;
      width: 100%;
      height: 2.8px;
      background-color: rgba(0, 0, 0, 0.87);
      transition: all 0.3s ease-in-out;
      backface-visibility: hidden;
      border-radius: 2px; }
      .pcoded-navbar .mobile-menu span:after, .pcoded-navbar .mobile-menu span:before {
        content: "";
        position: absolute;
        left: 0;
        height: 2.8px;
        background-color: rgba(0, 0, 0, 0.87);
        transition: all 0.3s;
        backface-visibility: hidden;
        border-radius: 2px; }
      .pcoded-navbar .mobile-menu span:after {
        top: 5px;
        width: 70%; }
      .pcoded-navbar .mobile-menu span:before {
        top: -5px;
        width: 40%; }
    .pcoded-navbar .mobile-menu.on span {
      background-color: transparent; }
      .pcoded-navbar .mobile-menu.on span:after, .pcoded-navbar .mobile-menu.on span:before {
        height: 2px;
        width: 100%; }
      .pcoded-navbar .mobile-menu.on span:before {
        transform: rotate(45deg) translate(4px, 4px); }
      .pcoded-navbar .mobile-menu.on span:after {
        transform: rotate(-45deg) translate(3px, -3px); }
  .pcoded-navbar .pcoded-badge {
    font-size: 75%;
    position: absolute;
    right: 30px;
    top: 12px;
    padding: 2px 7px; }
  .pcoded-navbar .pcoded-inner-navbar {
    flex-direction: column; }
    .pcoded-navbar .pcoded-inner-navbar li {
      position: relative; }
      .pcoded-navbar .pcoded-inner-navbar li > a {
        text-align: left;
        display: flex;
        align-items: center;
        border-radius: 0;
        position: relative; }
        .pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
          position: relative; }
        .pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
          font-size: 1rem;
          padding: 4px 7px;
          margin-right: 7px;
          border-radius: 4px;
          width: 30px;
          display: inline-flex;
          align-items: center;
          height: 30px;
          text-align: center; }
          .pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.1px;
            text-transform: uppercase; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
        content: "\e844";
        font-family: 'feather';
        font-size: 15px;
        border: none;
        position: absolute;
        right: 20px;
        transition: 0.3s ease-in-out; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
        transform: rotate(90deg); }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
        display: none; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
        display: block; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > a {
        position: relative; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
        text-align: left;
        padding: 7px 7px 7px 60px;
        margin: 0;
        display: block;
        text-transform: uppercase; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a {
        padding: 7px 7px 7px 80px; }
        .pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a:before {
          left: 55px; }
      .pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
        font-size: 10px;
        font-weight: 600;
        padding: 25px 20px 5px;
        text-transform: uppercase;
        position: relative; }
      .pcoded-navbar .pcoded-inner-navbar li.disabled > a {
        cursor: default;
        opacity: 0.5; }
  .pcoded-navbar .pcoded-submenu {
    background: #F2F3F7;
    padding: 15px 0; }
  .pcoded-navbar a {
    color: rgba(0, 0, 0, 0.87); }
  .pcoded-navbar .navbar-content,
  .pcoded-navbar .navbar-wrapper {
    width: 100%;
    height: 100%; }
  .pcoded-navbar.navbar-collapsed {
    width: 80px;
    height: 100%;
    transition: all 0.3s ease-in-out; }
    .pcoded-navbar.navbar-collapsed .header-logo {
      width: 80px; }
      .pcoded-navbar.navbar-collapsed .header-logo img {
        transform: rotateY(-90deg);
        transform-origin: 0 0;
        opacity: 0;
        transition: unset; }
      .pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
        transform: rotateY(0deg);
        transform-origin: 0 0;
        opacity: 1;
        left: calc((80px / 2) - 20px); }
      .pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
        transform: rotateY(-90deg);
        transform-origin: 0 0;
        opacity: 0; }
    .pcoded-navbar.navbar-collapsed .navbar-content.ps {
      overflow: visible; }
    .pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
      position: relative;
      width: 100%;
      height: auto;
      white-space: nowrap;
      overflow: hidden; }
      .pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
        transform: rotateY(-90deg);
        transform-origin: 0 0;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
      .pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
        content: "";
        position: absolute;
        top: 25px;
        left: 15px;
        width: calc(100% - 30px);
        height: 1px;
        background: rgba(0, 0, 0, 0.1); }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
      z-index: 1026;
      padding: 7px 25px; }
      .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
        transform: rotateY(-90deg);
        transform-origin: 0 0;
        opacity: 0;
        transition: all 0.3s ease-in-out; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
      right: 12px; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu {
      display: none; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
      content: "";
      position: absolute;
      top: 49px;
      left: calc(calc(80px / 2) - 3px);
      width: 1px;
      height: calc(100% - 49px);
      background: rgba(0, 0, 0, 0.07); }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
      color: transparent;
      white-space: nowrap; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
      opacity: 0; }
    .pcoded-navbar.navbar-collapsed ~ .pcoded-header {
      width: calc(100% - 80px); }
    .pcoded-navbar.navbar-collapsed ~ .pcoded-header,
    .pcoded-navbar.navbar-collapsed ~ .pcoded-main-container {
      margin-left: 80px; }
    .pcoded-navbar.navbar-collapsed .pcoded-badge {
      transform: rotateX(-90deg);
      transform-origin: 50% 50%;
      opacity: 0;
      transition: all 0.15s ease-in-out; }
    .pcoded-navbar.navbar-collapsed:hover {
      width: 256px !important; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
        transition-delay: 0.3s;
        transform: rotateX(0deg);
        opacity: 1; }
      .pcoded-navbar.navbar-collapsed:hover .header-logo {
        width: 256px; }
        .pcoded-navbar.navbar-collapsed:hover .header-logo img {
          transform: rotateY(0deg);
          opacity: 1; }
        .pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
          transform: rotateY(-90deg);
          opacity: 0; }
        .pcoded-navbar.navbar-collapsed:hover .header-logo .mobile-menu {
          display: flex;
          right: 10px; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption > label {
        transform: rotateY(0deg);
        opacity: 1; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
        background: transparent; }
      .pcoded-navbar.navbar-collapsed:hover .mobile-menu {
        transform: rotateY(0deg);
        opacity: 1; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext {
        transform: rotateY(0deg);
        opacity: 1; }
        .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext:after {
          opacity: 1;
          visibility: visible; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
        transform: rotateX(0deg); }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a:after {
        transform: rotateX(0deg) rotate(90deg); }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
        display: none; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
        color: inherit; }
      .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
        opacity: 1; }
  .pcoded-navbar .pcoded-menu-caption {
    color: #e8edf7; }
  .pcoded-navbar .pcoded-inner-navbar li.active > a, .pcoded-navbar .pcoded-inner-navbar li:focus > a, .pcoded-navbar .pcoded-inner-navbar li:hover > a {
    color: #15C042; }
    .pcoded-navbar .pcoded-inner-navbar li.active > a svg path, .pcoded-navbar .pcoded-inner-navbar li:focus > a svg path, .pcoded-navbar .pcoded-inner-navbar li:hover > a svg path {
      fill: #15C042; }
  .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #15C042; }
  .pcoded-navbar .pcoded-inner-navbar > li.active:after, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after {
    content: "";
    background-color: unset;
    z-index: 1027;
    position: absolute;
    left: 0;
    top: 5px;
    width: 3px;
    height: calc(100% - 5px); }
  .pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: #15C0420D;
    color: #15C042; }
  .pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.active:after, .pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.pcoded-trigger:after {
    display: none; }

.pcoded-navbar.mob-open ~ .pcoded-header,
.pcoded-navbar.mob-open ~ .pcoded-main-container, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container {
  margin-left: 80px; }
  .pcoded-navbar.mob-open ~ .pcoded-header:before,
  .pcoded-navbar.mob-open ~ .pcoded-main-container:before, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
  .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1; }

/* menu [ vartical ] css end */
/* menu[ horizontal ] css start */
.pcoded-navbar.theme-horizontal {
  display: block;
  height: 100px;
  width: 100%;
  z-index: 1023;
  box-shadow: none;
  position: fixed;
  top: 100px; }
  .pcoded-navbar.theme-horizontal .pcoded-badge {
    display: none; }
  .pcoded-navbar.theme-horizontal.top-nav-collapse {
    top: -100px; }
  .pcoded-navbar.theme-horizontal.default, .pcoded-navbar.theme-horizontal.default.top-nav-collapse {
    top: 100px; }
  .pcoded-navbar.theme-horizontal .header-logo {
    display: none; }
  .pcoded-navbar.theme-horizontal .sidenav-horizontal-wrapper {
    display: flex;
    align-items: center; }
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
    transition: all 0.5s ease-in-out; }
    .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-menu-caption {
      display: none; }
    .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li {
      display: inline-block;
      list-style: outside none none;
      margin: 0;
      padding: 0;
      position: relative; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a {
        margin: 0;
        padding: 8px 15px; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a > .pcoded-mtext {
          position: relative;
          top: 0;
          margin-right: 5px; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a:after {
          position: relative;
          top: 0;
          right: 0; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li:last-child > a {
        margin-right: 70px; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li:hover a {
        box-shadow: none;
        color: #fff;
        background: transparent; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
        content: "\6d";
        position: absolute;
        bottom: -34px;
        left: 0;
        font-family: "pct";
        z-index: 1001;
        font-size: 50px;
        line-height: 1;
        padding-left: calc(50% - 25px);
        color: #fff;
        text-shadow: 0 3px 4px rgba(69, 90, 100, 0.05);
        width: 100%;
        height: 40px;
        transform: scaleX(1.2); }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > .pcoded-submenu, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        margin-top: 30px; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active:after, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger:after {
        content: "";
        background-color: #1dc4e9;
        z-index: 1027;
        position: absolute;
        left: 19px;
        top: auto;
        bottom: 5px;
        width: 23px;
        height: 2px; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.edge > .pcoded-submenu {
        left: auto;
        right: 0; }
    .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu {
      position: relative; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu {
        opacity: 0;
        visibility: hidden;
        transform-origin: 50% 50%;
        transition: transform 0.3s, opacity 0.3s;
        transform-style: preserve-3d;
        transform: rotateX(-90deg);
        position: absolute;
        min-width: 230px;
        display: block;
        z-index: 1;
        top: 100%;
        list-style: outside none none;
        margin: 0;
        border-radius: 4px;
        padding: 15px;
        box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
        background: #fff; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a {
          color: #fff;
          padding: 10px 20px 10px 30px; }
          .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a:before {
            left: 5px;
            top: 19px; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.active > a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.pcoded-trigger > a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li:hover > a {
          color: #15C042; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu {
          position: absolute;
          min-width: 230px;
          z-index: 1;
          left: calc(100% + 10px);
          top: -10px;
          margin: 0 0 0 20px;
          border-radius: 4px; }
          .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu:before {
            content: "\6a";
            position: absolute;
            top: 8px;
            left: -31px;
            font-family: "pct";
            z-index: 1001;
            font-size: 50px;
            line-height: 1;
            color: #fff;
            width: 40px;
            height: 100%;
            text-shadow: -8px 0 13px rgba(62, 57, 107, 0.08); }
          .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a {
            color: #fff;
            padding: 10px 20px 10px 30px; }
            .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a:before {
              left: 5px;
              top: 19px; }
        .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu {
          left: auto;
          margin: 0 20px 0 0;
          right: calc(100% + 10px); }
          .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu:before {
            content: '\6b';
            left: auto;
            right: -21px;
            text-shadow: 8px 0 13px rgba(62, 57, 107, 0.08); }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu:hover > .pcoded-submenu, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg); }
  .pcoded-navbar.theme-horizontal .navbar-content {
    display: flex;
    align-items: center; }
  .pcoded-navbar.theme-horizontal ~ .pcoded-header {
    position: fixed;
    top: 0;
    margin-left: 0;
    width: 100%; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
      color: #3f4d67; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*='header-'] .b-title {
      color: #fff; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header {
      display: inline-flex;
      padding: 0 15px; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-main,
      .pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-thumb {
        display: none; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-dark {
        display: inline-block; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header .mobile-menu {
      display: none; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] {
      color: rgba(255, 255, 255, 0.8); }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header {
        display: inline-flex;
        padding: 0 15px; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-dark,
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-thumb {
          display: none; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-main {
          display: inline-block; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu {
        color: #888; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu a {
          color: #888; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li > a {
          color: #888; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li.active, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:active, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:focus, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:hover {
          background: rgba(21, 192, 66, 0.1); }
          .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li.active > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:active > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:focus > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:hover > a {
            background: transparent; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] a,
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] dropdown-toggle {
        color: rgba(255, 255, 255, 0.8); }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] a:hover,
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] dropdown-toggle:hover {
          color: #fff; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .notification .noti-body li.notification:hover {
        background: rgba(21, 192, 66, 0.1); }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head {
        color: #fff;
        background: #15C042; }
        .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head .dud-logout {
          color: #fff; }
      .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown.profile-notification.show:before {
        color: #15C042; }
  .pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
    margin-top: 200px;
    margin-left: 0; }

/* menu [ horizontal ] css end */
/* main content start */
.pcoded-content {
  position: relative;
  display: block; }

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  display: block;
  clear: both;
  float: unset;
  background-color: #F4FAFF;
  right: 0;
  margin-left: 256px;
  transition: all 0.3s ease-in-out;
  padding: 0 42.67px 30px 33px;
  height: calc(100vh - 137px);
  overflow-x: hidden; }

/* main content end*/
/* ==========================    Rsponsive Menu  start   ====================== */
@media only screen and (max-width: 991px) {
  .pcoded-header {
    background: #transparent;
    color: #666; }
    .pcoded-header .input-group-text,
    .pcoded-header a,
    .pcoded-header dropdown-toggle {
      color: #3f4d67; }
      .pcoded-header .input-group-text:hover,
      .pcoded-header a:hover,
      .pcoded-header dropdown-toggle:hover {
        color: #15C042; }
    .pcoded-header .dropdown .dropdown-toggle:after {
      display: none; }
    .pcoded-header #mobile-header {
      display: none;
      right: 20px; }
    .pcoded-header > .collapse:not(.show) {
      display: flex;
      background: #fff;
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); }
    .pcoded-header .dropdown-menu,
    .pcoded-header .dropdown-menu a,
    .pcoded-header .search-close .input-group-text {
      color: #3f4d67; }
    .pcoded-header .m-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100px; }
      .pcoded-header .m-header .b-brand {
        margin-left: 20px; }
      .pcoded-header .m-header .mobile-menu {
        right: 20px; }
    .pcoded-header .full-screen {
      display: none; }
    .pcoded-header .navbar-nav > li:last-child {
      padding-right: 60px; }
  .pcoded-navbar {
    margin-left: -256px;
    position: absolute;
    height: 100%;
    box-shadow: none; }
    .pcoded-navbar .scroll-div.navbar-content {
      height: 100%; }
    .pcoded-navbar ~ .pcoded-header,
    .pcoded-navbar ~ .pcoded-main-container {
      margin-left: 0; }
    .pcoded-navbar ~ .pcoded-header {
      width: 100%; }
    .pcoded-navbar .navbar-brand {
      display: none; }
    .pcoded-navbar.mob-open {
      margin-left: 0; }
      .pcoded-navbar.mob-open ~ .pcoded-header,
      .pcoded-navbar.mob-open ~ .pcoded-main-container {
        margin-left: 0; } }

@media only screen and (max-width: 575px) {
  .pcoded-header .navbar-nav > li:first-child {
    padding-left: 0; }
  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 20px; }
  .pcoded-header .navbar-nav.me-auto li {
    display: none; }
    .pcoded-header .navbar-nav.me-auto li.nav-item:not(.dropdown) {
      display: block;
      padding-right: 0; }
      .pcoded-header .navbar-nav.me-auto li.nav-item:not(.dropdown) .search-close {
        display: none; }
  .pcoded-header .dropdown {
    position: static; }
    .pcoded-header .dropdown .dropdown-toggle.show:after {
      display: none; }
    .pcoded-header .dropdown .dropdown-toggle.show:before {
      display: none; }
    .pcoded-header .dropdown .notification {
      width: 100%; }
    .pcoded-header .dropdown .dropdown-menu {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0; }
  .header-chat.open,
  .header-user-list.open {
    width: 280px; }
  .pcoded-content {
    padding: 30px 15px; }
  .card {
    margin-bottom: 15px; } }

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .pcoded-navbar .header-logo {
    transition: none; }
  .pcoded-navbar.navbar-collapsed {
    transition: none; }
    .pcoded-navbar.navbar-collapsed .header-logo img {
      transition: none; }
    .pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
      transition: none; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
      transition: none; }
    .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
      transition: none;
      transition-delay: 0; }
    .pcoded-navbar.navbar-collapsed .pcoded-badge {
      transition: none; }
    .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
      transition-delay: 0; } }

/* responsive horizontal menu */
@media only screen and (max-width: 991px) {
  .pcoded-navbar.theme-horizontal {
    margin-left: 0; }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header > .collapse:not(.show) {
      display: inline-flex;
      background: #fff;
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); }
    .pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
      color: #fff; }
    .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger > .pcoded-submenu,
    .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger.edge > .pcoded-submenu {
      position: relative;
      left: 0;
      min-width: 100%;
      margin: 0;
      box-shadow: none; }
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger > .pcoded-submenu:before,
      .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger.edge > .pcoded-submenu:before {
        display: none; } }

/* ==========================    Rsponsive Menu  end   ====================== */
/* ******************************************************************************* */
/* * Horizontal */
.theme-horizontal .sidenav-horizontal-wrapper {
  flex: 0 1 100%;
  width: 0; }
  .sidenav:not(.sidenav-no-animation) .theme-horizontal .sidenav-horizontal-wrapper .sidenav-inner {
    transition: margin 0.2s; }

.theme-horizontal .sidenav-horizontal-next,
.theme-horizontal .sidenav-horizontal-prev {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 2.25rem;
  background-color: #fff;
  z-index: 9; }
  .theme-horizontal .sidenav-horizontal-next::after,
  .theme-horizontal .sidenav-horizontal-prev::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border: 2px solid;
    border-top: 0; }
  .theme-horizontal .sidenav-horizontal-next.disabled,
  .theme-horizontal .sidenav-horizontal-prev.disabled {
    cursor: default !important;
    opacity: 0; }

.theme-horizontal .sidenav-horizontal-prev::after {
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg); }

.theme-horizontal .sidenav-horizontal-next::after {
  border-left: 0;
  transform: translate(-50%, -50%) rotate(-45deg); }

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.menu-styler {
  z-index: 1029;
  position: relative;
  color: #888; }
  .menu-styler h5,
  .menu-styler h6 {
    color: #111;
    font-weight: 400; }
  .menu-styler hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .menu-styler .style-toggler > a {
    position: fixed;
    right: 0;
    top: 30vh;
    transition: 0.3s ease-in-out; }
    .menu-styler .style-toggler > a:before {
      content: "\62";
      top: 0;
      font-size: 120px;
      position: absolute;
      right: -45px;
      font-family: "pct";
      z-index: 1001;
      line-height: 0;
      color: #15C042;
      transition: 0.3s ease-in-out;
      text-shadow: 1px 0 2px rgba(21, 192, 66, 0.9), 3px 0 5px rgba(0, 0, 0, 0.1);
      letter-spacing: -44px;
      transform: rotate(180deg); }
    .menu-styler .style-toggler > a:after {
      content: "\e8c3";
      top: -7px;
      font-size: 18px;
      position: absolute;
      right: 4px;
      font-family: "feather";
      z-index: 1029;
      line-height: 1;
      color: #fff;
      transition: 0.3s ease-in-out;
      animation: anim-rotate 1.5s linear infinite; }
      @media all and (-ms-high-contrast: none) {
        .menu-styler .style-toggler > a:after {
          line-height: 0.5; } }
  .menu-styler.open .style-toggler > a {
    right: 400px; }
    .menu-styler.open .style-toggler > a:after {
      content: "\e847";
      top: -7px;
      font-size: 18px;
      animation: anim-rotate 0s linear infinite; }
  .menu-styler.open .style-block {
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
  .menu-styler .style-block {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100vh;
    background: #fff;
    z-index: 1030;
    transition: 0.3s ease-in-out;
    padding: 25px 20px 20px; }
  .menu-styler .mst-scroll {
    height: calc(100vh - 175px);
    background-color: rgba(21, 192, 66, 0.03);
    padding: 0 15px; }
  .menu-styler label {
    margin-bottom: 3px; }
  .menu-styler .switch input[type=checkbox] + .cr {
    top: 8px; }
  .menu-styler .radio input[type=radio] + .cr {
    margin-right: 10px; }
    .menu-styler .radio input[type=radio] + .cr:after, .menu-styler .radio input[type=radio] + .cr:before {
      margin-right: 3px; }
  .menu-styler h6 {
    position: relative;
    margin: 10px 0 15px; }
    .menu-styler h6:after {
      content: "";
      background-color: #15C042;
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 40px;
      height: 2px; }
  .menu-styler .nav-pills {
    padding: 0;
    box-shadow: none; }
    .menu-styler .nav-pills li {
      width: calc(100% / 3); }
  .menu-styler .tab-content {
    padding: 15px;
    box-shadow: none;
    background-color: rgba(21, 192, 66, 0.03);
    border-top: 2px solid #15C042; }
  @media only screen and (max-width: 992px) {
    .menu-styler {
      display: none; } }

.lay-customizer .theme-color,
.theme-color {
  display: block;
  position: relative;
  padding: 5px 5px 0;
  margin-bottom: 10px; }
  .lay-customizer .theme-color > a,
  .theme-color > a {
    position: relative;
    width: 45px;
    height: 30px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 8px;
    background: #F4FAFF;
    overflow: hidden;
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33); }
    .lay-customizer .theme-color > a span,
    .theme-color > a span {
      width: 100%;
      position: absolute; }
      .lay-customizer .theme-color > a span:after, .lay-customizer .theme-color > a span:before,
      .theme-color > a span:after,
      .theme-color > a span:before {
        content: "";
        height: 100%;
        position: absolute; }
      .lay-customizer .theme-color > a span:before,
      .theme-color > a span:before {
        width: 30%;
        left: 0;
        background: #fff; }
      .lay-customizer .theme-color > a span:after,
      .theme-color > a span:after {
        width: 70%;
        right: 0;
        background: #F4FAFF; }
    .lay-customizer .theme-color > a > span:nth-child(1),
    .theme-color > a > span:nth-child(1) {
      height: 30%;
      top: 0; }
    .lay-customizer .theme-color > a > span:nth-child(2),
    .theme-color > a > span:nth-child(2) {
      height: 75%;
      bottom: 0; }
    .lay-customizer .theme-color > a.active,
    .theme-color > a.active {
      box-shadow: 0 0 0 3px #15C042; }
      .lay-customizer .theme-color > a.active:before,
      .theme-color > a.active:before {
        content: "\e8dc";
        top: -1px;
        font-size: 20px;
        position: absolute;
        left: 12px;
        font-family: "feather";
        z-index: 1001;
        color: #15C042;
        transition: 0.3s ease-in-out;
        text-shadow: 0 1px 3px rgba(21, 192, 66, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1); }
      .lay-customizer .theme-color > a.active:after,
      .theme-color > a.active:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); }
    .lay-customizer .theme-color > a[data-value="menu-light"] span:before,
    .theme-color > a[data-value="menu-light"] span:before {
      background: #fff; }
    .lay-customizer .theme-color > a[data-value="dark"] span:after,
    .theme-color > a[data-value="dark"] span:after {
      background: #101011; }
    .lay-customizer .theme-color > a[data-value="reset"],
    .theme-color > a[data-value="reset"] {
      background: #f44236;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: -10px;
      width: 135px; }
  .lay-customizer .theme-color.small > a,
  .theme-color.small > a {
    width: 25px;
    height: 25px;
    border-radius: 50%; }
    .lay-customizer .theme-color.small > a:before,
    .theme-color.small > a:before {
      font-size: 15px;
      left: 5px;
      top: 1px; }
    .lay-customizer .theme-color.small > a > span,
    .theme-color.small > a > span {
      display: none; }
  .lay-customizer .theme-color.header-color > a[data-value="header-blue"] > span:nth-child(1):after,
  .theme-color.header-color > a[data-value="header-blue"] > span:nth-child(1):after {
    background: #15C042; }
  .lay-customizer .theme-color.header-color.small > a[data-value="header-blue"],
  .theme-color.header-color.small > a[data-value="header-blue"] {
    background: #15C042; }
  .lay-customizer .theme-color.header-color > a[data-value="header-red"] > span:nth-child(1):after,
  .theme-color.header-color > a[data-value="header-red"] > span:nth-child(1):after {
    background: #ff5252; }
  .lay-customizer .theme-color.header-color.small > a[data-value="header-red"],
  .theme-color.header-color.small > a[data-value="header-red"] {
    background: #ff5252; }
  .lay-customizer .theme-color.header-color > a[data-value="header-purple"] > span:nth-child(1):after,
  .theme-color.header-color > a[data-value="header-purple"] > span:nth-child(1):after {
    background: #9575CD; }
  .lay-customizer .theme-color.header-color.small > a[data-value="header-purple"],
  .theme-color.header-color.small > a[data-value="header-purple"] {
    background: #9575CD; }
  .lay-customizer .theme-color.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after,
  .theme-color.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after {
    background: #23b7e5; }
  .lay-customizer .theme-color.header-color.small > a[data-value="header-lightblue"],
  .theme-color.header-color.small > a[data-value="header-lightblue"] {
    background: #23b7e5; }
  .lay-customizer .theme-color.header-color > a[data-value="header-dark"] > span:nth-child(1):after,
  .theme-color.header-color > a[data-value="header-dark"] > span:nth-child(1):after {
    background: #323437; }
  .lay-customizer .theme-color.header-color.small > a[data-value="header-dark"],
  .theme-color.header-color.small > a[data-value="header-dark"] {
    background: #323437; }
  .lay-customizer .theme-color.navbar-color > a[data-value="navbar-blue"] > span:before,
  .theme-color.navbar-color > a[data-value="navbar-blue"] > span:before {
    background: #15C042; }
  .lay-customizer .theme-color.navbar-color > a[data-value="navbar-red"] > span:before,
  .theme-color.navbar-color > a[data-value="navbar-red"] > span:before {
    background: #ff5252; }
  .lay-customizer .theme-color.navbar-color > a[data-value="navbar-purple"] > span:before,
  .theme-color.navbar-color > a[data-value="navbar-purple"] > span:before {
    background: #9575CD; }
  .lay-customizer .theme-color.navbar-color > a[data-value="navbar-lightblue"] > span:before,
  .theme-color.navbar-color > a[data-value="navbar-lightblue"] > span:before {
    background: #23b7e5; }
  .lay-customizer .theme-color.navbar-color > a[data-value="navbar-dark"] > span:before,
  .theme-color.navbar-color > a[data-value="navbar-dark"] > span:before {
    background: #323437; }
  .lay-customizer .theme-color.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before,
  .theme-color.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before {
    background: #15C042; }
  .lay-customizer .theme-color.brand-color.small > a[data-value="brand-blue"],
  .theme-color.brand-color.small > a[data-value="brand-blue"] {
    background: #15C042; }
  .lay-customizer .theme-color.brand-color > a[data-value="brand-red"] > span:nth-child(1):before,
  .theme-color.brand-color > a[data-value="brand-red"] > span:nth-child(1):before {
    background: #ff5252; }
  .lay-customizer .theme-color.brand-color.small > a[data-value="brand-red"],
  .theme-color.brand-color.small > a[data-value="brand-red"] {
    background: #ff5252; }
  .lay-customizer .theme-color.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before,
  .theme-color.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before {
    background: #9575CD; }
  .lay-customizer .theme-color.brand-color.small > a[data-value="brand-purple"],
  .theme-color.brand-color.small > a[data-value="brand-purple"] {
    background: #9575CD; }
  .lay-customizer .theme-color.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before,
  .theme-color.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before {
    background: #23b7e5; }
  .lay-customizer .theme-color.brand-color.small > a[data-value="brand-lightblue"],
  .theme-color.brand-color.small > a[data-value="brand-lightblue"] {
    background: #23b7e5; }
  .lay-customizer .theme-color.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before,
  .theme-color.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before {
    background: #323437; }
  .lay-customizer .theme-color.brand-color.small > a[data-value="brand-dark"],
  .theme-color.brand-color.small > a[data-value="brand-dark"] {
    background: #323437; }
  .lay-customizer .theme-color.active-color > a[data-value="active-default"],
  .theme-color.active-color > a[data-value="active-default"] {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
  .lay-customizer .theme-color.active-color > a[data-value="active-blue"],
  .theme-color.active-color > a[data-value="active-blue"] {
    background: #15C042; }
  .lay-customizer .theme-color.active-color > a[data-value="active-red"],
  .theme-color.active-color > a[data-value="active-red"] {
    background: #ff5252; }
  .lay-customizer .theme-color.active-color > a[data-value="active-purple"],
  .theme-color.active-color > a[data-value="active-purple"] {
    background: #9575CD; }
  .lay-customizer .theme-color.active-color > a[data-value="active-lightblue"],
  .theme-color.active-color > a[data-value="active-lightblue"] {
    background: #23b7e5; }
  .lay-customizer .theme-color.active-color > a[data-value="active-dark"],
  .theme-color.active-color > a[data-value="active-dark"] {
    background: #323437; }
  .lay-customizer .theme-color.title-color > a[data-value="title-default"],
  .theme-color.title-color > a[data-value="title-default"] {
    background: #fff; }
  .lay-customizer .theme-color.title-color > a[data-value="title-blue"],
  .theme-color.title-color > a[data-value="title-blue"] {
    background: #15C042; }
  .lay-customizer .theme-color.title-color > a[data-value="title-red"],
  .theme-color.title-color > a[data-value="title-red"] {
    background: #ff5252; }
  .lay-customizer .theme-color.title-color > a[data-value="title-purple"],
  .theme-color.title-color > a[data-value="title-purple"] {
    background: #9575CD; }
  .lay-customizer .theme-color.title-color > a[data-value="title-lightblue"],
  .theme-color.title-color > a[data-value="title-lightblue"] {
    background: #23b7e5; }
  .lay-customizer .theme-color.title-color > a[data-value="title-dark"],
  .theme-color.title-color > a[data-value="title-dark"] {
    background: #323437; }
  .lay-customizer .theme-color.navbar-images > a,
  .theme-color.navbar-images > a {
    height: 90px;
    background-size: cover;
    background-position: center center; }
    .lay-customizer .theme-color.navbar-images > a span,
    .theme-color.navbar-images > a span {
      display: none; }
    .lay-customizer .theme-color.navbar-images > a.active:before,
    .theme-color.navbar-images > a.active:before {
      top: 35px;
      content: "\e87c"; }
  .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-1"],
  .theme-color.navbar-images > a[data-value="navbar-image-1"] {
    background-image: url("../images/nav-bg/navbar-img-1.jpg"); }
  .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-2"],
  .theme-color.navbar-images > a[data-value="navbar-image-2"] {
    background-image: url("../images/nav-bg/navbar-img-2.jpg"); }
  .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-3"],
  .theme-color.navbar-images > a[data-value="navbar-image-3"] {
    background-image: url("../images/nav-bg/navbar-img-3.jpg"); }
  .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-4"],
  .theme-color.navbar-images > a[data-value="navbar-image-4"] {
    background-image: url("../images/nav-bg/navbar-img-4.jpg"); }
  .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-5"],
  .theme-color.navbar-images > a[data-value="navbar-image-5"] {
    background-image: url("../images/nav-bg/navbar-img-5.jpg"); }
  .lay-customizer .theme-color.bg-images > a,
  .theme-color.bg-images > a {
    width: 95px;
    height: 80px;
    border-radius: 2px;
    background-size: cover;
    background-position: center center; }
    .lay-customizer .theme-color.bg-images > a span,
    .theme-color.bg-images > a span {
      display: none; }
    .lay-customizer .theme-color.bg-images > a.active:before,
    .theme-color.bg-images > a.active:before {
      top: 35px;
      content: "\e87c"; }
  .lay-customizer .theme-color.prelayout-color > a,
  .theme-color.prelayout-color > a {
    margin-bottom: 5px; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(1),
    .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(1) {
      z-index: 1; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(1):before,
      .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(1):before {
        background: linear-gradient(to bottom, #15C042 0%, #15C042 80%, rgba(0, 0, 0, 0.87) 80%, rgba(0, 0, 0, 0.87) 90%, #15C042 91%, #15C042 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l1"] > span:nth-child(2):before {
      background: linear-gradient(to right, #fff 0%, #fff 20%, rgba(0, 0, 0, 0.87) 25%, rgba(0, 0, 0, 0.87) 30%, #fff 20%, #fff 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(1):before {
      background: #fff; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(2):before {
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(1):before {
      background: #fff; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(2):before {
      background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(1):before {
      background: #fff; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(2):before {
      background: #15C042; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(1):before {
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(2):before {
      background: #212224; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(1):before {
      background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(2):before {
      background: #212224; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1),
    .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1) {
      height: 40%; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):before,
      .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):after,
      .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):before {
        background: linear-gradient(#15C042 50%, #fff 0%);
        box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
        z-index: 1; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2),
    .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2) {
      height: 60%; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):before,
      .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):after,
      .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):before {
        background: #F4FAFF; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):after,
    .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):before {
      background: #23b7e5; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(2):before {
      background: #fff; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1),
    .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1) {
      height: 50%; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):after,
      .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):after {
        background: #23b7e5; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):before,
      .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):before {
        background: linear-gradient(#23b7e5 50%, #fff 0%);
        z-index: 1; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2),
    .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2) {
      height: 50%; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):after,
      .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):after {
        background: #F4FAFF; }
      .lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):before,
      .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):before {
        background: #fff; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):after,
    .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):after {
      background: #23b7e5; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):before,
    .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):before {
      background: #23b7e5; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):after,
    .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):after {
      background: #F4FAFF; }
    .lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):before,
    .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):before {
      background: linear-gradient(#fff 0px, #fff 5px, rgba(35, 183, 229, 0.5) 5px, rgba(35, 183, 229, 0.5) 5px, rgba(35, 183, 229, 0.5) 8px, #fff 8px, #fff); }

/* ===================================================================================
 ==========================    Diffrent Layout Styles Start     ====================
 ===================================================================================
 ====== [ light menu style start ] ======== */
.pcoded-navbar.menu-light {
  background-color: #fff;
  color: #3f4d67;
  box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09); }
  .pcoded-navbar.menu-light .header-logo {
    background-color: #fff; }
    .pcoded-navbar.menu-light .header-logo .logo-dark {
      display: none; }
    .pcoded-navbar.menu-light .header-logo .logo-main {
      display: block; }
  .pcoded-navbar.menu-light .sidenav-horizontal-next,
  .pcoded-navbar.menu-light .sidenav-horizontal-prev {
    background: #fff; }
  .pcoded-navbar.menu-light .mobile-menu span {
    background-color: rgba(0, 0, 0, 0.87); }
    .pcoded-navbar.menu-light .mobile-menu span:after, .pcoded-navbar.menu-light .mobile-menu span:before {
      background-color: rgba(0, 0, 0, 0.87); }
  .pcoded-navbar.menu-light .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.menu-light .pcoded-submenu {
    background: #fbfbfb; }
  .pcoded-navbar.menu-light a {
    color: #3f4d67; }
  .pcoded-navbar.menu-light.navbar-collapsed .pcoded-menu-caption:after {
    background: rgba(0, 0, 0, 0.1); }
  .pcoded-navbar.menu-light.navbar-collapsed:hover .pcoded-menu-caption:after {
    background: transparent; }
  .pcoded-navbar.menu-light .pcoded-menu-caption {
    color: #3f4d67; }
  .pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: #f1f1f1;
    color: #111; }
  .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.active a, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger a, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li:hover a {
    color: #3f4d67;
    background: transparent; }
  .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #fff; }

body.layout-1 .pcoded-navbar.menu-light .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(169, 183, 208, 0.4); }
  body.layout-1 .pcoded-navbar.menu-light .sidemenu li a:after {
    border-bottom: 1px solid rgba(181, 189, 202, 0.4); }

body.layout-1 .pcoded-navbar.menu-light .toggle-sidemenu {
  background: #f1f1f1;
  color: #3f4d67; }

body.layout-1 .pcoded-navbar.menu-light .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption:after {
  border-bottom: 1px solid rgba(181, 189, 202, 0.5); }

/* ====== [ light menu style end ] ======== */
/* ====== [ Menu icon start ] ======== */
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(1) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(2) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(3) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(4) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(5) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(6) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(7) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(8) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(9) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(10) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(11) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(12) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(13) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(14) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(15) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(16) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(17) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(18) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(19) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(20) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(21) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(22) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(23) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(24) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(25) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(26) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(27) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(28) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(29) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(30) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(31) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(32) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(33) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(34) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(35) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(36) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(37) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(38) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(39) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(40) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(41) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(42) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(43) > a > .pcoded-micon {
  color: #15C042; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(44) > a > .pcoded-micon {
  color: #ff5252; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(45) > a > .pcoded-micon {
  color: #01a9ac; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(46) > a > .pcoded-micon {
  color: #9575CD; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(47) > a > .pcoded-micon {
  color: #23b7e5; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(48) > a > .pcoded-micon {
  color: #f4c22b; }

.pcoded-navbar.icon-colored .pcoded-inner-navbar > li.pcoded-trigger > a > .pcoded-micon,
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li.active > a > .pcoded-micon {
  color: #fff; }

.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a > .pcoded-micon,
.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar > li.active > a > .pcoded-micon {
  color: #3f4d67; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(1) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(2) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(3) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(4) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(5) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(6) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(7) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(8) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(9) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(10) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(11) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(12) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(13) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(14) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(15) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(16) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(17) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(18) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(19) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(20) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(21) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(22) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(23) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(24) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(25) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(26) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(27) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(28) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(29) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(30) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(31) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(32) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(33) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(34) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(35) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(36) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(37) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(38) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(39) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(40) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(41) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(42) > a {
  color: #f4c22b; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(43) > a {
  color: #15C042; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(44) > a {
  color: #ff5252; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(45) > a {
  color: #01a9ac; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(46) > a {
  color: #9575CD; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(47) > a {
  color: #23b7e5; }

body.layout-1 .pcoded-navbar.icon-colored .sidemenu > li:nth-child(48) > a {
  color: #f4c22b; }

/* ====== [ Menu icon end ] ======== */
/* ====== [ Header color start ] ======== */
.theme-horizontal ~ .pcoded-header {
  background: #fff;
  box-shadow: 0 1px 20px 0 rgba(63, 77, 103, 0.15); }
  @media only screen and (max-width: 991px) {
    .theme-horizontal ~ .pcoded-header {
      background: #fff; }
      .theme-horizontal ~ .pcoded-header .horizontal-dasktop {
        display: none; }
      .theme-horizontal ~ .pcoded-header .horizontal-mobile {
        display: inline-block; } }

@media only screen and (max-width: 992px) {
  .theme-horizontal-dis ~ .pcoded-header .horizontal-dasktop {
    display: none; }
  .theme-horizontal-dis ~ .pcoded-header .horizontal-mobile {
    display: inline-block; } }

.pcoded-header[class*="header-"] {
  box-shadow: 0 3px 10px 0 rgba(65, 72, 78, 0.02); }

.pcoded-header.header-blue {
  background: #15C042;
  color: rgba(255, 255, 255, 0.8); }
  .pcoded-header.header-blue .profile-notification li > a {
    color: #888; }
  .pcoded-header.header-blue .profile-notification li.active, .pcoded-header.header-blue .profile-notification li:active, .pcoded-header.header-blue .profile-notification li:focus, .pcoded-header.header-blue .profile-notification li:hover {
    background: rgba(21, 192, 66, 0.1); }
    .pcoded-header.header-blue .profile-notification li.active > a, .pcoded-header.header-blue .profile-notification li:active > a, .pcoded-header.header-blue .profile-notification li:focus > a, .pcoded-header.header-blue .profile-notification li:hover > a {
      background: transparent; }
  .pcoded-header.header-blue .dropdown-menu {
    color: #888; }
    .pcoded-header.header-blue .dropdown-menu a {
      color: #888; }
    .pcoded-header.header-blue .dropdown-menu > li > a {
      color: #888; }
    .pcoded-header.header-blue .dropdown-menu > li.active, .pcoded-header.header-blue .dropdown-menu > li:active, .pcoded-header.header-blue .dropdown-menu > li:focus, .pcoded-header.header-blue .dropdown-menu > li:hover {
      background: rgba(21, 192, 66, 0.1);
      color: #888; }
      .pcoded-header.header-blue .dropdown-menu > li.active > a, .pcoded-header.header-blue .dropdown-menu > li:active > a, .pcoded-header.header-blue .dropdown-menu > li:focus > a, .pcoded-header.header-blue .dropdown-menu > li:hover > a {
        background: transparent; }
  .pcoded-header.header-blue .input-group .input-group-text,
  .pcoded-header.header-blue a,
  .pcoded-header.header-blue dropdown-toggle {
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-header.header-blue .input-group .input-group-text:hover,
    .pcoded-header.header-blue a:hover,
    .pcoded-header.header-blue dropdown-toggle:hover {
      color: #fff; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.header-blue .input-group .input-group-text,
      .pcoded-header.header-blue a,
      .pcoded-header.header-blue dropdown-toggle {
        color: #3f4d67; }
        .pcoded-header.header-blue .input-group .input-group-text:hover,
        .pcoded-header.header-blue a:hover,
        .pcoded-header.header-blue dropdown-toggle:hover {
          color: #2c3547; } }
  .pcoded-header.header-blue .main-search .search-close > .input-group-text {
    color: #15C042; }
  .pcoded-header.header-blue .main-search.open .input-group .search-btn {
    background: #15C042;
    border-color: #15C042; }
    .pcoded-header.header-blue .main-search.open .input-group .search-btn .input-group-text {
      color: #fff; }
  .pcoded-header.header-blue .dropdown .notification .noti-body li.notification:hover {
    background: rgba(21, 192, 66, 0.1); }
  .pcoded-header.header-blue .dropdown .profile-notification .pro-head {
    color: #fff;
    background: #15C042; }
    .pcoded-header.header-blue .dropdown .profile-notification .pro-head .dud-logout {
      color: #fff; }
  .pcoded-header.header-blue .dropdown.profile-notification.show:before {
    color: #15C042; }
  .pcoded-header.header-blue .b-bg {
    background: #fff; }
    .pcoded-header.header-blue .b-bg i {
      color: #15C042;
      background-image: #15C042;
      -webkit-background-clip: text;
      -webkit-text-fill-color: unset; }

.pcoded-header.header-red {
  background: #ff5252;
  color: rgba(255, 255, 255, 0.8); }
  .pcoded-header.header-red .profile-notification li > a {
    color: #888; }
  .pcoded-header.header-red .profile-notification li.active, .pcoded-header.header-red .profile-notification li:active, .pcoded-header.header-red .profile-notification li:focus, .pcoded-header.header-red .profile-notification li:hover {
    background: rgba(255, 82, 82, 0.1); }
    .pcoded-header.header-red .profile-notification li.active > a, .pcoded-header.header-red .profile-notification li:active > a, .pcoded-header.header-red .profile-notification li:focus > a, .pcoded-header.header-red .profile-notification li:hover > a {
      background: transparent; }
  .pcoded-header.header-red .dropdown-menu {
    color: #888; }
    .pcoded-header.header-red .dropdown-menu a {
      color: #888; }
    .pcoded-header.header-red .dropdown-menu > li > a {
      color: #888; }
    .pcoded-header.header-red .dropdown-menu > li.active, .pcoded-header.header-red .dropdown-menu > li:active, .pcoded-header.header-red .dropdown-menu > li:focus, .pcoded-header.header-red .dropdown-menu > li:hover {
      background: rgba(255, 82, 82, 0.1);
      color: #888; }
      .pcoded-header.header-red .dropdown-menu > li.active > a, .pcoded-header.header-red .dropdown-menu > li:active > a, .pcoded-header.header-red .dropdown-menu > li:focus > a, .pcoded-header.header-red .dropdown-menu > li:hover > a {
        background: transparent; }
  .pcoded-header.header-red .input-group .input-group-text,
  .pcoded-header.header-red a,
  .pcoded-header.header-red dropdown-toggle {
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-header.header-red .input-group .input-group-text:hover,
    .pcoded-header.header-red a:hover,
    .pcoded-header.header-red dropdown-toggle:hover {
      color: #fff; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.header-red .input-group .input-group-text,
      .pcoded-header.header-red a,
      .pcoded-header.header-red dropdown-toggle {
        color: #3f4d67; }
        .pcoded-header.header-red .input-group .input-group-text:hover,
        .pcoded-header.header-red a:hover,
        .pcoded-header.header-red dropdown-toggle:hover {
          color: #2c3547; } }
  .pcoded-header.header-red .main-search .search-close > .input-group-text {
    color: #ff5252; }
  .pcoded-header.header-red .main-search.open .input-group .search-btn {
    background: #ff5252;
    border-color: #ff5252; }
    .pcoded-header.header-red .main-search.open .input-group .search-btn .input-group-text {
      color: #fff; }
  .pcoded-header.header-red .dropdown .notification .noti-body li.notification:hover {
    background: rgba(255, 82, 82, 0.1); }
  .pcoded-header.header-red .dropdown .profile-notification .pro-head {
    color: #fff;
    background: #ff5252; }
    .pcoded-header.header-red .dropdown .profile-notification .pro-head .dud-logout {
      color: #fff; }
  .pcoded-header.header-red .dropdown.profile-notification.show:before {
    color: #ff5252; }
  .pcoded-header.header-red .b-bg {
    background: #fff; }
    .pcoded-header.header-red .b-bg i {
      color: #ff5252;
      background-image: #ff5252;
      -webkit-background-clip: text;
      -webkit-text-fill-color: unset; }

.pcoded-header.header-purple {
  background: #9575CD;
  color: rgba(255, 255, 255, 0.8); }
  .pcoded-header.header-purple .profile-notification li > a {
    color: #888; }
  .pcoded-header.header-purple .profile-notification li.active, .pcoded-header.header-purple .profile-notification li:active, .pcoded-header.header-purple .profile-notification li:focus, .pcoded-header.header-purple .profile-notification li:hover {
    background: rgba(149, 117, 205, 0.1); }
    .pcoded-header.header-purple .profile-notification li.active > a, .pcoded-header.header-purple .profile-notification li:active > a, .pcoded-header.header-purple .profile-notification li:focus > a, .pcoded-header.header-purple .profile-notification li:hover > a {
      background: transparent; }
  .pcoded-header.header-purple .dropdown-menu {
    color: #888; }
    .pcoded-header.header-purple .dropdown-menu a {
      color: #888; }
    .pcoded-header.header-purple .dropdown-menu > li > a {
      color: #888; }
    .pcoded-header.header-purple .dropdown-menu > li.active, .pcoded-header.header-purple .dropdown-menu > li:active, .pcoded-header.header-purple .dropdown-menu > li:focus, .pcoded-header.header-purple .dropdown-menu > li:hover {
      background: rgba(149, 117, 205, 0.1);
      color: #888; }
      .pcoded-header.header-purple .dropdown-menu > li.active > a, .pcoded-header.header-purple .dropdown-menu > li:active > a, .pcoded-header.header-purple .dropdown-menu > li:focus > a, .pcoded-header.header-purple .dropdown-menu > li:hover > a {
        background: transparent; }
  .pcoded-header.header-purple .input-group .input-group-text,
  .pcoded-header.header-purple a,
  .pcoded-header.header-purple dropdown-toggle {
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-header.header-purple .input-group .input-group-text:hover,
    .pcoded-header.header-purple a:hover,
    .pcoded-header.header-purple dropdown-toggle:hover {
      color: #fff; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.header-purple .input-group .input-group-text,
      .pcoded-header.header-purple a,
      .pcoded-header.header-purple dropdown-toggle {
        color: #3f4d67; }
        .pcoded-header.header-purple .input-group .input-group-text:hover,
        .pcoded-header.header-purple a:hover,
        .pcoded-header.header-purple dropdown-toggle:hover {
          color: #2c3547; } }
  .pcoded-header.header-purple .main-search .search-close > .input-group-text {
    color: #9575CD; }
  .pcoded-header.header-purple .main-search.open .input-group .search-btn {
    background: #9575CD;
    border-color: #9575CD; }
    .pcoded-header.header-purple .main-search.open .input-group .search-btn .input-group-text {
      color: #fff; }
  .pcoded-header.header-purple .dropdown .notification .noti-body li.notification:hover {
    background: rgba(149, 117, 205, 0.1); }
  .pcoded-header.header-purple .dropdown .profile-notification .pro-head {
    color: #fff;
    background: #9575CD; }
    .pcoded-header.header-purple .dropdown .profile-notification .pro-head .dud-logout {
      color: #fff; }
  .pcoded-header.header-purple .dropdown.profile-notification.show:before {
    color: #9575CD; }
  .pcoded-header.header-purple .b-bg {
    background: #fff; }
    .pcoded-header.header-purple .b-bg i {
      color: #9575CD;
      background-image: #9575CD;
      -webkit-background-clip: text;
      -webkit-text-fill-color: unset; }

.pcoded-header.header-lightblue {
  background: #23b7e5;
  color: rgba(255, 255, 255, 0.8); }
  .pcoded-header.header-lightblue .profile-notification li > a {
    color: #888; }
  .pcoded-header.header-lightblue .profile-notification li.active, .pcoded-header.header-lightblue .profile-notification li:active, .pcoded-header.header-lightblue .profile-notification li:focus, .pcoded-header.header-lightblue .profile-notification li:hover {
    background: rgba(35, 183, 229, 0.1); }
    .pcoded-header.header-lightblue .profile-notification li.active > a, .pcoded-header.header-lightblue .profile-notification li:active > a, .pcoded-header.header-lightblue .profile-notification li:focus > a, .pcoded-header.header-lightblue .profile-notification li:hover > a {
      background: transparent; }
  .pcoded-header.header-lightblue .dropdown-menu {
    color: #888; }
    .pcoded-header.header-lightblue .dropdown-menu a {
      color: #888; }
    .pcoded-header.header-lightblue .dropdown-menu > li > a {
      color: #888; }
    .pcoded-header.header-lightblue .dropdown-menu > li.active, .pcoded-header.header-lightblue .dropdown-menu > li:active, .pcoded-header.header-lightblue .dropdown-menu > li:focus, .pcoded-header.header-lightblue .dropdown-menu > li:hover {
      background: rgba(35, 183, 229, 0.1);
      color: #888; }
      .pcoded-header.header-lightblue .dropdown-menu > li.active > a, .pcoded-header.header-lightblue .dropdown-menu > li:active > a, .pcoded-header.header-lightblue .dropdown-menu > li:focus > a, .pcoded-header.header-lightblue .dropdown-menu > li:hover > a {
        background: transparent; }
  .pcoded-header.header-lightblue .input-group .input-group-text,
  .pcoded-header.header-lightblue a,
  .pcoded-header.header-lightblue dropdown-toggle {
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-header.header-lightblue .input-group .input-group-text:hover,
    .pcoded-header.header-lightblue a:hover,
    .pcoded-header.header-lightblue dropdown-toggle:hover {
      color: #fff; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.header-lightblue .input-group .input-group-text,
      .pcoded-header.header-lightblue a,
      .pcoded-header.header-lightblue dropdown-toggle {
        color: #3f4d67; }
        .pcoded-header.header-lightblue .input-group .input-group-text:hover,
        .pcoded-header.header-lightblue a:hover,
        .pcoded-header.header-lightblue dropdown-toggle:hover {
          color: #2c3547; } }
  .pcoded-header.header-lightblue .main-search .search-close > .input-group-text {
    color: #23b7e5; }
  .pcoded-header.header-lightblue .main-search.open .input-group .search-btn {
    background: #23b7e5;
    border-color: #23b7e5; }
    .pcoded-header.header-lightblue .main-search.open .input-group .search-btn .input-group-text {
      color: #fff; }
  .pcoded-header.header-lightblue .dropdown .notification .noti-body li.notification:hover {
    background: rgba(35, 183, 229, 0.1); }
  .pcoded-header.header-lightblue .dropdown .profile-notification .pro-head {
    color: #fff;
    background: #23b7e5; }
    .pcoded-header.header-lightblue .dropdown .profile-notification .pro-head .dud-logout {
      color: #fff; }
  .pcoded-header.header-lightblue .dropdown.profile-notification.show:before {
    color: #23b7e5; }
  .pcoded-header.header-lightblue .b-bg {
    background: #fff; }
    .pcoded-header.header-lightblue .b-bg i {
      color: #23b7e5;
      background-image: #23b7e5;
      -webkit-background-clip: text;
      -webkit-text-fill-color: unset; }

.pcoded-header.header-dark {
  background: #323437;
  color: rgba(255, 255, 255, 0.8); }
  .pcoded-header.header-dark .profile-notification li > a {
    color: #888; }
  .pcoded-header.header-dark .profile-notification li.active, .pcoded-header.header-dark .profile-notification li:active, .pcoded-header.header-dark .profile-notification li:focus, .pcoded-header.header-dark .profile-notification li:hover {
    background: rgba(50, 52, 55, 0.1); }
    .pcoded-header.header-dark .profile-notification li.active > a, .pcoded-header.header-dark .profile-notification li:active > a, .pcoded-header.header-dark .profile-notification li:focus > a, .pcoded-header.header-dark .profile-notification li:hover > a {
      background: transparent; }
  .pcoded-header.header-dark .dropdown-menu {
    color: #888; }
    .pcoded-header.header-dark .dropdown-menu a {
      color: #888; }
    .pcoded-header.header-dark .dropdown-menu > li > a {
      color: #888; }
    .pcoded-header.header-dark .dropdown-menu > li.active, .pcoded-header.header-dark .dropdown-menu > li:active, .pcoded-header.header-dark .dropdown-menu > li:focus, .pcoded-header.header-dark .dropdown-menu > li:hover {
      background: rgba(50, 52, 55, 0.1);
      color: #888; }
      .pcoded-header.header-dark .dropdown-menu > li.active > a, .pcoded-header.header-dark .dropdown-menu > li:active > a, .pcoded-header.header-dark .dropdown-menu > li:focus > a, .pcoded-header.header-dark .dropdown-menu > li:hover > a {
        background: transparent; }
  .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark dropdown-toggle {
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-header.header-dark .input-group .input-group-text:hover,
    .pcoded-header.header-dark a:hover,
    .pcoded-header.header-dark dropdown-toggle:hover {
      color: #fff; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.header-dark .input-group .input-group-text,
      .pcoded-header.header-dark a,
      .pcoded-header.header-dark dropdown-toggle {
        color: #3f4d67; }
        .pcoded-header.header-dark .input-group .input-group-text:hover,
        .pcoded-header.header-dark a:hover,
        .pcoded-header.header-dark dropdown-toggle:hover {
          color: #2c3547; } }
  .pcoded-header.header-dark .main-search .search-close > .input-group-text {
    color: #323437; }
  .pcoded-header.header-dark .main-search.open .input-group .search-btn {
    background: #323437;
    border-color: #323437; }
    .pcoded-header.header-dark .main-search.open .input-group .search-btn .input-group-text {
      color: #fff; }
  .pcoded-header.header-dark .dropdown .notification .noti-body li.notification:hover {
    background: rgba(50, 52, 55, 0.1); }
  .pcoded-header.header-dark .dropdown .profile-notification .pro-head {
    color: #fff;
    background: #323437; }
    .pcoded-header.header-dark .dropdown .profile-notification .pro-head .dud-logout {
      color: #fff; }
  .pcoded-header.header-dark .dropdown.profile-notification.show:before {
    color: #323437; }
  .pcoded-header.header-dark .b-bg {
    background: #fff; }
    .pcoded-header.header-dark .b-bg i {
      color: #323437;
      background-image: #323437;
      -webkit-background-clip: text;
      -webkit-text-fill-color: unset; }

/* ====== [ Header color end ] ======== */
/* ====== [ Brand color start ] ======== */
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:after,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:before {
  background: #fff; }

.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu.on span {
  background: transparent; }

.pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg {
  background: #fff; }
  .pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg i {
    color: #15C042;
    background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.pcoded-navbar.brand-blue .header-logo,
.pcoded-navbar[class*="navbar-"].brand-blue .header-logo {
  background: #15C042; }
  .pcoded-navbar.brand-blue .header-logo .b-bg,
  .pcoded-navbar[class*="navbar-"].brand-blue .header-logo .b-bg {
    background: #fff; }
    .pcoded-navbar.brand-blue .header-logo .b-bg i,
    .pcoded-navbar[class*="navbar-"].brand-blue .header-logo .b-bg i {
      color: #15C042;
      -webkit-text-fill-color: unset; }

.pcoded-navbar.brand-red .header-logo,
.pcoded-navbar[class*="navbar-"].brand-red .header-logo {
  background: #ff5252; }
  .pcoded-navbar.brand-red .header-logo .b-bg,
  .pcoded-navbar[class*="navbar-"].brand-red .header-logo .b-bg {
    background: #fff; }
    .pcoded-navbar.brand-red .header-logo .b-bg i,
    .pcoded-navbar[class*="navbar-"].brand-red .header-logo .b-bg i {
      color: #ff5252;
      -webkit-text-fill-color: unset; }

.pcoded-navbar.brand-purple .header-logo,
.pcoded-navbar[class*="navbar-"].brand-purple .header-logo {
  background: #9575CD; }
  .pcoded-navbar.brand-purple .header-logo .b-bg,
  .pcoded-navbar[class*="navbar-"].brand-purple .header-logo .b-bg {
    background: #fff; }
    .pcoded-navbar.brand-purple .header-logo .b-bg i,
    .pcoded-navbar[class*="navbar-"].brand-purple .header-logo .b-bg i {
      color: #9575CD;
      -webkit-text-fill-color: unset; }

.pcoded-navbar.brand-lightblue .header-logo,
.pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo {
  background: #23b7e5; }
  .pcoded-navbar.brand-lightblue .header-logo .b-bg,
  .pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo .b-bg {
    background: #fff; }
    .pcoded-navbar.brand-lightblue .header-logo .b-bg i,
    .pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo .b-bg i {
      color: #23b7e5;
      -webkit-text-fill-color: unset; }

.pcoded-navbar.brand-dark .header-logo,
.pcoded-navbar[class*="navbar-"].brand-dark .header-logo {
  background: #323437; }
  .pcoded-navbar.brand-dark .header-logo .b-bg,
  .pcoded-navbar[class*="navbar-"].brand-dark .header-logo .b-bg {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .pcoded-navbar.brand-dark .header-logo .b-bg i,
    .pcoded-navbar[class*="navbar-"].brand-dark .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }

.pcoded-navbar.brand-default .header-logo,
.pcoded-navbar[class*="navbar-"].brand-default .header-logo {
  background: #fff; }

/* ====== [ Brand color end ] ======== */
/* ====== [ Menu color start ] ======== */
.pcoded-navbar.navbar-blue {
  background: #15C042; }
  .pcoded-navbar.navbar-blue .mobile-menu span {
    background-color: #fff; }
    .pcoded-navbar.navbar-blue .mobile-menu span:after, .pcoded-navbar.navbar-blue .mobile-menu span:before {
      background-color: #fff; }
  .pcoded-navbar.navbar-blue .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.navbar-blue .header-logo .b-bg {
    background: #15C042; }
    .pcoded-navbar.navbar-blue .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }
  .pcoded-navbar.navbar-blue .navbar-brand,
  .pcoded-navbar.navbar-blue .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar.navbar-blue .pcoded-menu-caption {
    color: #fff; }
  .pcoded-navbar.navbar-blue .pcoded-inner-navbar li a {
    color: #fff; }
  .pcoded-navbar.navbar-blue .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li:hover > a {
    color: #fff; }
  .pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #fff; }
  .pcoded-navbar.navbar-blue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
    box-shadow: none; }
  .pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next,
  .pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev {
    background: #15C042;
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next:hover,
    .pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev:hover {
      color: #fff; }
  .pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #15C042; }

.pcoded-navbar.navbar-red {
  background: #ff5252; }
  .pcoded-navbar.navbar-red .mobile-menu span {
    background-color: #fff; }
    .pcoded-navbar.navbar-red .mobile-menu span:after, .pcoded-navbar.navbar-red .mobile-menu span:before {
      background-color: #fff; }
  .pcoded-navbar.navbar-red .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.navbar-red .header-logo .b-bg {
    background: #ff5252; }
    .pcoded-navbar.navbar-red .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }
  .pcoded-navbar.navbar-red .navbar-brand,
  .pcoded-navbar.navbar-red .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar.navbar-red .pcoded-menu-caption {
    color: #fff; }
  .pcoded-navbar.navbar-red .pcoded-inner-navbar li a {
    color: #fff; }
  .pcoded-navbar.navbar-red .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar li:hover > a {
    color: #fff; }
  .pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #fff; }
  .pcoded-navbar.navbar-red .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
    box-shadow: none; }
  .pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next,
  .pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev {
    background: #ff5252;
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next:hover,
    .pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev:hover {
      color: #fff; }
  .pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #ff5252; }

.pcoded-navbar.navbar-purple {
  background: #9575CD; }
  .pcoded-navbar.navbar-purple .mobile-menu span {
    background-color: #fff; }
    .pcoded-navbar.navbar-purple .mobile-menu span:after, .pcoded-navbar.navbar-purple .mobile-menu span:before {
      background-color: #fff; }
  .pcoded-navbar.navbar-purple .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.navbar-purple .header-logo .b-bg {
    background: #9575CD; }
    .pcoded-navbar.navbar-purple .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }
  .pcoded-navbar.navbar-purple .navbar-brand,
  .pcoded-navbar.navbar-purple .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar.navbar-purple .pcoded-menu-caption {
    color: #fff; }
  .pcoded-navbar.navbar-purple .pcoded-inner-navbar li a {
    color: #fff; }
  .pcoded-navbar.navbar-purple .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li:hover > a {
    color: #fff; }
  .pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #fff; }
  .pcoded-navbar.navbar-purple .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
    box-shadow: none; }
  .pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next,
  .pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev {
    background: #9575CD;
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next:hover,
    .pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev:hover {
      color: #fff; }
  .pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #9575CD; }

.pcoded-navbar.navbar-lightblue {
  background: #23b7e5; }
  .pcoded-navbar.navbar-lightblue .mobile-menu span {
    background-color: #fff; }
    .pcoded-navbar.navbar-lightblue .mobile-menu span:after, .pcoded-navbar.navbar-lightblue .mobile-menu span:before {
      background-color: #fff; }
  .pcoded-navbar.navbar-lightblue .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.navbar-lightblue .header-logo .b-bg {
    background: #23b7e5; }
    .pcoded-navbar.navbar-lightblue .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }
  .pcoded-navbar.navbar-lightblue .navbar-brand,
  .pcoded-navbar.navbar-lightblue .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar.navbar-lightblue .pcoded-menu-caption {
    color: #fff; }
  .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li a {
    color: #fff; }
  .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li:hover > a {
    color: #fff; }
  .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #fff; }
  .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
    box-shadow: none; }
  .pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-next,
  .pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-prev {
    background: #23b7e5;
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-next:hover,
    .pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-prev:hover {
      color: #fff; }
  .pcoded-navbar.navbar-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #23b7e5; }

.pcoded-navbar.navbar-dark {
  background: #323437; }
  .pcoded-navbar.navbar-dark .mobile-menu span {
    background-color: #fff; }
    .pcoded-navbar.navbar-dark .mobile-menu span:after, .pcoded-navbar.navbar-dark .mobile-menu span:before {
      background-color: #fff; }
  .pcoded-navbar.navbar-dark .mobile-menu.on span {
    background-color: transparent; }
  .pcoded-navbar.navbar-dark .header-logo .b-bg {
    background: #323437; }
    .pcoded-navbar.navbar-dark .header-logo .b-bg i {
      color: #fff;
      -webkit-text-fill-color: unset; }
  .pcoded-navbar.navbar-dark .navbar-brand,
  .pcoded-navbar.navbar-dark .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar.navbar-dark .pcoded-menu-caption {
    color: #fff; }
  .pcoded-navbar.navbar-dark .pcoded-inner-navbar li a {
    color: #fff; }
  .pcoded-navbar.navbar-dark .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li:hover > a {
    color: #fff; }
  .pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
    background: #fff; }
  .pcoded-navbar.navbar-dark .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(0, 0, 0, 0.08);
    color: #fff;
    box-shadow: none; }
  .pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next,
  .pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev {
    background: #323437;
    color: rgba(255, 255, 255, 0.8); }
    .pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next:hover,
    .pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev:hover {
      color: #fff; }
  .pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
    color: #323437; }

body.layout-1 .pcoded-navbar.navbar-blue .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li.active:after {
    background: #fff; }
  body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li a {
    color: #fff; }
    body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li a:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

body.layout-1 .pcoded-navbar.navbar-red .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  body.layout-1 .pcoded-navbar.navbar-red .sidemenu li.active:after {
    background: #fff; }
  body.layout-1 .pcoded-navbar.navbar-red .sidemenu li a {
    color: #fff; }
    body.layout-1 .pcoded-navbar.navbar-red .sidemenu li a:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

body.layout-1 .pcoded-navbar.navbar-purple .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li.active:after {
    background: #fff; }
  body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li a {
    color: #fff; }
    body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li a:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

body.layout-1 .pcoded-navbar.navbar-lightblue .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  body.layout-1 .pcoded-navbar.navbar-lightblue .sidemenu li.active:after {
    background: #fff; }
  body.layout-1 .pcoded-navbar.navbar-lightblue .sidemenu li a {
    color: #fff; }
    body.layout-1 .pcoded-navbar.navbar-lightblue .sidemenu li a:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

body.layout-1 .pcoded-navbar.navbar-dark .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li.active:after {
    background: #fff; }
  body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li a {
    color: #fff; }
    body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li a:after {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

/* ====== [ Menu color end ] ======== */
/* ====== [ Active Color start ] ======== */
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea; }

.pcoded-navbar.active-blue[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #15C042; }

.pcoded-navbar.active-blue .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-blue .pcoded-inner-navbar li:hover > a {
  color: #15C042; }

.pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #15C042; }

.pcoded-navbar.active-blue .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #15C042; }

.pcoded-navbar.active-blue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff; }

.pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111; }

.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent; }

.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #15C042; }

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea; }

.pcoded-navbar.active-red[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #ff5252; }

.pcoded-navbar.active-red .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-red .pcoded-inner-navbar li:hover > a {
  color: #ff5252; }

.pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #ff5252; }

.pcoded-navbar.active-red .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #ff5252; }

.pcoded-navbar.active-red .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff; }

.pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111; }

.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent; }

.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #ff5252; }

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea; }

.pcoded-navbar.active-purple[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #9575CD; }

.pcoded-navbar.active-purple .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-purple .pcoded-inner-navbar li:hover > a {
  color: #9575CD; }

.pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #9575CD; }

.pcoded-navbar.active-purple .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #9575CD; }

.pcoded-navbar.active-purple .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff; }

.pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111; }

.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent; }

.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #9575CD; }

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea; }

.pcoded-navbar.active-lightblue[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #23b7e5; }

.pcoded-navbar.active-lightblue .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li:hover > a {
  color: #23b7e5; }

.pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #23b7e5; }

.pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #23b7e5; }

.pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff; }

.pcoded-navbar.active-lightblue.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111; }

.pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent; }

.pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #23b7e5; }

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea; }

.pcoded-navbar.active-dark[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #323437; }

.pcoded-navbar.active-dark .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-dark .pcoded-inner-navbar li:hover > a {
  color: #323437; }

.pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #323437; }

.pcoded-navbar.active-dark .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #323437; }

.pcoded-navbar.active-dark .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff; }

.pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111; }

.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent; }

.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #323437; }

body.layout-1 .pcoded-navbar.active-blue .sidemenu li.active {
  color: #15C042; }
  body.layout-1 .pcoded-navbar.active-blue .sidemenu li.active:after {
    background-color: #15C042; }

body.layout-1 .pcoded-navbar.active-red .sidemenu li.active {
  color: #ff5252; }
  body.layout-1 .pcoded-navbar.active-red .sidemenu li.active:after {
    background-color: #ff5252; }

body.layout-1 .pcoded-navbar.active-purple .sidemenu li.active {
  color: #9575CD; }
  body.layout-1 .pcoded-navbar.active-purple .sidemenu li.active:after {
    background-color: #9575CD; }

body.layout-1 .pcoded-navbar.active-lightblue .sidemenu li.active {
  color: #23b7e5; }
  body.layout-1 .pcoded-navbar.active-lightblue .sidemenu li.active:after {
    background-color: #23b7e5; }

body.layout-1 .pcoded-navbar.active-dark .sidemenu li.active {
  color: #323437; }
  body.layout-1 .pcoded-navbar.active-dark .sidemenu li.active:after {
    background-color: #323437; }

/* ====== [ Active Color end ] ======== */
/* ====== [ menu static start ] ======== */
.pcoded-navbar.menupos-static {
  position: absolute;
  height: 100%;
  min-height: 100%; }
  .pcoded-navbar.menupos-static .scroll-div.navbar-content {
    height: calc(100% - 100px);
    min-height: 100vh; }

/* ====== [ menu static end ] ======== */
/* ====== [Boxc start ] ======== */
body.box-layout {
  position: static; }
  body.box-layout .pcoded-header {
    position: relative; }
    body.box-layout .pcoded-header.headerpos-fixed ~ .pcoded-main-container {
      padding-top: 0; }
  body.box-layout .pcoded-navbar.theme-horizontal ~ .pcoded-header {
    position: relative; }
  @media only screen and (max-width: 992px) {
    body.box-layout {
      position: relative; }
      body.box-layout.container {
        padding: 0; } }

/* ====== [ Box end ] ======== */
/* ====== [ Header Fixed start ] ======== */
.pcoded-header.headerpos-fixed {
  position: fixed;
  top: 0; }
  .pcoded-header.headerpos-fixed ~ .pcoded-main-container {
    padding-top: 100px; }
    @media only screen and (max-width: 991px) {
      .pcoded-header.headerpos-fixed ~ .pcoded-main-container {
        padding-top: 200px; } }

/* ====== [Header Fixed  end ] ======== */
/* ====== [ Dropdown icon start ] ======== */
.pcoded-navbar.drp-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e847"; }

.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e8b1"; }

.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  transform: rotate(45deg); }

/* ====== [ Dropdown icon end ] =================== */
/* ====== [ Menu Item icon start ] ================ */
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  background: rgba(169, 183, 208, 0.05); }

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  font-family: 'feather';
  font-style: normal;
  padding-right: 4px;
  font-size: 13px;
  position: absolute;
  left: 35px;
  top: 10px;
  opacity: 1;
  visibility: visible;
  background: transparent; }

.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu > .pcoded-submenu > li > a:before {
  left: 5px; }

.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu > .pcoded-submenu > li > .pcoded-submenu > li > a:before {
  top: 12px; }

.pcoded-navbar.menu-item-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e897"; }

.pcoded-navbar.menu-item-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e83f"; }

.pcoded-navbar.menu-item-icon-style4 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e856"; }

.pcoded-navbar.menu-item-icon-style5 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e847"; }

.pcoded-navbar.menu-item-icon-style6 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e844"; }

/* ====== [ Menu Item icon end ] ================== */
/* ====== [ Menu background images start ] ======== */
.pcoded-navbar[class*="navbar-image"] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; }
  .pcoded-navbar[class*="navbar-image"] .navbar-brand,
  .pcoded-navbar[class*="navbar-image"] .pcoded-submenu {
    background: transparent; }
  .pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar > li.active > a,
  .pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(240, 240, 240, 0.4); }
  .pcoded-navbar[class*="navbar-image"]:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7); }

.pcoded-navbar.navbar-image-1 {
  background-image: url("../images/nav-bg/navbar-img-1.jpg"); }

.pcoded-navbar.navbar-image-2 {
  background-image: url("../images/nav-bg/navbar-img-2.jpg"); }

.pcoded-navbar.navbar-image-3 {
  background-image: url("../images/nav-bg/navbar-img-3.jpg"); }

.pcoded-navbar.navbar-image-4 {
  background-image: url("../images/nav-bg/navbar-img-4.jpg"); }

.pcoded-navbar.navbar-image-5 {
  background-image: url("../images/nav-bg/navbar-img-5.jpg"); }

body.layout-1 .pcoded-navbar[class*="navbar-image"] .header-logo {
  background: transparent; }

/* ====== [ Menu background images end ] ========== */
/* ====== [ Menu title color start ] ============== */
.pcoded-navbar.title-blue .pcoded-inner-navbar .pcoded-menu-caption {
  color: #15C042; }

.pcoded-navbar.title-red .pcoded-inner-navbar .pcoded-menu-caption {
  color: #ff5252; }

.pcoded-navbar.title-purple .pcoded-inner-navbar .pcoded-menu-caption {
  color: #9575CD; }

.pcoded-navbar.title-lightblue .pcoded-inner-navbar .pcoded-menu-caption {
  color: #23b7e5; }

.pcoded-navbar.title-dark .pcoded-inner-navbar .pcoded-menu-caption {
  color: #323437; }

/* ====== [ Menu title color end ] ================ */
/* ====== [ Hide Caption start ] ============== */
.pcoded-navbar.caption-hide .pcoded-menu-caption {
  display: none; }

/* ====== [ Hide Caption end ] ================ */
/* ==========================    Diffrent Layout Styles end     ====================== */
/* temp SCSS for document */
.doc-img > a {
  position: relative;
  width: 130px;
  height: 80px;
  display: inline-block;
  border: 3px solid #fff;
  margin-right: 5px;
  background: #F4FAFF;
  overflow: hidden; }
  .doc-img > a span {
    width: 100%;
    position: absolute; }
    .doc-img > a span:after, .doc-img > a span:before {
      content: "";
      height: 100%;
      position: absolute; }
    .doc-img > a span:before {
      width: 30%;
      left: 0;
      background: #fff; }
    .doc-img > a span:after {
      width: 70%;
      right: 0;
      background: #F4FAFF; }
  .doc-img > a > span:nth-child(1) {
    height: 20%;
    top: 0; }
  .doc-img > a > span:nth-child(2) {
    height: 80%;
    bottom: 0; }
  .doc-img > a.active {
    border-color: #15C042; }
    .doc-img > a.active:before {
      content: "\e83f";
      top: -4px;
      font-size: 20px;
      position: absolute;
      left: 10px;
      font-family: "feather";
      z-index: 1001;
      color: #15C042;
      transition: 0.3s ease-in-out;
      text-shadow: 0 3px 8px rgba(21, 192, 66, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1); }
    .doc-img > a.active:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.65); }
  .doc-img > a[data-value="menu-light"] span:before {
    background: #fff; }
  .doc-img > a[data-value="dark"] span:after {
    background: #101011; }

.doc-img.navbar-color > a[data-value="navbar-blue"] > span:before {
  background: #15C042; }

.doc-img.navbar-color > a[data-value="navbar-red"] > span:before {
  background: #ff5252; }

.doc-img.navbar-color > a[data-value="navbar-purple"] > span:before {
  background: #9575CD; }

.doc-img.navbar-color > a[data-value="navbar-lightblue"] > span:before {
  background: #23b7e5; }

.doc-img.navbar-color > a[data-value="navbar-dark"] > span:before {
  background: #323437; }

.doc-img.header-color > a[data-value="header-blue"] > span:nth-child(1):after {
  background: #15C042; }

.doc-img.header-color > a[data-value="header-red"] > span:nth-child(1):after {
  background: #ff5252; }

.doc-img.header-color > a[data-value="header-purple"] > span:nth-child(1):after {
  background: #9575CD; }

.doc-img.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after {
  background: #23b7e5; }

.doc-img.header-color > a[data-value="header-dark"] > span:nth-child(1):after {
  background: #323437; }

.doc-img.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before {
  background: #15C042; }

.doc-img.brand-color > a[data-value="brand-red"] > span:nth-child(1):before {
  background: #ff5252; }

.doc-img.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before {
  background: #9575CD; }

.doc-img.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before {
  background: #23b7e5; }

.doc-img.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before {
  background: #323437; }

.doc-img.active-color > a[data-value="active-default"] {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }

.doc-img.active-color > a[data-value="active-blue"] {
  background: #15C042; }

.doc-img.active-color > a[data-value="active-red"] {
  background: #ff5252; }

.doc-img.active-color > a[data-value="active-purple"] {
  background: #9575CD; }

.doc-img.active-color > a[data-value="active-lightblue"] {
  background: #23b7e5; }

.doc-img.active-color > a[data-value="active-dark"] {
  background: #323437; }

.doc-img.title-color > a[data-value="title-default"] {
  background: #fff; }

.doc-img.title-color > a[data-value="title-blue"] {
  background: #15C042; }

.doc-img.title-color > a[data-value="title-red"] {
  background: #ff5252; }

.doc-img.title-color > a[data-value="title-purple"] {
  background: #9575CD; }

.doc-img.title-color > a[data-value="title-lightblue"] {
  background: #23b7e5; }

.doc-img.title-color > a[data-value="title-dark"] {
  background: #323437; }

.doc-img.navbar-images > a {
  height: 130px;
  background-size: 37px;
  background-position: left top; }
  .doc-img.navbar-images > a span:before {
    background: transparent; }

.doc-img.navbar-images > a[data-value="navbar-image-1"] {
  background-image: url("../images/nav-bg/navbar-img-1.jpg"); }

.doc-img.navbar-images > a[data-value="navbar-image-2"] {
  background-image: url("../images/nav-bg/navbar-img-2.jpg"); }

.doc-img.navbar-images > a[data-value="navbar-image-3"] {
  background-image: url("../images/nav-bg/navbar-img-3.jpg"); }

.doc-img.navbar-images > a[data-value="navbar-image-4"] {
  background-image: url("../images/nav-bg/navbar-img-4.jpg"); }

.doc-img.navbar-images > a[data-value="navbar-image-5"] {
  background-image: url("../images/nav-bg/navbar-img-5.jpg"); }

.doc-img.navbar-images > a[data-value="navbar-image-6"] {
  background-image: url("../images/nav-bg/navbar-img-6.jpg"); }

.nav-link.active.h-blue {
  background: #15C042 !important; }

.nav-link.active.h-red {
  background: #ff5252 !important; }

.nav-link.active.h-purple {
  background: #9575CD !important; }

.nav-link.active.h-lightblue {
  background: #23b7e5 !important; }

.nav-link.active.h-dark {
  background: #323437 !important; }

@media all and (-ms-high-contrast: none) {
  .pcoded-header .dropdown.show::before {
    line-height: 0.1; }
  .pcoded-header .navbar-nav > li {
    display: inline-flex; }
  .pcoded-header .navbar-nav > li.dropdown {
    display: inline-block; }
  .dropdown-menu.show::before {
    top: -2px; }
  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu::after {
    left: 40px; } }

/* ===================================================================================================
 ==========================    Diffrent types of NAvbar Layout Styles end     ======================
 =================================================================================================== */
.pcoded-navbar.layout-2 a, .pcoded-navbar.layout-2-2 a, .pcoded-navbar.layout-3 a, .pcoded-navbar.layout-4 a, .pcoded-navbar.layout-4-2 a {
  color: rgba(255, 255, 255, 0.85); }

.pcoded-navbar.layout-2 .pcoded-submenu, .pcoded-navbar.layout-2-2 .pcoded-submenu, .pcoded-navbar.layout-3 .pcoded-submenu, .pcoded-navbar.layout-4 .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu {
  background-color: rgba(0, 0, 0, 0.05); }
  .pcoded-navbar.layout-2 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-2-2 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-3 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu .pcoded-submenu {
    background-color: transparent; }

.pcoded-navbar.layout-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-2-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-3.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-4.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-4-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  display: none; }

.pcoded-navbar.layout-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-2-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-3.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-4.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-4-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: rgba(255, 255, 255, 0.85); }

.pcoded-navbar.layout-2 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-3 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-4 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar .pcoded-menu-caption {
  display: none; }

.pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a {
  padding: 20px 15px;
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a .pcoded-badge {
    top: 25px; }
  .pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext {
    top: 25px; }

.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  top: 25px; }

.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger:after {
  display: none; }

.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger > a {
  background-color: rgba(0, 0, 0, 0.08); }

.pcoded-navbar.layout-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li:hover > a {
  color: #fff; }

.pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff; }

.pcoded-navbar.layout-2 {
  background: linear-gradient(253Deg, #1de9b6 0, #1dc4e9 70%); }

.pcoded-navbar.layout-2-2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
  .pcoded-navbar.layout-2-2 .navbar-brand .b-brand .b-bg {
    background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }

.pcoded-navbar.layout-3 {
  background: #15C042; }

.pcoded-navbar.layout-4, .pcoded-navbar.layout-4-2 {
  background: #212224; }
  .pcoded-navbar.layout-4 a, .pcoded-navbar.layout-4-2 a {
    color: rgba(255, 255, 255, 0.5); }
  .pcoded-navbar.layout-4 .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu {
    background-color: rgba(255, 255, 255, 0.05); }
    .pcoded-navbar.layout-4 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu .pcoded-submenu {
      background-color: transparent; }
  .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
  .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger > a {
    background-color: rgba(255, 255, 255, 0.08); }
  .pcoded-navbar.layout-4 .navbar-brand, .pcoded-navbar.layout-4-2 .navbar-brand {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .pcoded-navbar.layout-4 .navbar-brand .mobile-menu span,
    .pcoded-navbar.layout-4 .navbar-brand .mobile-menu span:after,
    .pcoded-navbar.layout-4 .navbar-brand .mobile-menu span:before, .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span,
    .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span:after,
    .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span:before {
      background: #fff; }
    .pcoded-navbar.layout-4 .navbar-brand .mobile-menu.on span, .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu.on span {
      background: transparent; }
    .pcoded-navbar.layout-4 .navbar-brand .b-brand .b-bg, .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg {
      background: #fff; }
      .pcoded-navbar.layout-4 .navbar-brand .b-brand .b-bg i, .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg i {
        color: #15C042;
        background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }

.pcoded-navbar.layout-4-2 .navbar-brand {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
  .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg {
    background: #fff; }
    .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg i {
      color: #15C042;
      background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }

body.layout-6 {
  background: #23b7e5;
  background-size: cover;
  background-attachment: fixed;
  background-position: top right;
  position: relative;
  /* for able pro style */
  /* dark navbar */ }
  body.layout-6 .page-header-title + .breadcrumb > .breadcrumb-item:last-child a,
  body.layout-6 .page-header-title h5,
  body.layout-6 .pcoded-header .input-group .input-group-text,
  body.layout-6 .pcoded-header .navbar-nav > li > .dropdown > .dropdown-toggle,
  body.layout-6 .pcoded-header .navbar-nav > li > .dropdown-toggle,
  body.layout-6 .pcoded-header .navbar-nav > li > a {
    color: #fff; }
  body.layout-6 .mobile-menu span,
  body.layout-6 .mobile-menu span:after,
  body.layout-6 .mobile-menu span:before,
  body.layout-6 .pcoded-navbar.menu-light .mobile-menu span,
  body.layout-6 .pcoded-navbar.menu-light .mobile-menu span:after,
  body.layout-6 .pcoded-navbar.menu-light .mobile-menu span:before {
    background: #fff; }
  body.layout-6 .breadcrumb-item + .breadcrumb-item::before,
  body.layout-6 .page-header-title + .breadcrumb > .breadcrumb-item a {
    color: rgba(255, 255, 255, 0.8); }
  body.layout-6 .pcoded-content {
    background: #F4FAFF; }
  body.layout-6 .navbar-brand,
  body.layout-6 .pcoded-header.header-blue,
  body.layout-6 .pcoded-navbar,
  body.layout-6 .pcoded-navbar.menu-light,
  body.layout-6 .pcoded-navbar.menu-light .header-logo {
    background: transparent;
    box-shadow: none; }
  body.layout-6 .pcoded-navbar .navbar-content {
    background: #3f4d67;
    border-radius: 0 10px 0 0;
    box-shadow: 0 0 20px 0 #3f4d67; }
  body.layout-6.box-layout .pcoded-navbar .navbar-content {
    border-radius: 10px 10px 0 0; }
  body.layout-6 .main-body {
    min-height: calc(100vh - 200px); }
  body.layout-6 .pcoded-navbar.menu-light .navbar-content {
    background: #fff;
    box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09); }
  body.layout-6 .pcoded-navbar.mob-open ~ .pcoded-header:before,
  body.layout-6 .pcoded-navbar.mob-open ~ .pcoded-main-container:before,
  body.layout-6 .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
  body.layout-6 .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
    background: transparent; }
  body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .b-brand {
    display: none; }
  body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu {
    right: auto;
    left: 20px;
    transition-delay: 0s; }
    body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu span {
      background: transparent; }
  body.layout-6 .pcoded-content {
    margin-top: 200px; }
  body.layout-6 .pcoded-content .pcoded-inner-content {
    margin-top: -200px;
    min-height: calc(100vh + 150px); }
  body.layout-6 .card {
    border-radius: 5px; }
  body.layout-6 .pcoded-navbar .header-logo {
    background: transparent; }
  body.layout-6 .pcoded-navbar.navbar-dark.brand-lightblue .header-logo,
  body.layout-6 .pcoded-navbar.navbar-dark[class*="navbar-"].brand-lightblue .header-logo,
  body.layout-6 .pcoded-navbar.navbar-dark .header-logo {
    background: transparent; }
  body.layout-6 .pcoded-navbar.navbar-dark .navbar-content {
    background: #323437;
    box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.08); }
  @media only screen and (max-width: 991px) {
    body.layout-6 .pcoded-header .input-group .input-group-text,
    body.layout-6 .pcoded-header .navbar-nav > li > .dropdown > .dropdown-toggle,
    body.layout-6 .pcoded-header .navbar-nav > li > .dropdown-toggle,
    body.layout-6 .pcoded-header .navbar-nav > li > a {
      color: #3f4d67; } }

body.layout-8 .pcoded-header {
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122); }

body.layout-8 .pcoded-navbar.menu-light {
  box-shadow: inset -1px 0 0 0 rgba(100, 121, 143, 0.122); }
  body.layout-8 .pcoded-navbar.menu-light .pcoded-submenu {
    background: transparent; }
  body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-menu-caption:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 20px;
    height: 1px;
    width: calc(100% - 40px);
    box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122); }
  body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-menu-caption:first-child:after {
    display: none; }
  body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a {
    border-radius: 0 20px 20px 0;
    margin-right: 15px;
    padding: 4px 15px; }
    body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a:after {
      top: 8px;
      right: 15px; }
    body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext {
      top: 9px; }
  body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
    background: rgba(35, 183, 229, 0.15);
    color: #23b7e5; }

body.layout-8 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  margin-right: 8px; }
  body.layout-8 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a:after {
    top: 8px;
    right: 15px; }

body.layout-8 .pcoded-navbar.active-blue .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(21, 192, 66, 0.15);
  color: #15C042; }

body.layout-8 .pcoded-navbar.active-red .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(255, 82, 82, 0.15);
  color: #ff5252; }

body.layout-8 .pcoded-navbar.active-purple .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(149, 117, 205, 0.15);
  color: #9575CD; }

body.layout-8 .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(35, 183, 229, 0.15);
  color: #23b7e5; }

body.layout-8 .pcoded-navbar.active-dark .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(50, 52, 55, 0.15);
  color: #323437; }

body.layout-8 .card {
  box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122);
  transition: all 0.25s ease-in-out; }
  body.layout-8 .card:hover {
    box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122), 0 2px 1px rgba(0, 0, 0, 0.05); }

body.layout-1 .pcoded-navbar .toggle-sidemenu {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #e6e6e6;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 0 0; }

body.layout-1 .pcoded-navbar.navbar-collapsed .toggle-sidemenu {
  display: none; }

body.layout-1 .pcoded-navbar .header-logo {
  padding: 10px 20px; }

body.layout-1 .pcoded-navbar .sidemenu {
  left: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.07); }
  body.layout-1 .pcoded-navbar .sidemenu li {
    position: relative; }
    body.layout-1 .pcoded-navbar .sidemenu li a {
      font-size: 22px;
      padding: 10px 29px;
      position: relative;
      cursor: pointer; }
      body.layout-1 .pcoded-navbar .sidemenu li a:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 60%;
        left: 20%;
        bottom: 0;
        border-bottom: 1px solid rgba(181, 189, 202, 0.2); }
    body.layout-1 .pcoded-navbar .sidemenu li:last-child a:after {
      display: none; }
    body.layout-1 .pcoded-navbar .sidemenu li.active {
      color: #15C042; }
      body.layout-1 .pcoded-navbar .sidemenu li.active:after {
        content: "";
        background-color: #15C042;
        z-index: 1027;
        position: absolute;
        right: 0;
        top: 7px;
        width: 3px;
        height: calc(100% - 14px);
        border-radius: 3px 0 0 3px; }

body.layout-1 .pcoded-navbar .layout1-nav {
  display: flex;
  align-items: stretch;
  height: auto;
  min-height: 100%; }

body.layout-1 .pcoded-navbar .side-content {
  flex: 1 1 0; }
  body.layout-1 .pcoded-navbar .side-content .sidelink {
    display: none; }
    body.layout-1 .pcoded-navbar .side-content .sidelink.active {
      display: block; }
    body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption {
      font-size: 16px;
      padding: 16px 15px 16px 18px;
      font-weight: 400;
      text-transform: capitalize; }
      body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 40px;
        left: 18px;
        bottom: 13px;
        border-bottom: 1px solid rgba(181, 189, 202, 0.2); }
    body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
      padding: 7px 7px 7px 40px; }
      body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
        left: 20px; }
    body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a {
      padding: 7px 7px 7px 60px; }
      body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > .pcoded-submenu > li > a:before {
        left: 40px; }
    body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a {
      padding: 12px 10px 12px 20px; }
      body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-micon {
        display: none; }
      body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li > a .pcoded-mtext {
        position: relative;
        top: 0; }
    body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.active:after, body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar > li.pcoded-trigger:after {
      display: none; }

body.layout-1 .pcoded-navbar.hide-sidemenu .sidemenu {
  position: absolute;
  left: -80px; }

body.layout-1 .pcoded-navbar.hide-sidemenu .toggle-sidemenu i {
  transform: rotate(180deg); }

.navbar-content {
  position: relative; }

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent; }

.ps__thumb-y {
  background: #2a3446; }

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #222a38; }

.div__logout__btn > a {
  color: white !important; }

.gradientcolor .btn-round {
  border-radius: 30px; }

.ChartShadow {
  filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2)); }

.chart-activity,
.chart-sale,
.chart-statistics,
.device-chart {
  margin: 0 auto; }

#Statistics-sale .amcharts-cursor-fill {
  filter: url(#shadow);
  fill: rgba(255, 255, 255, 0.3);
  stroke-width: 0; }

.amcharts-zoom-out-bg,
.amcharts-zoom-out-image {
  display: none; }

/* statistics section */
.card-command .card-icon {
  opacity: 0.5; }

.mat-drp .btn.dropdown-toggle, .mat-drp .dropdown-toggle.introjs-button {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #d8d8d8; }
  .mat-drp .btn.dropdown-toggle:after, .mat-drp .dropdown-toggle.introjs-button:after {
    display: none; }
  .mat-drp .btn.dropdown-toggle:focus, .mat-drp .dropdown-toggle.introjs-button:focus {
    box-shadow: none;
    outline: none; }

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .table-card .row-table i {
    padding: 50px 20px; }
  .table-card .row-table > [class*=col-] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }
    .table-card .row-table > [class*=col-] .row {
      display: flex;
      align-items: center; }

.rides-bar i.rides-icon {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 15px; }

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0; }

.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0; }

.profit-bar i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 13px; }

.assets-value .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center 103%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1; }

.assets-value .card-block {
  position: relative;
  z-index: 5; }

@media screen and (max-width: 1199px) {
  .assets-value .bg-img {
    background: none; } }

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%); }

.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative; }

.chat-sanders .scroll-wrapper {
  height: 305px; }

.chat-sanders .received-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -12px;
    bottom: 18px;
    transform: rotate(270deg);
    border: 6px solid transparent;
    border-bottom-color: #fff; }

.chat-sanders .send-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .send-chat .msg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .send-chat .msg:after {
    content: "";
    position: absolute;
    right: -11px;
    bottom: 18px;
    transform: rotate(450deg);
    border: 6px solid transparent;
    border-bottom-color: #1de9b6; }

.chat-sanders .btn, .chat-sanders .introjs-button {
  background: none;
  opacity: 0.4; }

.chat-sanders .form-control {
  background: #f4f7fa; }

.chat-sanders .input-group {
  background: transparent; }

.widget-menu {
  background: linear-gradient(-135deg, #88d3ce 0%, #6e45e2 100%); }
  .widget-menu .widget-title {
    border-top: 1px solid #676fb9; }
  .widget-menu i {
    opacity: 0.5; }

.to-do .to-do-button {
  position: absolute;
  bottom: 30px;
  right: 20px; }
  .to-do .to-do-button .btn, .to-do .to-do-button .introjs-button {
    width: 40px;
    height: 40px;
    float: right;
    border-radius: 50px;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0; }

.lazy-dog i {
  opacity: 0.5; }

.widget-content .widget-lorem .media h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center; }

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #e3e3e3; }
  .note-bar .friendlist-box:first-child {
    border: 0; }
  .note-bar .friendlist-box h6 {
    display: inline-block; }
  .note-bar .friendlist-box i {
    opacity: 0.2;
    color: #888; }

.to-do-list h6 {
  display: inline-block; }

.to-do-list .check-task > input:checked ~ .row,
.to-do-list .check-task > input[checked] ~ .row,
.to-do-list .done-task {
  opacity: 0.7; }
  .to-do-list .check-task > input:checked ~ .row > div,
  .to-do-list .check-task > input[checked] ~ .row > div,
  .to-do-list .done-task > div {
    filter: grayscale(0.8); }
  .to-do-list .check-task > input:checked ~ .row h6,
  .to-do-list .check-task > input:checked ~ .row p,
  .to-do-list .check-task > input:checked ~ .row span,
  .to-do-list .check-task > input[checked] ~ .row h6,
  .to-do-list .check-task > input[checked] ~ .row p,
  .to-do-list .check-task > input[checked] ~ .row span,
  .to-do-list .done-task h6,
  .to-do-list .done-task p,
  .to-do-list .done-task span {
    text-decoration: line-through; }

.to-do-list .checkbox-fade .check-task {
  display: block; }

.to-do-list .checkbox-fade .to-content {
  display: inline-block; }

.to-do-list .checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin: 0;
  top: 5px; }
  .to-do-list .checkbox-fade .cr .cr-icon {
    color: #fff;
    font-size: 0.8em;
    left: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%; }

.to-do-list .checkbox-fade label input[type=checkbox] {
  display: none; }

.to-do-list .checkbox-fade label input[type=checkbox] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in; }

.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1; }

.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border: 0; }

.User-Activity .table td,
.user-list .table td {
  vertical-align: middle; }

.User-Activity .table thead th,
.user-list .table thead th {
  border: 0; }

.Application-list .table td,
.code-table .table td {
  vertical-align: middle; }

.Application-list .table .label,
.code-table .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.Application-list thead th,
.code-table thead th {
  border: 0; }

.Recent-Users .table tr:first-child td,
.User-Lists .table tr:first-child td {
  border-top: 0; }

.Recent-Users .table td,
.User-Lists .table td {
  vertical-align: middle; }

.Recent-Users .table .label,
.User-Lists .table .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center; }
  .social-media .progress h5 {
    position: relative;
    top: -2px; }

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none; }

.post-emoticon li {
  display: inline-block; }

.post-emoticon i {
  position: relative;
  top: 4px; }

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.loction-user .row {
  padding: 35px 30px; }

.loction-user i {
  opacity: 0.5; }

.loction-user .loction-progress {
  padding: 35px 30px; }
  .loction-user .loction-progress .progress {
    justify-content: center;
    height: 10px;
    border-radius: 0; }

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none; }

.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px; }

.Design-sprint .btn, .Design-sprint .introjs-button,
.dashboard-kit .btn,
.dashboard-kit .introjs-button {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0; }

.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3; }

.ux-designer {
  position: relative;
  padding: 35px 30px; }
  .ux-designer .btn, .ux-designer .introjs-button {
    border-radius: 50px;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: -20px;
    padding: 0;
    margin: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0; }
  .task-list:after {
    content: "";
    position: absolute;
    background: #ecedef;
    height: 100%;
    width: 2px;
    top: 0;
    left: 30px;
    z-index: 1; }
  .task-list li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative; }
    .task-list li:last-child {
      margin-bottom: 0; }
    .task-list li .task-icon {
      position: absolute;
      left: 22px;
      top: 13px;
      border-radius: 50%;
      padding: 2px;
      width: 17px;
      height: 17px;
      z-index: 2;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-main {
  padding: 30px 25px; }
  .project-main i {
    opacity: 0.4; }

.user-chart i {
  opacity: 0.3; }

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0; }

.user-card .label {
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.Active-visitor .progress {
  height: 7px; }

.Active-visitor .card-active > div + div {
  border-left: 1px solid #eaeaea; }

@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px; } }

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px; }

.Invoice-bar i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .location-sale .card-icon {
      display: none; } }

.card-Impression i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.card-Revenue i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.card-customer i {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 375px) {
  .card-customer i {
    width: 80px;
    height: 80px;
    font-size: 50px; } }

.ticket-customer i {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px; }

.ticket-visitor .progress {
  border-radius: 0;
  height: 13px; }

.customer-visitor i {
  opacity: 0.2;
  font-size: 118px;
  position: absolute;
  bottom: 0;
  left: 0; }

.card-social:hover i {
  font-size: 40px;
  transition: all 0.3s ease-in-out; }

.card-social .progress {
  height: 6px; }

.card-social .card-active > div + div {
  border-left: 1px solid #eaeaea; }

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px; }

.team-leader .slide {
  margin-bottom: 32px; }
  .team-leader .slide li {
    width: 10px;
    height: 10px;
    background: #e3e3e3;
    margin: 0 6px;
    border-top: none;
    border-bottom: none;
    border-radius: 50px; }
  .team-leader .slide .carousel-indicators {
    bottom: -44px; }
    .team-leader .slide .carousel-indicators .active {
      background: #869791; }

.affilate-offers .card-icon {
  opacity: 0.5; }

.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px; }

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0; }
  .earning-date .bd-example .nav-pills {
    background: transparent;
    padding: 0;
    box-shadow: none; }
    .earning-date .bd-example .nav-pills .nav-link {
      min-width: auto;
      padding: 6px 20px;
      color: #fff;
      position: relative; }
    .earning-date .bd-example .nav-pills .nav-link.active {
      background: #fff;
      box-shadow: none;
      color: #000; }
      .earning-date .bd-example .nav-pills .nav-link.active:after {
        content: "";
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        top: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 11px; }
  .earning-date .bd-example .tab-content {
    background: transparent;
    padding: 0;
    box-shadow: none; }

.sale-view i {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-task i {
  opacity: 0.5; }

.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px; }

.project-task .progress {
  height: 6px; }

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px; }

.card-event i {
  position: absolute;
  bottom: 36px;
  right: 27px; }

.bitcoin-wallet i {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5; }

.summary-box .card-icon {
  opacity: 0.4; }

.feature-card-box .feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  padding: 10px;
  font-size: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.feature-card-box:hover .feature-icon {
  transform: scale(1.2);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0; }

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
  border-radius: 3px;
  padding: 0 3px; }

@media screen and (max-width: 767px) {
  .user-designer [class*=col-]:last-child {
    margin-top: 15px; }
  div.dataTables_wrapper div.dataTables_filter label {
    float: none !important;
    text-align: center; } }

.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd; }

/* ===================== to do page ============================= */
#task-container ul {
  overflow: hidden;
  padding: 0; }

#task-container li {
  float: left;
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  color: #666;
  border-top: 5px solid #15C042;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  transition: all 0.3s;
  position: relative; }
  #task-container li:nth-child(2n) {
    margin-right: 0; }

#task-container li.complete {
  opacity: 1;
  border-top: 9px solid #f44236;
  transition: all ease-in 0.3s; }
  #task-container li.complete:before {
    background: url("../images/complete.png") no-repeat;
    position: absolute;
    top: 5px;
    right: 5px;
    content: "";
    width: 55px;
    height: 55px;
    background-size: 100%; }
  #task-container li.complete p {
    text-decoration: line-through; }

@media screen and (max-width: 580px) {
  #task-container li {
    width: 100%; } }

.new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px; }

.new-task label input[type=checkbox] {
  display: none; }

.checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin-right: 16px;
  top: 0; }

.task-panel .to-do-label {
  margin-bottom: 15px; }
  .task-panel .to-do-label:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0; }

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through; }

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0; }

.to-do-list:hover i {
  opacity: 1;
  transition: opacity ease-in 0.3s; }

.note-card .note-box-aside {
  border-right: 1px solid #ddd; }

.note-card .Note-header {
  padding: 20px 0; }

.note-card #Note-pad {
  border: none;
  resize: none;
  background: 0 0;
  padding: 0 20px 0 50px;
  line-height: 35px; }

.note-card .note-write {
  position: relative;
  background: -webkit-linear-gradient(to top, #98dcfa 0%, #e1f5fe 5%) 0 0;
  background-size: 100% 35px; }
  .note-card .note-write:before {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    left: 32px;
    bottom: 0;
    border-left: 1px solid #4fc3f7; }
  .note-card .note-write:after {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    left: 34px;
    bottom: 0;
    border-left: 1px solid #4fc3f7; }

.note-card .Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  transform: translateX(10px); }

.note-card #Note-list li:hover .Note-delete {
  opacity: 1;
  transform: translateX(0px); }

/* ===================== Gallery page ============================= */
.gallery-masonry .card-columns {
  column-count: 4; }

@media screen and (max-width: 1400px) {
  .gallery-masonry .card-columns {
    column-count: 3; } }

@media screen and (max-width: 992px) {
  .gallery-masonry .card-columns {
    column-count: 2; } }

@media screen and (max-width: 575px) {
  .gallery-masonry .card-columns {
    column-count: 1; } }

.job-meta-data i {
  margin-right: 5px;
  color: #15C042; }

/* ===================== Task-list page ============================= */
.task-data img {
  width: 40px;
  box-shadow: none; }

.task-data i {
  color: #111; }

.task-data .dropdown-toggle:after {
  color: #111; }

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer; }

.task-board-left .taskboard-right-progress,
.task-board-left .taskboard-right-revision,
.task-board-left .taskboard-right-users {
  padding-top: 10px; }

.task-board-left .taskboard-right-progress .progress {
  height: 9px;
  margin-bottom: 25px; }

.task-board-left .user-box .media-object {
  height: 50px;
  width: 50px;
  cursor: pointer; }

.task-board-left .user-box .media-left {
  position: relative; }

.task-board-left .user-box .btn.btn-icon, .task-board-left .user-box .btn-icon.introjs-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center; }

.task-board-left .live-status {
  height: 8px;
  width: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  top: 5px; }

.filter-bar .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  padding: 0.5rem 1rem; }
  @media screen and (max-width: 557px) {
    .filter-bar .navbar .f-text {
      display: block;
      width: 100%; }
    .filter-bar .navbar .f-view {
      padding-left: 24px; }
      .filter-bar .navbar .f-view span {
        display: block;
        width: 100%;
        margin-bottom: 10px; } }

.filter-bar .label {
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 7px; }

.filter-bar .task-detail {
  margin-bottom: 5px; }

.filter-bar .card-task .task-list-table {
  display: inline-block; }
  .filter-bar .card-task .task-list-table img {
    width: 40px;
    box-shadow: none; }
  .filter-bar .card-task .task-list-table i {
    color: #111; }

.filter-bar .card-task .task-board {
  float: right;
  margin-top: 5px; }
  .filter-bar .card-task .task-board .dropdown {
    display: inline-block; }
  .filter-bar .card-task .task-board .btn, .filter-bar .card-task .task-board .introjs-button {
    padding: 4px 10px;
    font-size: 10px;
    margin: 0; }

.assign-user img,
.task-comment img {
  width: 45px;
  box-shadow: none; }

/* ===================== Invoice page ============================= */
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center; }

.invoice-table.table {
  padding-left: 20px; }
  .invoice-table.table td {
    border: 0;
    padding: 4px 0; }

.invoive-info {
  display: flex;
  margin-bottom: 30px; }
  .invoive-info h6 {
    margin-bottom: 20px;
    text-transform: uppercase; }
  .invoive-info .invoice-order.table {
    padding-left: 0; }
    .invoive-info .invoice-order.table th {
      border: 0;
      padding: 4px 0; }
      .invoive-info .invoice-order.table th:first-child {
        padding-left: 0;
        width: 80px; }

.invoice-total {
  background: #f3f3f3;
  padding: 20px 10px;
  margin: 10px 0 25px; }
  .invoice-total .table {
    margin-bottom: 0; }
    .invoice-total .table th {
      border: 0;
      padding: 4px 0;
      text-align: right; }
    .invoice-total .table td {
      text-align: right; }
    .invoice-total .table tbody {
      padding-right: 20px;
      float: right; }

.invoice-summary .label {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px; }

.invoice-list .btn, .invoice-list .introjs-button {
  padding: 5px 10px;
  font-size: 12px; }

.invoice-list .task-list-table {
  display: inline-block; }

/*  ================ new css =================== */
.syntax-output {
  border-radius: 0.25rem;
  border: 1px solid #eaeaea; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px !important;
  background-color: #15C042;
  color: #fff; }

.owl-carousel button:focus {
  outline: none;
  color: #fff; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #15C042;
  color: #fff; }

.counter b {
  font-size: 24px; }

.tour-mobile .error-block {
  display: none; }

.message-mobile span {
  font-size: 16px; }

.message-mobile .task-right-header-status {
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer; }
  @media screen and (min-width: 992px) {
    .message-mobile .task-right-header-status {
      display: none; } }

.fullcalendar-card .fc-button {
  background-color: #15C042;
  border-color: #fff;
  color: #fff;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  height: 37px;
  padding: 0 15px; }

.fullcalendar-card h2 {
  font-size: 28px; }

@media screen and (max-width: 768px) {
  .fullcalendar-card .fc .fc-toolbar > * > * {
    float: none; }
  .fullcalendar-card .fc-toolbar .fc-left, .fullcalendar-card .fc-toolbar .fc-right, .fullcalendar-card .fc-toolbar .fc-center {
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px; } }

.ck-content strong {
  font-weight: 600; }

div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  box-shadow: none !important; }

pre[class*=language-]:after, pre[class*=language-]:before {
  display: none; }

#chat-scroll {
  height: 280px;
  position: relative; }

.msg-user-list.scroll-div {
  height: calc(100vh - 300px);
  position: relative; }

.msg-user-chat.scroll-div {
  height: calc(100vh - 330px);
  position: relative; }

@media screen and (max-width: 1199px) {
  .note-card .note-box-aside {
    border: none !important; } }

@media screen and (max-width: 991px) {
  .ch-block {
    display: none; }
  .msg-block.dis-chat .taskboard-right-progress {
    display: none; }
  .msg-block.dis-chat .ch-block {
    display: block; } }

@media screen and (max-width: 992px) {
  .tour-mobile .error-block {
    display: block; }
  .tour-mobile .page-wrapper {
    display: none; } }

@media screen and (min-width: 768px) {
  .horizontal-mobile {
    display: none; } }

/**  =====================
      Form Componant css start
==========================  **/
.custom-select, .dataTable-selector, .dataTable-input, .form-control {
  padding: 10px 16px;
  font-size: 14px;
  height: auto;
  box-shadow: 0 1px 2px 0 rgba(57, 70, 92, 0.05); }
  .custom-select:focus,
  .dataTable-selector:focus,
  .dataTable-input:focus,
  .form-control:focus {
    box-shadow: none; }
  .custom-select::-moz-placeholder,
  .dataTable-selector::-moz-placeholder,
  .dataTable-input::-moz-placeholder,
  .form-control::-moz-placeholder {
    color: #aaaeb3;
    opacity: 1; }
  .custom-select:-ms-input-placeholder,
  .dataTable-selector:-ms-input-placeholder,
  .dataTable-input:-ms-input-placeholder,
  .form-control:-ms-input-placeholder {
    color: #aaaeb3; }
  .custom-select::-webkit-input-placeholder,
  .dataTable-selector::-webkit-input-placeholder,
  .dataTable-input::-webkit-input-placeholder,
  .form-control::-webkit-input-placeholder {
    color: #aaaeb3; }

.form-select:focus {
  box-shadow: none; }

.dataTable-selector {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding-right: 2.25rem; }

.dataTable-selector,
.dataTable-input {
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

/* input group start */
.form-group label {
  color: #666; }

.input-group {
  background: #F4FAFF; }
  .input-group .input-group-text {
    padding-left: 15px;
    padding-right: 15px;
    background: transparent; }
    .input-group .input-group-text i {
      font-size: 20px; }
  .input-group .custom-file-label {
    margin-bottom: 0; }
  .input-group .btn, .input-group .introjs-button {
    margin-bottom: 0;
    margin-right: 0; }

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5; }
  .cust-file-button .custom-file-label::after {
    padding: 0.775rem 0.75rem;
    height: 2.65rem; }

.custom-select {
  height: calc(2.25rem + 9px); }

/* input group End */
/**====== Form Componant css end ======**/
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #15C042;
  background-color: #15C042; }

.custom-control-input.input-primary:checked ~ .custom-control-label::before {
  border-color: #15C042;
  background-color: #15C042; }

.custom-control-input.input-primary:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #15C042; }

.custom-control-input.input-primary:not(:disabled):active ~ .custom-control-label::before {
  background-color: #15C042;
  border-color: #15C042; }

.custom-control-input.input-light-primary:checked ~ .custom-control-label::before {
  border-color: #d0f2d9;
  background-color: #d0f2d9; }

.custom-control-input.input-light-primary:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d0f2d9; }

.custom-control-input.input-light-primary:not(:disabled):active ~ .custom-control-label::before {
  background-color: #d0f2d9;
  border-color: #d0f2d9; }

.custom-control-input.input-light-primary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2315C042' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-primary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2315C042' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-primary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2315C042'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-primary:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #15C042; }

.custom-control-input.input-secondary:checked ~ .custom-control-label::before {
  border-color: #748892;
  background-color: #748892; }

.custom-control-input.input-secondary:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #748892; }

.custom-control-input.input-secondary:not(:disabled):active ~ .custom-control-label::before {
  background-color: #748892;
  border-color: #748892; }

.custom-control-input.input-light-secondary:checked ~ .custom-control-label::before {
  border-color: #e3e7e9;
  background-color: #e3e7e9; }

.custom-control-input.input-light-secondary:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3e7e9; }

.custom-control-input.input-light-secondary:not(:disabled):active ~ .custom-control-label::before {
  background-color: #e3e7e9;
  border-color: #e3e7e9; }

.custom-control-input.input-light-secondary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23748892' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-secondary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23748892' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-secondary:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23748892'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-secondary:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #748892; }

.custom-control-input.input-success:checked ~ .custom-control-label::before {
  border-color: #15C042;
  background-color: #15C042; }

.custom-control-input.input-success:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #15C042; }

.custom-control-input.input-success:not(:disabled):active ~ .custom-control-label::before {
  background-color: #15C042;
  border-color: #15C042; }

.custom-control-input.input-light-success:checked ~ .custom-control-label::before {
  border-color: #d0f2d9;
  background-color: #d0f2d9; }

.custom-control-input.input-light-success:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d0f2d9; }

.custom-control-input.input-light-success:not(:disabled):active ~ .custom-control-label::before {
  background-color: #d0f2d9;
  border-color: #d0f2d9; }

.custom-control-input.input-light-success:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2315C042' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-success:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2315C042' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-success:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2315C042'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-success:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #15C042; }

.custom-control-input.input-info:checked ~ .custom-control-label::before {
  border-color: #3ebfea;
  background-color: #3ebfea; }

.custom-control-input.input-info:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3ebfea; }

.custom-control-input.input-info:not(:disabled):active ~ .custom-control-label::before {
  background-color: #3ebfea;
  border-color: #3ebfea; }

.custom-control-input.input-light-info:checked ~ .custom-control-label::before {
  border-color: #d8f2fb;
  background-color: #d8f2fb; }

.custom-control-input.input-light-info:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d8f2fb; }

.custom-control-input.input-light-info:not(:disabled):active ~ .custom-control-label::before {
  background-color: #d8f2fb;
  border-color: #d8f2fb; }

.custom-control-input.input-light-info:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233ebfea' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-info:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233ebfea' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-info:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ebfea'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-info:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #3ebfea; }

.custom-control-input.input-warning:checked ~ .custom-control-label::before {
  border-color: #f4c22b;
  background-color: #f4c22b; }

.custom-control-input.input-warning:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f4c22b; }

.custom-control-input.input-warning:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f4c22b;
  border-color: #f4c22b; }

.custom-control-input.input-light-warning:checked ~ .custom-control-label::before {
  border-color: #fdf3d5;
  background-color: #fdf3d5; }

.custom-control-input.input-light-warning:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fdf3d5; }

.custom-control-input.input-light-warning:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fdf3d5;
  border-color: #fdf3d5; }

.custom-control-input.input-light-warning:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f4c22b' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-warning:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f4c22b' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-warning:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f4c22b'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-warning:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #f4c22b; }

.custom-control-input.input-danger:checked ~ .custom-control-label::before {
  border-color: #f44236;
  background-color: #f44236; }

.custom-control-input.input-danger:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f44236; }

.custom-control-input.input-danger:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f44236;
  border-color: #f44236; }

.custom-control-input.input-light-danger:checked ~ .custom-control-label::before {
  border-color: #fdd9d7;
  background-color: #fdd9d7; }

.custom-control-input.input-light-danger:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fdd9d7; }

.custom-control-input.input-light-danger:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fdd9d7;
  border-color: #fdd9d7; }

.custom-control-input.input-light-danger:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f44236' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-danger:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f44236' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-danger:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f44236'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-danger:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #f44236; }

.custom-control-input.input-light:checked ~ .custom-control-label::before {
  border-color: #f2f2f2;
  background-color: #f2f2f2; }

.custom-control-input.input-light:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f2f2f2; }

.custom-control-input.input-light:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f2f2f2;
  border-color: #f2f2f2; }

.custom-control-input.input-light-light:checked ~ .custom-control-label::before {
  border-color: #fcfcfc;
  background-color: #fcfcfc; }

.custom-control-input.input-light-light:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fcfcfc; }

.custom-control-input.input-light-light:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fcfcfc;
  border-color: #fcfcfc; }

.custom-control-input.input-light-light:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f2f2f2' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-light:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23f2f2f2' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-light:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f2f2f2'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-light:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #f2f2f2; }

.custom-control-input.input-dark:checked ~ .custom-control-label::before {
  border-color: #37474f;
  background-color: #37474f; }

.custom-control-input.input-dark:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #37474f; }

.custom-control-input.input-dark:not(:disabled):active ~ .custom-control-label::before {
  background-color: #37474f;
  border-color: #37474f; }

.custom-control-input.input-light-dark:checked ~ .custom-control-label::before {
  border-color: #d7dadc;
  background-color: #d7dadc; }

.custom-control-input.input-light-dark:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d7dadc; }

.custom-control-input.input-light-dark:not(:disabled):active ~ .custom-control-label::before {
  background-color: #d7dadc;
  border-color: #d7dadc; }

.custom-control-input.input-light-dark:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337474f' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input.input-light-dark:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2337474f' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input.input-light-dark:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2337474f'/%3e%3c/svg%3e"); }

.custom-switch .custom-control-input.input-light-dark:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: #37474f; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px); }

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.introjs-button:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.introjs-button:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px); }

.minicolors .form-control {
  padding: 6px 12px 5px 44px; }

/* form-select */
.select2-container {
  width: 100% !important; }

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer; }

.bootstrap-tagsinput {
  width: 100%;
  border: 2px solid #ced4da;
  background: transparent;
  border-radius: 0.375rem;
  padding: 4px 15px; }
  .bootstrap-tagsinput input {
    color: #888; }

.custom-file-label {
  height: calc(1.5em + 0.75rem + 5px); }
  .custom-file-label:after {
    height: calc(1.5em + 0.75rem + 2.5px); }

.datepicker {
  width: 265px;
  padding: 10px; }
  .datepicker.dropdown-menu {
    padding: 10px; }
  .datepicker.datepicker-orient-top {
    margin-top: 8px; }
  .datepicker table {
    width: 100%; }
  .datepicker td,
  .datepicker th {
    font-weight: regular;
    width: 35px;
    height: 35px; }
  .datepicker thead th {
    color: #111; }
    .datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
      font-weight: 500;
      color: #888; }
      .datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
        font-size: 1.2rem;
        color: #888; }
        .datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
          line-height: 0;
          vertical-align: middle; }
      .datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
        background: #F4FAFF !important; }
    .datepicker thead th.dow {
      color: #888;
      font-weight: 500; }
  .datepicker tbody tr > td.day {
    color: #888; }
    .datepicker tbody tr > td.day:hover {
      background: #F4FAFF;
      color: #888; }
    .datepicker tbody tr > td.day.old {
      color: #888; }
    .datepicker tbody tr > td.day.new {
      color: #888; }
    .datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
      background: #15C042;
      color: #fff; }
    .datepicker tbody tr > td.day.today {
      position: relative;
      background: #15C042 !important;
      color: #fff !important; }
      .datepicker tbody tr > td.day.today:before {
        content: "";
        display: inline-block;
        border: solid transparent;
        border-width: 0 0 7px 7px;
        border-bottom-color: #ffffff;
        border-top-color: #888;
        position: absolute;
        bottom: 4px;
        right: 4px; }
    .datepicker tbody tr > td.day.range {
      background: #111; }
  .datepicker tbody tr > td span.hour,
  .datepicker tbody tr > td span.minute,
  .datepicker tbody tr > td span.month,
  .datepicker tbody tr > td span.year {
    color: #888; }
    .datepicker tbody tr > td span.hour:hover,
    .datepicker tbody tr > td span.minute:hover,
    .datepicker tbody tr > td span.month:hover,
    .datepicker tbody tr > td span.year:hover {
      background: #F4FAFF; }
    .datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
    .datepicker tbody tr > td span.minute.active,
    .datepicker tbody tr > td span.minute.active.focused:hover,
    .datepicker tbody tr > td span.minute.active:hover,
    .datepicker tbody tr > td span.minute.focused,
    .datepicker tbody tr > td span.minute.focused:hover,
    .datepicker tbody tr > td span.month.active,
    .datepicker tbody tr > td span.month.active.focused:hover,
    .datepicker tbody tr > td span.month.active:hover,
    .datepicker tbody tr > td span.month.focused,
    .datepicker tbody tr > td span.month.focused:hover,
    .datepicker tbody tr > td span.year.active,
    .datepicker tbody tr > td span.year.active.focused:hover,
    .datepicker tbody tr > td span.year.active:hover,
    .datepicker tbody tr > td span.year.focused,
    .datepicker tbody tr > td span.year.focused:hover {
      background: #15C042;
      color: #fff; }
  .datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
    font-weight: 500; }
    .datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
      background: #F4FAFF; }
  .datepicker.datepicker-inline {
    border: 1px solid #eaeaea; }

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px; }
  .bootstrap-timepicker-widget .bootstrap-timepicker-hour,
  .bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
  .bootstrap-timepicker-widget .bootstrap-timepicker-minute,
  .bootstrap-timepicker-widget .bootstrap-timepicker-second {
    border: none;
    box-shadow: none; }
  .bootstrap-timepicker-widget table td > a {
    border: 0; }
    .bootstrap-timepicker-widget table td > a i {
      font-size: 1.1rem; }

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: #111; }

.bootstrap-timepicker-widget table td > a:hover {
  background: #F4FAFF; }

.tagify__tag {
  --tag-bg: #F4FAFF;
  --tag-hover: rgba(21, 192, 66, 0.3);
  --tag-remove-bg: rgba(244, 66, 54, 0.3);
  --tag-remove-btn-bg--hover: #f44236; }
  .tagify__tag.tagify__tag--primary {
    --tag-bg: rgba(21, 192, 66, 0.2); }
    .tagify__tag.tagify__tag--primary div .tagify__tag-text {
      color: #15C042; }
  .tagify__tag.tagify__tag--secondary {
    --tag-bg: rgba(116, 136, 146, 0.2); }
    .tagify__tag.tagify__tag--secondary div .tagify__tag-text {
      color: #748892; }
  .tagify__tag.tagify__tag--success {
    --tag-bg: rgba(21, 192, 66, 0.2); }
    .tagify__tag.tagify__tag--success div .tagify__tag-text {
      color: #15C042; }
  .tagify__tag.tagify__tag--info {
    --tag-bg: rgba(62, 191, 234, 0.2); }
    .tagify__tag.tagify__tag--info div .tagify__tag-text {
      color: #3ebfea; }
  .tagify__tag.tagify__tag--warning {
    --tag-bg: rgba(244, 194, 43, 0.2); }
    .tagify__tag.tagify__tag--warning div .tagify__tag-text {
      color: #f4c22b; }
  .tagify__tag.tagify__tag--danger {
    --tag-bg: rgba(244, 66, 54, 0.2); }
    .tagify__tag.tagify__tag--danger div .tagify__tag-text {
      color: #f44236; }
  .tagify__tag.tagify__tag--light {
    --tag-bg: rgba(242, 242, 242, 0.2); }
    .tagify__tag.tagify__tag--light div .tagify__tag-text {
      color: #f2f2f2; }
  .tagify__tag.tagify__tag--dark {
    --tag-bg: rgba(55, 71, 79, 0.2); }
    .tagify__tag.tagify__tag--dark div .tagify__tag-text {
      color: #37474f; }

.bootstrap-switch {
  border-color: #eaeaea; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
    background: #F4FAFF; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #15C042; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
    color: #fff;
    background: #748892; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #15C042; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #3ebfea; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #f4c22b; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #f44236; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
    color: #fff;
    background: #f2f2f2; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
    color: #fff;
    background: #37474f; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*="btn-"]:not(.btn-light), .bootstrap-select > .dropdown-toggle.bs-placeholder.introjs-button[class*="btn-"]:not(.btn-light) {
  color: #fff; }

.typeahead .twitter-typeahead {
  display: block !important; }

.scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto; }

.tt-menu {
  padding: 10px 0;
  font-size: 14px;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .tt-menu .tt-suggestion {
    padding: 4px 20px;
    border-radius: 2px; }
    .tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
      background: rgba(21, 192, 66, 0.03);
      color: #15C042; }

.sticky-action {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1; }

.headerpos-fixed ~ .pcoded-main-container .sticky-action {
  top: 100px; }

.dropzone {
  min-height: auto; }

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #eaeaea;
  border-radius: 2px; }
  .dropzone .dropzone-msg-title {
    color: #888;
    margin: 0 0 5px;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem; }
  .dropzone .dropzone-msg-desc {
    color: #888;
    font-weight: 400;
    font-size: 1rem; }
  .dropzone .dz-preview .dz-image {
    border-radius: 2px; }
  .dropzone.dropzone-primary {
    border-color: #15C042; }
  .dropzone.dropzone-secondary {
    border-color: #748892; }
  .dropzone.dropzone-success {
    border-color: #15C042; }
  .dropzone.dropzone-info {
    border-color: #3ebfea; }
  .dropzone.dropzone-warning {
    border-color: #f4c22b; }
  .dropzone.dropzone-danger {
    border-color: #f44236; }
  .dropzone.dropzone-light {
    border-color: #f2f2f2; }
  .dropzone.dropzone-dark {
    border-color: #37474f; }

.dz-started .dropzone-msg {
  display: none; }

.dropzone-multi {
  border: 0;
  padding: 0; }
  .dropzone-multi .dz-message {
    display: none; }
  .dropzone-multi .dropzone-panel .dropzone-remove-all,
  .dropzone-multi .dropzone-panel .dropzone-upload {
    display: none; }
  .dropzone-multi .dropzone-item {
    background: #F4FAFF;
    border-radius: 2px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px; }
    .dropzone-multi .dropzone-item .dropzone-progress {
      width: 20%; }
      .dropzone-multi .dropzone-item .dropzone-progress .progress {
        height: 0.5rem;
        transition: all 0.2s ease-in-out; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
      font-size: 0.9rem;
      font-weight: 500;
      color: #888;
      text-overflow: ellipsis;
      margin-right: 0.5rem; }
      .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
        font-size: 0.9rem;
        font-weight: 500;
        color: #888; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
      margin-top: 0.25rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: #f44236;
      text-overflow: ellipsis; }
    .dropzone-multi .dropzone-item .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
          font-size: 0.8rem;
          color: #111; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
          color: #15C042; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        transition: all 0.2s ease-in-out; }

.uppy-Dashboard--modal {
  z-index: 1030; }

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #F4FAFF; }

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
  width: 100px; }

.slider-selection {
  box-shadow: none; }

.pc-toggle-noUiSlider {
  height: 50px; }

.pc-toggle-noUiSlider.off .noUi-handle {
  border-color: #f44236;
  background: #f44236;
  box-shadow: none; }

.note-editor.card .card-header {
  padding: 0 5px 5px;
  border-bottom-color: #a9a9a9; }

#cke5-inline-demo .ck-content {
  margin-bottom: 1rem;
  padding: 2.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  #cke5-inline-demo .ck-content h3 {
    margin-top: 0; }

#cke5-inline-demo header.ck-content {
  text-align: center; }
  #cke5-inline-demo header.ck-content h2:first-of-type {
    margin-top: 0; }
  #cke5-inline-demo header.ck-content h2 + h3 {
    margin-top: 0;
    color: #888;
    font-weight: 600; }

#cke5-inline-demo .demo-row {
  width: 100%;
  display: flex; }
  #cke5-inline-demo .demo-row .demo-row__half {
    width: 50%; }
    #cke5-inline-demo .demo-row .demo-row__half:first-of-type {
      padding-right: 0.5rem; }
    #cke5-inline-demo .demo-row .demo-row__half:last-of-type {
      padding-left: 0.5rem; }
  #cke5-inline-demo .demo-row p {
    margin-bottom: 0; }
  #cke5-inline-demo .demo-row h3 {
    margin: 0;
    font-weight: 600; }

.switch-demo .custom-switch-v1 {
  margin-bottom: 4px; }

.custom-switch-v1.form-switch {
  padding-left: 2.9em; }
  .custom-switch-v1.form-switch .custom-control-input {
    height: 20px;
    width: 35px;
    margin-left: -2.9em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23F4FAFF'/%3e%3c/svg%3e");
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
    .custom-switch-v1.form-switch .custom-control-input[class*="input-light-"] {
      border: none; }
    .custom-switch-v1.form-switch .custom-control-input:focus {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.25); }
    .custom-switch-v1.form-switch .custom-control-input:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-primary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2315C042'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-secondary:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23748892'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-success:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2315C042'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-info:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%233ebfea'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-warning:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f4c22b'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-danger:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f44236'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-light:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23f2f2f2'/%3e%3c/svg%3e"); }
  .custom-switch-v1.form-switch .custom-control-input.input-light-dark:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2337474f'/%3e%3c/svg%3e"); }

.custom-switch-v1 .custom-control-label::before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 0.8rem;
  top: 0;
  left: -2.55rem; }

.custom-switch-v1 .custom-control-label::after {
  top: calc(0.15625rem - 2px);
  left: calc(-2.25rem - 4px);
  height: 19px;
  width: 19px;
  border-radius: 0.7rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1); }

.custom-switch-v1 .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.95rem); }

.row[data-multiselectsplitter-wrapper-selector] select {
  margin-bottom: 8px; }

.choices {
  position: relative;
  margin-bottom: 5px;
  font-size: 16px; }
  .choices:focus {
    outline: none; }
  .choices:last-child {
    margin-bottom: 0; }
  .choices.is-disabled .choices__inner,
  .choices.is-disabled .choices__input {
    background-color: #F4FAFF;
    cursor: not-allowed;
    user-select: none; }
  .choices.is-disabled .choices__item {
    cursor: not-allowed; }
  .choices [hidden] {
    display: none !important; }

.choices[data-type*="select-one"] {
  cursor: pointer; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px; }
  .choices[data-type*="select-one"] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ced4da;
    background-color: #fff;
    margin: 0; }
  .choices[data-type*="select-one"] .choices__button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5; }
    .choices[data-type*="select-one"] .choices__button:focus, .choices[data-type*="select-one"] .choices__button:hover {
      opacity: 1; }
    .choices[data-type*="select-one"] .choices__button:focus {
      box-shadow: 0 0 0 2px #15C042; }
  .choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
    display: none; }
  .choices[data-type*="select-one"]:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #495057 transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none; }
  .choices[data-type*="select-one"].is-open:after {
    border-color: transparent transparent #495057 transparent;
    margin-top: -7.5px; }
  .choices[data-type*="select-one"][dir="rtl"]:after {
    left: 11.5px;
    right: auto; }
  .choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0; }

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text; }

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0; }
  .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="select-multiple"] .choices__button:hover,
  .choices[data-type*="text"] .choices__button:focus,
  .choices[data-type*="text"] .choices__button:hover {
    opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  padding: 7.5px 7.5px 3.75px;
  border: 2px solid #ced4da;
  border-radius: 0.375rem;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }
  .is-focused .choices__inner,
  .is-open .choices__inner {
    border-color: #15C042; }
  .is-open .choices__inner {
    border-radius: 0.375rem 0.375rem 0 0; }
  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 0.375rem 0.375rem; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }
  [dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px; }
  .choices__list--single .choices__item {
    width: 100%; }

.choices__list--multiple {
  display: inline; }
  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #15C042;
    border: 1px solid #12a93a;
    color: #ffffff;
    word-break: break-all;
    box-sizing: border-box; }
    .choices__list--multiple .choices__item[data-deletable] {
      padding-right: 5px; }
    [dir="rtl"] .choices__list--multiple .choices__item {
      margin-right: 0;
      margin-left: 3.75px; }
    .choices__list--multiple .choices__item.is-highlighted {
      background-color: #12a93a;
      border: 1px solid #109232; }
    .is-disabled .choices__list--multiple .choices__item {
      background-color: #75c0ff;
      border: 1px solid #42a9ff; }

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4da;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility; }
  .choices__list--dropdown.is-active {
    visibility: visible; }
  .is-open .choices__list--dropdown {
    border-color: #a2aeb9; }
  .is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0; }
  .choices__list--dropdown .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
  .choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px; }
    [dir="rtl"] .choices__list--dropdown .choices__item {
      text-align: right; }
  @media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
      padding-right: 100px; }
      .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px; }
        [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
          right: auto;
          left: 10px; } }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: rgba(21, 192, 66, 0.1); }
    .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
      opacity: 0.5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #ebeef0;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .choices__button:focus {
    outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  border: 0;
  color: #495057;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }
  .choices__input:focus {
    outline: 0; }
  [dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0; }

.choices__placeholder {
  opacity: 0.5; }

.btn.bootstrap-touchspin-down > i, .bootstrap-touchspin-down.introjs-button > i,
.btn.bootstrap-touchspin-up > i,
.bootstrap-touchspin-up.introjs-button > i {
  margin-right: 0; }

.bt-wizard .nav {
  background: #F4FAFF;
  padding: 0; }
  .bt-wizard .nav .nav-link.active {
    box-shadow: 0 0 5px 2px rgba(21, 192, 66, 0.3); }

.pc-wizard .card-body .tab-content,
.pc-wizard-details .card-body .tab-content,
.pc-wizard-subtitle .card-body .tab-content,
.pc-wizard-subtitle-vertical .card-body .tab-content {
  box-shadow: none; }

.pc-wizard .nav.nav-tabs {
  border-bottom: 1px solid #eaeaea; }
  .pc-wizard .nav.nav-tabs .nav-item {
    margin-bottom: 0; }
  .pc-wizard .nav.nav-tabs .nav-link {
    border-radius: 0;
    border: none;
    font-size: 16px;
    color: #888;
    background: no-repeat center bottom, center 100%;
    background-size: 0 100%, 100% 100%;
    transition: background 0.3s ease-out;
    background-image: linear-gradient(to top, #15C042 2px, rgba(255, 255, 255, 0) 2px);
    opacity: 0.7; }
    .pc-wizard .nav.nav-tabs .nav-link i {
      margin-right: 10px; }
  .pc-wizard .nav.nav-tabs .nav-link.active,
  .pc-wizard .nav.nav-tabs .show > .nav-link {
    background-size: 100% 100%, 100% 100%;
    opacity: 1;
    color: #15C042;
    box-shadow: none; }

.pc-wizard-details .nav {
  background: #feffff; }
  .pc-wizard-details .nav.nav-tabs {
    border-bottom: 1px solid #eaeaea; }
    .pc-wizard-details .nav.nav-tabs .nav-item {
      margin-bottom: -1px; }
    .pc-wizard-details .nav.nav-tabs .nav-link {
      padding: 0.8rem 2rem;
      font-size: 16px;
      color: #888;
      background: transparent;
      opacity: 0.7; }
      .pc-wizard-details .nav.nav-tabs .nav-link i {
        margin-bottom: 5px;
        font-size: 20px;
        opacity: 0.3;
        display: block;
        text-align: center; }
    .pc-wizard-details .nav.nav-tabs .nav-link.active,
    .pc-wizard-details .nav.nav-tabs .show > .nav-link {
      background: #fff;
      opacity: 1;
      font-weight: 500;
      color: #15C042; }
      .pc-wizard-details .nav.nav-tabs .nav-link.active i,
      .pc-wizard-details .nav.nav-tabs .show > .nav-link i {
        opacity: 0.8; }

.pc-wizard-subtitle .nav.nav-tabs {
  border-bottom: 1px solid #eaeaea; }
  .pc-wizard-subtitle .nav.nav-tabs .nav-item {
    margin-bottom: -1px; }
  .pc-wizard-subtitle .nav.nav-tabs .nav-link {
    padding: 1rem 1.5rem;
    color: #888;
    border: none;
    opacity: 0.7;
    display: flex;
    align-items: center;
    background: no-repeat center bottom, center 100%;
    background-size: 0 100%, 100% 100%;
    transition: background 0.3s ease-out;
    background-image: linear-gradient(to top, #15C042 2.5px, rgba(255, 255, 255, 0) 2.5px); }
    .pc-wizard-subtitle .nav.nav-tabs .nav-link .num-icon {
      width: 30px;
      height: 30px;
      border-radius: 2px;
      margin-right: 10px;
      background: #eaf5ff;
      color: #888;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
    .pc-wizard-subtitle .nav.nav-tabs .nav-link h6 {
      margin-bottom: 0; }
    .pc-wizard-subtitle .nav.nav-tabs .nav-link i {
      margin-right: 14px;
      font-size: 30px;
      opacity: 0.3;
      display: block;
      text-align: center; }
  .pc-wizard-subtitle .nav.nav-tabs .nav-link.active,
  .pc-wizard-subtitle .nav.nav-tabs .show > .nav-link {
    background-size: 100% 100%, 100% 100%;
    opacity: 1;
    box-shadow: none; }
    .pc-wizard-subtitle .nav.nav-tabs .nav-link.active i,
    .pc-wizard-subtitle .nav.nav-tabs .show > .nav-link i {
      opacity: 1;
      color: #15C042; }
    .pc-wizard-subtitle .nav.nav-tabs .nav-link.active .num-icon,
    .pc-wizard-subtitle .nav.nav-tabs .show > .nav-link .num-icon {
      background: #15C042;
      color: #fff; }

.nav.tab-wizard.card-header {
  background: transparent; }

.pc-wizard-subtitle-vertical .nav {
  background: #f9fcff; }
  .pc-wizard-subtitle-vertical .nav.nav-tabs {
    border-bottom: 1px solid #eaeaea; }
    .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-item {
      margin-bottom: -1px; }
    .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link {
      padding: 1rem 1.5rem;
      border-radius: 0;
      color: #888;
      border: none;
      opacity: 0.7;
      display: flex;
      align-items: center;
      background: no-repeat center left, center 100%;
      background-size: 100% 0, 100% 100%;
      transition: background 0.3s ease-out;
      background-image: linear-gradient(to left, #15C042 2.5px, rgba(255, 255, 255, 0) 2.5px); }
      .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link .num-icon {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        margin-right: 10px;
        background: #eaf5ff;
        color: #888;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
      .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link h6 {
        margin-bottom: 0; }
      .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link i {
        margin-right: 14px;
        font-size: 30px;
        opacity: 0.3;
        display: block;
        text-align: center; }
    .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link.active,
    .pc-wizard-subtitle-vertical .nav.nav-tabs .show > .nav-link {
      background-size: 100% 100%, 100% 100%;
      opacity: 1;
      box-shadow: none; }
      .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link.active i,
      .pc-wizard-subtitle-vertical .nav.nav-tabs .show > .nav-link i {
        opacity: 1;
        color: #15C042; }
      .pc-wizard-subtitle-vertical .nav.nav-tabs .nav-link.active .num-icon,
      .pc-wizard-subtitle-vertical .nav.nav-tabs .show > .nav-link .num-icon {
        background: #15C042;
        color: #fff; }

.tab-wizard.nav .nav-item .nav-link {
  background: rgba(255, 255, 255, 0.4); }

.tab-wizard.nav .nav-item + .nav-item .nav-link {
  margin-left: 10px; }

.tab-wizard.nav .nav-link.active,
.tab-wizard.nav .show > .nav-link {
  background: #fff; }

.sw-theme-default {
  box-shadow: none; }
  .sw-theme-default .sw-container {
    min-height: auto; }
  .sw-theme-default .step-content {
    margin-top: 30px;
    padding: 10px;
    border: 0 solid #eaeaea;
    background-color: #fff;
    text-align: left; }
  .sw-theme-default .sw-toolbar {
    background: #f9f9f9;
    border-radius: 0 !important;
    padding: 10px;
    margin-bottom: 0 !important; }
  .sw-theme-default .sw-toolbar-top {
    border-bottom-color: #eaeaea !important; }
  .sw-theme-default .sw-toolbar-bottom {
    background: transparent;
    border-top-color: #eaeaea !important; }
  .sw-theme-default > ul.step-anchor > li {
    position: relative;
    margin-right: 2px; }
    .sw-theme-default > ul.step-anchor > li > a {
      color: #888; }
      .sw-theme-default > ul.step-anchor > li > a:hover {
        color: #888; }
    .sw-theme-default > ul.step-anchor > li.clickable > a:hover,
    .sw-theme-default > ul.step-anchor > li.clickable > a:hover h6 {
      color: #15C042 !important; }
    .sw-theme-default > ul.step-anchor > li > a::after {
      background: #15C042;
      height: 2px; }
    .sw-theme-default > ul.step-anchor > li.active > a,
    .sw-theme-default > ul.step-anchor > li.active > a h6 {
      color: #15C042 !important; }
    .sw-theme-default > ul.step-anchor > li.done > a {
      color: #111 !important; }
      .sw-theme-default > ul.step-anchor > li.done > a::after {
        background: #15C042; }
    .sw-theme-default > ul.step-anchor > li.danger > a,
    .sw-theme-default > ul.step-anchor > li.danger > a h6 {
      color: #f44236 !important; }
      .sw-theme-default > ul.step-anchor > li.danger > a::after,
      .sw-theme-default > ul.step-anchor > li.danger > a h6::after {
        background: #f44236;
        border-left-color: color-level(#f44236, -9); }
    .sw-theme-default > ul.step-anchor > li.disabled > a {
      opacity: 0.5; }

.sw-theme-arrows {
  border-radius: 2px;
  border: 1px solid #eaeaea; }
  .sw-theme-arrows > .sw-container {
    min-height: auto; }
  .sw-theme-arrows .step-content {
    margin-top: 30px;
    border: 0 solid #eaeaea; }
  .sw-theme-arrows > ul.step-anchor {
    border-bottom: 1px solid #eaeaea;
    background: #F4FAFF;
    border-top-right-radius: 2px; }
    .sw-theme-arrows > ul.step-anchor > li > a {
      color: #888;
      background: #F4FAFF; }
      .sw-theme-arrows > ul.step-anchor > li > a:hover {
        color: #888;
        background: #F4FAFF; }
      .sw-theme-arrows > ul.step-anchor > li > a:after {
        border-left: 30px solid #F4FAFF; }
      .sw-theme-arrows > ul.step-anchor > li > a:before {
        border-left: 30px solid #F4FAFF; }
    .sw-theme-arrows > ul.step-anchor > li:first-child > a {
      padding-left: 15px; }
    .sw-theme-arrows > ul.step-anchor > li > a:hover {
      color: #888;
      background: #eaeaea;
      border-color: #eaeaea; }
      .sw-theme-arrows > ul.step-anchor > li > a:hover:after {
        border-left-color: #eaeaea; }
    .sw-theme-arrows > ul.step-anchor > li.clickable > a:hover {
      color: #15C042 !important;
      background: #3ebfea !important; }
    .sw-theme-arrows > ul.step-anchor > li.active > a {
      border-color: #15C042 !important;
      color: #fff !important;
      background: #15C042 !important; }
      .sw-theme-arrows > ul.step-anchor > li.active > a h6 {
        color: #fff !important; }
      .sw-theme-arrows > ul.step-anchor > li.active > a:after {
        border-left: 30px solid #15C042 !important; }
    .sw-theme-arrows > ul.step-anchor > li.done > a {
      border-color: color-level(#15C042 -9) !important;
      color: #15C042 !important;
      background: color-level(#15C042 -9) !important; }
      .sw-theme-arrows > ul.step-anchor > li.done > a h6 {
        color: #15C042 !important; }
      .sw-theme-arrows > ul.step-anchor > li.done > a:after {
        border-left: 30px solid color-level(#15C042 -9); }
    .sw-theme-arrows > ul.step-anchor > li.danger > a {
      border-color: #f44236 !important;
      color: #fff !important;
      background: #f44236 !important; }
      .sw-theme-arrows > ul.step-anchor > li.danger > a:after {
        border-left: 30px solid #f44236 !important; }
    .sw-theme-arrows > ul.step-anchor > li.disabled > a {
      opacity: 0.5; }
  .sw-theme-arrows::before {
    border: 10px solid #eaeaea;
    border-top: 10px solid #15C042; }

.sw-theme-circles .step-content {
  margin-top: 30px; }

.sw-theme-circles .sw-toolbar-bottom {
  border-top-color: #eaeaea !important;
  border-bottom-color: #eaeaea !important; }

.sw-theme-circles > ul.step-anchor {
  background: #fff; }
  .sw-theme-circles > ul.step-anchor:before {
    background-color: #F4FAFF;
    border-radius: 2px; }
  .sw-theme-circles > ul.step-anchor > li {
    margin-left: 40px; }
    .sw-theme-circles > ul.step-anchor > li > a {
      border: 2px solid #F4FAFF;
      background: #F4FAFF;
      color: #888;
      background: #F4FAFF; }
      .sw-theme-circles > ul.step-anchor > li > a:hover {
        color: #888;
        background: #F4FAFF; }
      .sw-theme-circles > ul.step-anchor > li > a > p {
        position: relative;
        font-size: 80%;
        bottom: -30px;
        color: #888; }
    .sw-theme-circles > ul.step-anchor > li.clickable > a:hover {
      color: #15C042 !important; }
    .sw-theme-circles > ul.step-anchor > li.active > a {
      border-color: #15C042;
      background: #15C042;
      color: #fff; }
      .sw-theme-circles > ul.step-anchor > li.active > a h6 {
        color: #fff; }
      .sw-theme-circles > ul.step-anchor > li.active > a > p {
        color: #15C042; }
    .sw-theme-circles > ul.step-anchor > li.done > a {
      border-color: #15C042;
      background: #15C042;
      color: #fff; }
      .sw-theme-circles > ul.step-anchor > li.done > a h6 {
        color: #fff; }
      .sw-theme-circles > ul.step-anchor > li.done > a > p {
        color: #15C042; }
    .sw-theme-circles > ul.step-anchor > li.danger > a {
      border-color: #f44236;
      background: #fff;
      color: #f44236; }
      .sw-theme-circles > ul.step-anchor > li.danger > a h6 {
        color: #f44236; }
      .sw-theme-circles > ul.step-anchor > li.danger > a > small {
        color: #f44236; }
    .sw-theme-circles > ul.step-anchor > li.disabled > a {
      opacity: 0.5; }

.sw-theme-dots .step-content {
  margin-top: 30px; }

.sw-theme-dots .sw-toolbar-top {
  border-bottom-color: #eaeaea !important; }

.sw-theme-dots .sw-toolbar-bottom {
  border-top-color: #eaeaea !important;
  border-bottom-color: #eaeaea !important; }

.sw-theme-dots > ul.step-anchor {
  border: 0 solid #eaeaea !important; }
  .sw-theme-dots > ul.step-anchor:before {
    background-color: #F4FAFF; }
  .sw-theme-dots > ul.step-anchor > li > a {
    color: #888; }
    .sw-theme-dots > ul.step-anchor > li > a:before {
      color: #15C042;
      background: #F4FAFF; }
    .sw-theme-dots > ul.step-anchor > li > a:after {
      background: #F4FAFF; }
    .sw-theme-dots > ul.step-anchor > li > a:hover {
      color: #888; }
    .sw-theme-dots > ul.step-anchor > li > a:focus {
      color: #888; }
  .sw-theme-dots > ul.step-anchor > li.clickable > a:hover {
    color: #888; }
  .sw-theme-dots > ul.step-anchor > li.active > a,
  .sw-theme-dots > ul.step-anchor > li.active > a h6 {
    color: #15C042; }
    .sw-theme-dots > ul.step-anchor > li.active > a:after,
    .sw-theme-dots > ul.step-anchor > li.active > a h6:after {
      background: #15C042; }
  .sw-theme-dots > ul.step-anchor > li.done > a,
  .sw-theme-dots > ul.step-anchor > li.done > a h6 {
    color: #15C042; }
    .sw-theme-dots > ul.step-anchor > li.done > a:after,
    .sw-theme-dots > ul.step-anchor > li.done > a h6:after {
      background: #15C042; }
  .sw-theme-dots > ul.step-anchor > li.danger > a,
  .sw-theme-dots > ul.step-anchor > li.danger > a h6 {
    color: #f44236; }
    .sw-theme-dots > ul.step-anchor > li.danger > a:after,
    .sw-theme-dots > ul.step-anchor > li.danger > a h6:after {
      background: #f44236; }
  .sw-theme-dots > ul.step-anchor > li.disabled > a {
    opacity: 0.5; }

/* Responsive CSS */
@media screen and (max-width: 768px) {
  .sw-theme-dots > ul.step-anchor:before {
    background-color: #F4FAFF; } }

.smartwizard-example .nav-link {
  padding-left: calc(2.5rem + 15px); }

.smartwizard-example.sw-main,
.smartwizard-example .sw-container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.smartwizard-example .sw-container {
  flex-shrink: 1; }

.smartwizard-example .step-anchor {
  flex-direction: row; }
  .smartwizard-example .step-anchor:not(.card) {
    border: 0; }

.smartwizard-example .step-anchor li {
  display: flex;
  flex-grow: 1; }

.smartwizard-example .step-anchor li > a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem 0 3.75rem;
  min-height: 2.5rem;
  outline: 0 !important;
  border: 0 !important;
  background: transparent !important;
  text-decoration: none;
  font-weight: 500;
  opacity: 0.4;
  cursor: default; }
  .smartwizard-example .step-anchor li > a:after {
    display: none; }
  .smartwizard-example .step-anchor li > a .small,
  .smartwizard-example .step-anchor li > a small {
    font-weight: normal; }

.smartwizard-example .step-anchor li.active > a,
.smartwizard-example .step-anchor li.clickable > a,
.smartwizard-example .step-anchor li.done > a {
  opacity: 1; }

.smartwizard-example .step-anchor li.active > a,
.smartwizard-example .step-anchor li.clickable > a {
  cursor: pointer; }

.smartwizard-example .step-anchor li.done > a {
  cursor: pointer; }

.smartwizard-example .step-anchor li.disabled > a,
.smartwizard-example .step-anchor li.disabled > a:hover {
  cursor: not-allowed !important; }

.smartwizard-example .sw-done-icon,
.smartwizard-example .sw-icon,
.smartwizard-example .sw-number {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.25rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid;
  border-radius: 50%;
  text-align: center;
  line-height: calc(2.5rem - 4px); }

.smartwizard-example .sw-done-icon,
.smartwizard-example .sw-icon {
  font-size: 1rem; }

.smartwizard-example .sw-done-icon {
  display: none; }

.smartwizard-example .step-anchor li.done .sw-done-icon {
  display: block; }
  .smartwizard-example .step-anchor li.done .sw-done-icon ~ .sw-icon,
  .smartwizard-example .step-anchor li.done .sw-done-icon ~ .sw-number {
    display: none; }

.smartwizard-example .wizard-number {
  font-weight: 500; }

.smartwizard-example .step-anchor li.done .sw-done-icon,
.smartwizard-example .step-anchor li.done .sw-icon,
.smartwizard-example .step-anchor li.done .sw-number {
  opacity: 0.4; }

.smartwizard-example .step-anchor > li.active > a .sw-done-icon,
.smartwizard-example .step-anchor > li.active > a .sw-icon,
.smartwizard-example .step-anchor > li.active > a .sw-number {
  border-color: #15C042; }

.smartwizard-example .step-content {
  position: relative;
  display: none;
  margin: 0;
  width: 100%; }

.smartwizard-example .sw-toolbar {
  justify-content: flex-end;
  padding: 0; }

.sw-theme-default .step-anchor li > a {
  color: #888; }

.sw-theme-default .step-anchor li.danger > a {
  color: #f44236 !important; }

.sw-theme-default .sw-done-icon,
.sw-theme-default .sw-icon,
.sw-theme-default .sw-number {
  border-color: #eaeaea; }

.sw-theme-default .step-anchor li.danger .sw-done-icon,
.sw-theme-default .step-anchor li.danger .sw-icon,
.sw-theme-default .step-anchor li.danger .sw-number {
  border-color: #f44236 !important;
  color: #f44236 !important; }

@media (min-width: 992px) {
  .smartwizard-vertical-left.sw-main,
  .smartwizard-vertical-right.sw-main {
    flex-direction: row; }
  .smartwizard-vertical-left .step-anchor,
  .smartwizard-vertical-right .step-anchor {
    align-self: flex-start;
    flex-direction: column; }
    .smartwizard-vertical-left .step-anchor li,
    .smartwizard-vertical-right .step-anchor li {
      flex-grow: 0; }
  .sw-theme-default.smartwizard-vertical-right {
    flex-direction: row-reverse; }
  .sw-theme-default.smartwizard-vertical-right .step-anchor li > a {
    padding: 0 0 0 5.75rem; }
  .sw-theme-default.smartwizard-vertical-right .sw-done-icon,
  .sw-theme-default.smartwizard-vertical-right .sw-icon,
  .sw-theme-default.smartwizard-vertical-right .sw-number {
    left: 2rem; } }

.tabcontrol,
.wizard {
  display: block;
  width: 100%;
  overflow: hidden; }

.tabcontrol a,
.wizard a {
  outline: 0; }

.tabcontrol ul,
.wizard ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.tabcontrol ul > li,
.wizard ul > li {
  display: block;
  padding: 0; }

/* Accessibility */
.tabcontrol > .content > .title,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.wizard > .steps .current-info {
  position: absolute;
  left: -999em; }

/*
    Wizard
*/
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%; }

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%; }

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em;
  width: 65%; }

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%; }

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em; }

.wizard > .steps .number {
  font-size: 1.429em; }

.wizard > .steps > ul > li {
  width: 25%;
  float: left; }

.wizard > .actions > ul > li {
  float: left; }

.wizard > .steps a {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .steps a:active, .wizard > .steps a:hover {
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }

.wizard > .steps .disabled a {
  background: #F4FAFF;
  color: #888;
  cursor: default;
  opacity: 0.6; }
  .wizard > .steps .disabled a:active, .wizard > .steps .disabled a:hover {
    background: #F4FAFF;
    color: #888;
    cursor: default; }

.wizard > .steps .current a {
  background: #15C042;
  color: #fff;
  cursor: default; }
  .wizard > .steps .current a:active, .wizard > .steps .current a:hover {
    background: #15C042;
    color: #fff;
    cursor: default; }

.wizard > .steps .done a {
  background: rgba(21, 192, 66, 0.2);
  color: #15C042; }
  .wizard > .steps .done a:active, .wizard > .steps .done a:hover {
    background: rgba(21, 192, 66, 0.2);
    color: #15C042; }

.wizard > .steps .error a {
  background: #f44236;
  color: #fff; }
  .wizard > .steps .error a:active, .wizard > .steps .error a:hover {
    background: #f44236;
    color: #fff; }

.wizard > .content {
  background: #feffff;
  display: block;
  margin: 0.5em;
  min-height: 8em;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: 5px; }

.wizard > .content > .body {
  padding: 25px; }
  .wizard > .content > .body ul {
    list-style: disc !important; }
    .wizard > .content > .body ul > li {
      display: list-item; }
  .wizard > .content > .body > iframe {
    border: 0 none;
    width: 100%;
    height: 100%; }
  .wizard > .content > .body input.error {
    background: #fbe3e4;
    border-color: #f44236;
    color: #f44236; }
  .wizard > .content > .body label {
    display: inline-block;
    margin-bottom: 0.5em; }
    .wizard > .content > .body label.error {
      color: #f44236;
      display: inline-block;
      margin-left: 1.5em; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }

.wizard > .actions > ul {
  display: inline-block;
  text-align: right; }
  .wizard > .actions > ul > li {
    margin: 0 0.5em; }

.wizard > .actions a {
  background: #15C042;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .actions a:active, .wizard > .actions a:hover {
    background: #15C042;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    border-radius: 5px; }

.wizard > .actions .disabled a {
  background: #F4FAFF;
  color: #aaa; }
  .wizard > .actions .disabled a:active, .wizard > .actions .disabled a:hover {
    background: #F4FAFF;
    color: #aaa; }

@media (max-width: 768px) {
  .wizard .steps > ul > li {
    width: 100%; }
  .wizard.vertical > .steps,
  .wizard.vertical > .content {
    width: 100%; } }

.pc-wizard.wizard .number {
  display: none; }

.pc-wizard.wizard > .steps a {
  display: flex;
  align-items: center;
  margin: 0.5em;
  position: relative; }
  .pc-wizard.wizard > .steps a:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 20px;
    opacity: 0.5; }
  .pc-wizard.wizard > .steps a span b {
    display: block; }
  .pc-wizard.wizard > .steps a .num-icon {
    width: 35px;
    height: 35px;
    border-radius: 2px;
    margin-right: 10px;
    background: #F4FAFF;
    color: #888;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .pc-wizard.wizard > .steps a.disabled:hover {
    background: transparent; }
  .pc-wizard.wizard > .steps a:hover {
    display: flex; }

.pc-wizard.wizard > .steps > ul > li:last-child a:after {
  visibility: hidden; }

.pc-wizard.wizard > .steps .disabled a {
  background: transparent; }

.pc-wizard.wizard > .steps .current a {
  background: transparent;
  color: inherit; }
  .pc-wizard.wizard > .steps .current a .num-icon {
    background: #15C042;
    color: #fff; }

.pc-wizard.wizard > .steps .done a {
  background: transparent; }
  .pc-wizard.wizard > .steps .done a .num-icon {
    background: rgba(21, 192, 66, 0.2);
    color: #15C042; }
  .pc-wizard.wizard > .steps .done a span {
    color: #888; }

.pc-wizard.wizard > .content {
  margin: 0;
  border-radius: 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea; }

.pc-wizard.wizard > .actions {
  padding: 15px 25px 10px; }

.pc-wizard.wizard.vertical > .actions,
.pc-wizard.wizard.vertical > .content {
  margin: 0;
  background: #feffff;
  width: 70%;
  border-left: 1px solid #eaeaea; }

.pc-wizard.wizard.vertical > .content {
  padding: 25px 25px 0;
  border-radius: 0 2px 0 0; }

.pc-wizard.wizard.vertical .actions {
  padding: 25px;
  border-radius: 0 0 2px 0; }

.pc-wizard.wizard.vertical > .steps {
  padding: 35px 10px; }
  .pc-wizard.wizard.vertical > .steps a:after {
    content: "";
    right: 10px; }
  .pc-wizard.wizard.vertical > .steps > ul > li:last-child a:after {
    visibility: visible; }

@media (max-width: 768px) {
  .pc-wizard.wizard > .steps a:after {
    right: 15px; }
  .pc-wizard.wizard > .steps > ul > li:last-child a:after {
    visibility: visible; }
  .pc-wizard.wizard.vertical > .steps {
    padding: 0; }
  .pc-wizard.wizard.vertical > .content {
    padding: 0; }
  .pc-wizard.wizard.vertical > .actions,
  .pc-wizard.wizard.vertical > .content {
    width: 100%; } }

.pc-tab-control.wizard .number {
  display: none; }

.pc-tab-control.wizard > .steps a {
  display: flex;
  align-items: center;
  margin: 0.5em 0.5em 0;
  padding: 1em 1.6em;
  border-radius: 2px 2px 0 0;
  position: relative;
  background: rgba(255, 255, 255, 0.4); }
  .pc-tab-control.wizard > .steps a span {
    color: #888; }
    .pc-tab-control.wizard > .steps a span b {
      display: block; }
  .pc-tab-control.wizard > .steps a .num-icon {
    width: 35px;
    height: 35px;
    border-radius: 2px;
    margin-right: 10px;
    background: #F4FAFF;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .pc-tab-control.wizard > .steps a .num-icon .material-icons-two-tone {
      background-color: #888; }
  .pc-tab-control.wizard > .steps a.disabled:hover {
    background: transparent; }
  .pc-tab-control.wizard > .steps a:hover {
    display: flex; }

.pc-tab-control.wizard > .steps .done a:active,
.pc-tab-control.wizard > .steps .done a:hover {
  background: rgba(255, 255, 255, 0.4); }

.pc-tab-control.wizard > .steps > ul > li:last-child a {
  margin-right: 0; }

.pc-tab-control.wizard > .steps > ul > li:first-child a {
  margin-left: 0; }

.pc-tab-control.wizard > .steps > ul > li:last-child a:after {
  visibility: hidden; }

.pc-tab-control.wizard > .steps .disabled a {
  background: transparent; }

.pc-tab-control.wizard > .steps .current a {
  background: #fff;
  color: inherit; }
  .pc-tab-control.wizard > .steps .current a .num-icon {
    background: #15C042;
    color: #fff; }
    .pc-tab-control.wizard > .steps .current a .num-icon .material-icons-two-tone {
      background-color: #fff; }

.pc-tab-control.wizard > .content {
  margin: 0;
  border-radius: 0 0 2px 2px;
  background: #fff;
  box-shadow: 0 0 0 1px #e2e5e8;
  margin-bottom: 30px; }

.pc-tab-control.wizard > .actions {
  padding: 15px 25px 10px; }

@media (max-width: 768px) {
  .pc-tab-control.wizard > .steps a {
    margin: 0;
    border-radius: 2px; } }

.card .card-header h5 + span {
  display: block; }

.btn[data-handler="cmdSave"] i, .introjs-button[data-handler="cmdSave"] i {
  margin-right: 0; }

.bootstrap-select > .dropdown-toggle.btn-light, .bootstrap-select > .dropdown-toggle.btn-secondary, .bootstrap-select > .dropdown-toggle.introjs-button.introjs-prevbutton {
  border-width: 2px;
  border-color: #ced4da !important;
  box-shadow: 0 1px 2px 0 rgba(57, 70, 92, 0.05);
  background: #fff !important;
  color: #495057;
  border-radius: 0.375rem; }

.bootstrap-select > .dropdown-toggle.btn-light:focus, .bootstrap-select > .dropdown-toggle.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.introjs-button.introjs-prevbutton:focus {
  outline: none !important; }

.daterangepicker {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 25px rgba(21, 192, 66, 0.15);
  background-color: #fff; }
  .daterangepicker .calendar-table {
    border: 1px solid #fff;
    background-color: #fff; }
  .daterangepicker td.in-range {
    background-color: #66ee8a; }
  .daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #fff;
    color: #888;
    opacity: 0.5; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #15C042;
    color: #fff; }
  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: #15C042;
    color: #fff; }
    .daterangepicker td.available:hover > span,
    .daterangepicker th.available:hover > span {
      border-color: #fff; }
  .daterangepicker:after {
    border-bottom: 6px solid #fff; }
  .daterangepicker:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker .ranges ul {
    padding-top: 15px;
    padding-bottom: 15px; }
    @media (min-width: 564px) {
      .daterangepicker .ranges ul {
        width: 160px; } }
    .daterangepicker .ranges ul li {
      margin: 5px 10px;
      transition: all 0.2s ease-in-out; }
      .daterangepicker .ranges ul li.active {
        background-color: #15C042; }
      .daterangepicker .ranges ul li:hover {
        box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
        background-color: #15C042;
        color: #fff; }

.select2-container--default .select2-selection,
.select2-container--default .select2-selection--single {
  border: 2px solid #ced4da; }

.select2-container .select2-selection--single {
  height: 48px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 46px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 46px; }

.select2-container .select2-selection--multiple {
  min-height: 46px; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 9px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-right: 7px;
  margin-top: 9px;
  padding: 1px 7px; }

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 9px; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #e2e5e8 2px; }

.select2-container--default .select2-selection--multiple {
  background-color: #fff;
  border: 2px solid #eaeaea;
  border-radius: 3px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #15C042;
    border: 1px solid #15C042;
    color: #fff;
    border-radius: 3px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    margin-right: 8px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #fff; }

.custom-control-inline {
  margin-right: 0; }

/* ==============================================================
                     image-cropper  Start
====================================================== */
.docs-buttons .btn, .docs-buttons .introjs-button {
  margin-bottom: 10px !important; }

.docs-data .input-group {
  background: transparent;
  margin-bottom: 10px; }

.docs-data > .input-group > label {
  min-width: 80px; }

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px; }
  .img-container > img {
    max-width: 100%; }
  @media (min-width: 768px) {
    .img-container {
      min-height: 516px; } }

.docs-preview {
  margin-right: -15px; }
  .docs-preview .img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden; }
    .docs-preview .img-preview > img {
      max-width: 100%; }
  .docs-preview .preview-lg {
    width: 100% !important; }
  .docs-preview .preview-md {
    width: 139px;
    height: 78px; }
  .docs-preview .preview-sm {
    width: 69px;
    height: 39px; }
  .docs-preview .preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0; }

.docs-buttons > .btn, .docs-buttons > .introjs-button,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
  margin-right: 5px; }

.docs-toggles > .btn, .docs-toggles > .introjs-button,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
  margin-bottom: 10px; }

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px; }
  .docs-tooltip > .icon {
    margin: 0 -3px;
    vertical-align: top; }

.tooltip-inner {
  white-space: normal; }

.btn-upload .tooltip-inner {
  white-space: nowrap; }

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important; }
    .btn-group-crop > .btn, .btn-group-crop > .introjs-button {
      padding-left: 5px;
      padding-right: 5px; }
    .btn-group-crop .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px; } }

.docs-options .dropdown-menu {
  width: 100%; }
  .docs-options .dropdown-menu > li {
    padding: 3px 20px;
    font-size: 14px; }
    .docs-options .dropdown-menu > li:hover {
      background-color: #f7f7f7; }
    .docs-options .dropdown-menu > li > label {
      display: block; }

.docs-cropped .modal-body {
  text-align: center; }
  .docs-cropped .modal-body > canvas,
  .docs-cropped .modal-body > img {
    max-width: 100%; }

.card-block .docs-options .dropdown-menu {
  top: inherit; }

/* ==================  image-cropper end  ======================================= */
.form-group label.invalid-feedback {
  color: #f44236; }

.bootstrap-select.form-control {
  box-shadow: none; }

.dropdown-menu > li.active a,
.dropdown-menu > li:active a,
.dropdown-menu > li:focus a,
.dropdown-menu > li:hover a {
  color: #15C042; }

.arrow-none.dropdown-toggle:after {
  display: none; }

.form-check .form-check-input.input-primary:checked {
  border-color: #15C042;
  background-color: #15C042; }

.form-check .form-check-input.input-light-primary:checked {
  border-color: #d0f2d9;
  background-color: #d0f2d9; }
  .form-check .form-check-input.input-light-primary:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2315C042' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-primary:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2315C042'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-primary:focus[type="checkbox"], .form-check .form-check-input.input-primary:focus[type="radio"], .form-check .form-check-input.input-light-primary:focus[type="checkbox"], .form-check .form-check-input.input-light-primary:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(21, 192, 66, 0.25); }

.form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2315C042'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-secondary:checked {
  border-color: #748892;
  background-color: #748892; }

.form-check .form-check-input.input-light-secondary:checked {
  border-color: #e3e7e9;
  background-color: #e3e7e9; }
  .form-check .form-check-input.input-light-secondary:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23748892' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-secondary:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23748892'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-secondary:focus[type="checkbox"], .form-check .form-check-input.input-secondary:focus[type="radio"], .form-check .form-check-input.input-light-secondary:focus[type="checkbox"], .form-check .form-check-input.input-light-secondary:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(116, 136, 146, 0.25); }

.form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23748892'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-success:checked {
  border-color: #15C042;
  background-color: #15C042; }

.form-check .form-check-input.input-light-success:checked {
  border-color: #d0f2d9;
  background-color: #d0f2d9; }
  .form-check .form-check-input.input-light-success:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2315C042' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-success:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2315C042'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-success:focus[type="checkbox"], .form-check .form-check-input.input-success:focus[type="radio"], .form-check .form-check-input.input-light-success:focus[type="checkbox"], .form-check .form-check-input.input-light-success:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(21, 192, 66, 0.25); }

.form-check.form-switch .form-check-input.input-light-success:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2315C042'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-info:checked {
  border-color: #3ebfea;
  background-color: #3ebfea; }

.form-check .form-check-input.input-light-info:checked {
  border-color: #d8f2fb;
  background-color: #d8f2fb; }
  .form-check .form-check-input.input-light-info:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ebfea' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-info:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ebfea'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-info:focus[type="checkbox"], .form-check .form-check-input.input-info:focus[type="radio"], .form-check .form-check-input.input-light-info:focus[type="checkbox"], .form-check .form-check-input.input-light-info:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(62, 191, 234, 0.25); }

.form-check.form-switch .form-check-input.input-light-info:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ebfea'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-warning:checked {
  border-color: #f4c22b;
  background-color: #f4c22b; }

.form-check .form-check-input.input-light-warning:checked {
  border-color: #fdf3d5;
  background-color: #fdf3d5; }
  .form-check .form-check-input.input-light-warning:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f4c22b' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-warning:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f4c22b'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-warning:focus[type="checkbox"], .form-check .form-check-input.input-warning:focus[type="radio"], .form-check .form-check-input.input-light-warning:focus[type="checkbox"], .form-check .form-check-input.input-light-warning:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(244, 194, 43, 0.25); }

.form-check.form-switch .form-check-input.input-light-warning:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f4c22b'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-danger:checked {
  border-color: #f44236;
  background-color: #f44236; }

.form-check .form-check-input.input-light-danger:checked {
  border-color: #fdd9d7;
  background-color: #fdd9d7; }
  .form-check .form-check-input.input-light-danger:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f44236' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-danger:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f44236'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-danger:focus[type="checkbox"], .form-check .form-check-input.input-danger:focus[type="radio"], .form-check .form-check-input.input-light-danger:focus[type="checkbox"], .form-check .form-check-input.input-light-danger:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25); }

.form-check.form-switch .form-check-input.input-light-danger:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f44236'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-light:checked {
  border-color: #f2f2f2;
  background-color: #f2f2f2; }

.form-check .form-check-input.input-light-light:checked {
  border-color: #fcfcfc;
  background-color: #fcfcfc; }
  .form-check .form-check-input.input-light-light:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f2f2f2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-light:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f2f2f2'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-light:focus[type="checkbox"], .form-check .form-check-input.input-light:focus[type="radio"], .form-check .form-check-input.input-light-light:focus[type="checkbox"], .form-check .form-check-input.input-light-light:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.25); }

.form-check.form-switch .form-check-input.input-light-light:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f2f2f2'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-dark:checked {
  border-color: #37474f;
  background-color: #37474f; }

.form-check .form-check-input.input-light-dark:checked {
  border-color: #d7dadc;
  background-color: #d7dadc; }
  .form-check .form-check-input.input-light-dark:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2337474f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .form-check .form-check-input.input-light-dark:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2337474f'/%3e%3c/svg%3e"); }

.form-check .form-check-input.input-dark:focus[type="checkbox"], .form-check .form-check-input.input-dark:focus[type="radio"], .form-check .form-check-input.input-light-dark:focus[type="checkbox"], .form-check .form-check-input.input-light-dark:focus[type="radio"] {
  box-shadow: 0 0 0 0.2rem rgba(55, 71, 79, 0.25); }

.form-check.form-switch .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2337474f'/%3e%3c/svg%3e"); }

/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px; }
  .checkbox input[type=checkbox] {
    margin: 0;
    display: none;
    width: 22px; }
    .checkbox input[type=checkbox] + .cr {
      padding-left: 0; }
      .checkbox input[type=checkbox] + .cr:before {
        content: "\e83f";
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 10px;
        border: 2px solid #e9eaec;
        border-radius: 3px;
        font-size: 15px;
        font-family: 'feather';
        font-weight: 400;
        line-height: 19px;
        vertical-align: bottom;
        text-align: center;
        background: #ffffff;
        color: transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out; }
    .checkbox input[type=checkbox]:checked + .cr:before {
      background: #1dd5d2;
      border-color: #1dd5d2;
      color: #ffffff; }
    .checkbox input[type=checkbox].disabled + .cr,
    .checkbox input[type=checkbox]:disabled + .cr {
      opacity: 0.5; }
    .checkbox input[type=checkbox].disabled + .cr:before,
    .checkbox input[type=checkbox]:disabled + .cr:before {
      cursor: not-allowed; }
  .checkbox.checkbox-fill input[type=checkbox] + .cr:after {
    content: "";
    width: 22.5px;
    height: 22.5px;
    display: inline-block;
    margin-right: 10px;
    border: 2px solid #e9eaec;
    border-radius: 2px;
    vertical-align: bottom;
    text-align: center;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 8.5px;
    left: 3px; }
  .checkbox.checkbox-fill input[type=checkbox] + .cr:before {
    opacity: 0;
    content: "\e840";
    font-size: 27px;
    background: transparent; }
  .checkbox.checkbox-fill input[type=checkbox]:checked + .cr:after {
    opacity: 0; }
  .checkbox.checkbox-fill input[type=checkbox]:checked + .cr:before {
    opacity: 1;
    background: transparent;
    color: #1dd5d2;
    border-color: transparent; }
  .checkbox.checkbox-primary input[type=checkbox]:checked + .cr:before {
    background: #15C042;
    border-color: #15C042;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-primary input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #15C042;
    border-color: transparent; }
  .checkbox.checkbox-danger input[type=checkbox]:checked + .cr:before {
    background: #f44236;
    border-color: #f44236;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-danger input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #f44236;
    border-color: transparent; }
  .checkbox.checkbox-success input[type=checkbox]:checked + .cr:before {
    background: #15C042;
    border-color: #15C042;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-success input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #15C042;
    border-color: transparent; }
  .checkbox.checkbox-warning input[type=checkbox]:checked + .cr:before {
    background: #f4c22b;
    border-color: #f4c22b;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-warning input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #f4c22b;
    border-color: transparent; }
  .checkbox.checkbox-info input[type=checkbox]:checked + .cr:before {
    background: #3ebfea;
    border-color: #3ebfea;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-info input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #3ebfea;
    border-color: transparent; }
  .checkbox.checkbox-dark input[type=checkbox]:checked + .cr:before {
    background: #37474f;
    border-color: #37474f;
    color: #ffffff; }
  .checkbox.checkbox-fill.checkbox-dark input[type=checkbox]:checked + .cr:before {
    background: transparent;
    color: #37474f;
    border-color: transparent; }
  .checkbox .cr {
    cursor: pointer; }

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px; }
  .radio input[type=radio] {
    margin: 0;
    display: none;
    width: 22px; }
    .radio input[type=radio] + .cr {
      padding-left: 0;
      position: relative; }
      .radio input[type=radio] + .cr:after, .radio input[type=radio] + .cr:before {
        content: "";
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: bottom;
        background: #fff;
        color: transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out; }
      .radio input[type=radio] + .cr:before {
        width: 22px;
        height: 22px;
        border: 2px solid #e9eaec; }
      .radio input[type=radio] + .cr:after {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 5px;
        left: 5px; }
    .radio input[type=radio]:checked + .cr:before {
      border-color: #1dd5d2; }
    .radio input[type=radio]:checked + .cr:after {
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .radio input[type=radio]:disabled + .cr {
      opacity: 0.5;
      cursor: not-allowed; }
      .radio input[type=radio]:disabled + .cr:after, .radio input[type=radio]:disabled + .cr:before {
        cursor: not-allowed; }
  .radio.radio-fill input[type=radio] + .cr:after {
    width: 18px;
    height: 18px;
    top: 2px;
    left: 2px; }
  .radio.radio-primary input[type=radio]:checked + .cr:before {
    border-color: #15C042; }
  .radio.radio-primary input[type=radio]:checked + .cr:after {
    background: #15C042; }
  .radio.radio-danger input[type=radio]:checked + .cr:before {
    border-color: #f44236; }
  .radio.radio-danger input[type=radio]:checked + .cr:after {
    background: #f44236; }
  .radio.radio-success input[type=radio]:checked + .cr:before {
    border-color: #15C042; }
  .radio.radio-success input[type=radio]:checked + .cr:after {
    background: #15C042; }
  .radio.radio-warning input[type=radio]:checked + .cr:before {
    border-color: #f4c22b; }
  .radio.radio-warning input[type=radio]:checked + .cr:after {
    background: #f4c22b; }
  .radio.radio-info input[type=radio]:checked + .cr:before {
    border-color: #3ebfea; }
  .radio.radio-info input[type=radio]:checked + .cr:after {
    background: #3ebfea; }
  .radio.radio-dark input[type=radio]:checked + .cr:before {
    border-color: #37474f; }
  .radio.radio-dark input[type=radio]:checked + .cr:after {
    background: #37474f; }
  .radio .cr {
    cursor: pointer; }

@-moz-document url-prefix() {
  .radio input[type="radio"] + .cr::after {
    top: 14px; } }

.custom-controls-stacked .radio input[type=radio] + .cr:after {
  top: 15px; }

/**====== Radio & Checked css end ======**/
/**  =====================
      Label & Badges css start
==========================  **/
.label {
  padding: 4px 10px;
  min-height: auto;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px; }
  .label.label-primary {
    background: #15C042;
    color: #ffffff; }
  .label.label-danger {
    background: #f44236;
    color: #ffffff; }
  .label.label-success {
    background: #15C042;
    color: #ffffff; }
  .label.label-warning {
    background: #f4c22b;
    color: #ffffff; }
  .label.label-info {
    background: #3ebfea;
    color: #ffffff; }
  .label.label-dark {
    background: #37474f;
    color: #ffffff; }

/**====== Label & Badges css end ======**/
/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem 0.75rem; }

.table thead th {
  border-bottom: 1px solid #eaeaea; }

.table tbody + tbody {
  border-top: 2px solid #eaeaea; }

.table > :not(:first-child) {
  border-top: none; }

/* Border versions */
.table-bordered td,
.table-bordered th {
  border: 1px solid #eaeaea; }

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(21, 192, 66, 0.05); }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent; }

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(21, 192, 66, 0.05); }
  .table-hover tbody tr:hover > * {
    background: transparent;
    --bs-table-accent-bg: transparent; }

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #37474f;
  border-color: #222c31; }

.table-dark {
  color: #fff;
  background-color: #37474f; }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #222c31; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: #334249; }
  .table-dark.table-hover tbody tr:hover {
    background-color: #2d3940; }

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important; }
  @media screen and (max-width: 992px) {
    table.dataTable.fixedHeader-floating {
      display: none !important; } }

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important; } }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_paginate,
  div.dataTables_wrapper div.dataTables_filter {
    margin-top: 10px;
    margin-bottom: 5px; } }

/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #15C042;
  border-color: #15C042; }
  .footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
  .footable .pagination > .active > span:focus,
  .footable .pagination > .active > span:hover {
    background-color: #15C042;
    border-color: #15C042; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222; }

.footable-details.table,
.footable.table {
  margin-bottom: 0; }

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0; }

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0; }

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #eaeaea; }

.table-columned > tbody > tr > th {
  border: 0; }

/**====== Foo-table css end ======**/
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: 18px; }

/**====== Data Tables css end ======**/
/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh; }
  .auth-wrapper a,
  .auth-wrapper p > a {
    color: #111;
    font-weight: 600; }
  .auth-wrapper .btn-auth-gen .btn-icon {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px; }
    .auth-wrapper .btn-auth-gen .btn-icon small {
      font-size: 15px; }
  .auth-wrapper .btn-check:checked + .btn-outline-secondary {
    background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
  .auth-wrapper .input-group {
    background: transparent; }
  .auth-wrapper .card {
    margin-bottom: 0; }
  .auth-wrapper > div {
    z-index: 5; }
  .auth-wrapper .auth-content {
    position: relative;
    width: 390px;
    padding: 15px;
    z-index: 5; }
    .auth-wrapper .auth-content.multyform, .auth-wrapper .auth-content.subscribe {
      width: 750px; }
  @media only screen and (max-width: 768px) {
    .auth-wrapper {
      max-width: 360px; } }
  @media only screen and (max-width: 575px) {
    .auth-wrapper .card .card-body {
      padding: 30px 15px; } }
  .auth-wrapper .auth-icon {
    font-size: 30px; }
    .auth-wrapper .auth-icon:before {
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .auth-wrapper.offline {
    background: radial-gradient(#94acbe, #253653); }
    .auth-wrapper.offline .offline-wrapper {
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center; }
      .auth-wrapper.offline .offline-wrapper > svg {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0; }
      .auth-wrapper.offline .offline-wrapper .boat-img {
        animation: boatanim 12s ease-in-out infinite; }
    .auth-wrapper.offline .s-img-1,
    .auth-wrapper.offline .s-img-2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .auth-wrapper.offline .s-img-1 {
      animation: sparcle 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .auth-wrapper.offline .s-img-2 {
      animation: sparcle 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.19s; }
    .auth-wrapper.offline .moon {
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      top: 12%;
      left: 40%;
      width: 150px;
      height: 150px;
      border-radius: 50%; }
      .auth-wrapper.offline .moon:after, .auth-wrapper.offline .moon:before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4); }
      .auth-wrapper.offline .moon:after {
        animation: sunwawe 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
      .auth-wrapper.offline .moon:before {
        animation: sunwawe 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s; }
    .auth-wrapper.offline .sark .img-sark {
      width: 300px;
      position: absolute;
      right: -200px;
      animation: sark 12s ease-in-out infinite; }
    .auth-wrapper.offline .sark .bubble {
      background: rgba(255, 255, 255, 0);
      position: absolute;
      top: 12%;
      left: 60%;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      animation: bubble 12s ease-in-out infinite; }
      .auth-wrapper.offline .sark .bubble:after, .auth-wrapper.offline .sark .bubble:before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4); }
      .auth-wrapper.offline .sark .bubble:after {
        animation: sunwawe 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
      .auth-wrapper.offline .sark .bubble:before {
        animation: sunwawe 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s; }
    .auth-wrapper.offline .off-main {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center; }
      .auth-wrapper.offline .off-main .off-content {
        position: absolute;
        width: 100%;
        bottom: 5%;
        left: 0;
        z-index: 5; }
    @media only screen and (max-width: 575px) {
      .auth-wrapper.offline .sark {
        display: none; }
      .auth-wrapper.offline .offline-wrapper > svg {
        height: 95%; }
      .auth-wrapper.offline .off-main .off-content {
        bottom: 0%; }
        .auth-wrapper.offline .off-main .off-content h1 {
          font-size: 45px; }
      .auth-wrapper.offline .moon {
        top: 12%;
        width: 50px;
        height: 50px; } }
  .auth-wrapper .auth-bg .r {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%; }
    .auth-wrapper .auth-bg .r:first-child {
      top: -100px;
      right: -100px;
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
    .auth-wrapper .auth-bg .r:last-child {
      left: -100px;
      bottom: -100px;
      background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }
    .auth-wrapper .auth-bg .r.s {
      width: 20px;
      height: 20px; }
      .auth-wrapper .auth-bg .r.s:nth-child(2) {
        top: 150px;
        right: -150px;
        background: #15C042; }
      .auth-wrapper .auth-bg .r.s:nth-child(3) {
        left: -150px;
        bottom: 150px;
        background: #15C042; }
    .auth-wrapper .auth-bg .r:nth-child(odd) {
      animation: floating 7s infinite; }
    .auth-wrapper .auth-bg .r:nth-child(even) {
      animation: floating 9s infinite; }

/* image varient start */
.aut-bg-img {
  background-image: url("../images/bg-images/bg4.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center; }

.aut-bg-img-side p {
  line-height: 2; }

.aut-bg-img .custom-select,
.aut-bg-img .form-control,
.aut-bg-img-side .custom-select,
.aut-bg-img-side .form-control {
  background: transparent; }

.auth-tabs .tab-content {
  overflow: hidden;
  position: relative; }
  .auth-tabs .tab-content .tab-pane > * {
    position: relative;
    z-index: 5; }
  .auth-tabs .tab-content .auth-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 180px;
    transform: rotate(-30deg);
    z-index: 1;
    opacity: 0.2; }

/* image varient start */
@keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg); }
  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg); } }

@keyframes bubble {
  0%, 30% {
    opacity: 0; }
  31% {
    opacity: 1; }
  59% {
    opacity: 1; }
  60%, 100% {
    opacity: 0; } }

@keyframes sark {
  0% {
    transform: rotate(-10deg) translate(245px, 46px); }
  30% {
    transform: rotate(-13deg) translate(-329px, -80px); }
  60% {
    transform: rotate(-13deg) translate(-329px, -80px); }
  100% {
    transform: rotate(-26deg) translate(-887px, -80px); } }

@keyframes boatanim {
  0% {
    transform: rotate(-10deg) translate(95px, -14px); }
  50% {
    transform: rotate(5deg) translate(-65px, -14px); }
  100% {
    transform: rotate(-10deg) translate(95px, -14px); } }

@keyframes sunwawe {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(4);
    opacity: 0; } }

@keyframes sparcle {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 0; } }

/**====== Authentication css end ======**/
/* Button variants
 Easily pump out default styles, as well as :hover, :focus, :active,
 and disabled options for all buttons */
/**  =====================
      Button css start
==========================  **/
.btn-theme,
a.btn-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-color: #1de1c2;
  border-radius: 0.25rem;
  padding: 11px 25px; }
  .btn-theme:active, .btn-theme:focus, .btn-theme:not(:disabled):not(.disabled):active,
  a.btn-theme:active,
  a.btn-theme:focus,
  a.btn-theme:not(:disabled):not(.disabled):active {
    background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    color: #fff;
    box-shadow: none; }
  .btn-theme.active,
  a.btn-theme.active {
    background-image: #fff !important;
    color: #d6d6d6;
    border: 1px solid #eaeaea;
    box-shadow: none; }

.btn-outline-theme {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none; }
  .btn-outline-theme:active, .btn-outline-theme:focus, .btn-outline-theme:not(:disabled):not(.disabled):active {
    background-image: #fff;
    color: #d6d6d6;
    border: 1px solid #eaeaea; }
  .btn-outline-theme.active {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%) !important;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid transparent; }

.btn-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 25px; }
  .btn-theme2:active, .btn-theme2:focus, .btn-theme2:not(:disabled):not(.disabled):active {
    background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
    color: #fff;
    box-shadow: none; }
  .btn-theme2.active {
    background-image: #fff !important;
    color: #d6d6d6;
    border: 1px solid #eaeaea;
    box-shadow: none; }

.btn-outline-theme2 {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  box-shadow: none; }
  .btn-outline-theme2:active, .btn-outline-theme2:focus, .btn-outline-theme2:not(:disabled):not(.disabled):active {
    background-image: #fff;
    color: #d6d6d6;
    border: 1px solid #eaeaea; }
  .btn-outline-theme2.active {
    background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%) !important;
    color: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid transparent; }

.btn, .introjs-button {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out; }
  .btn > i, .introjs-button > i {
    margin-right: 12px; }
  .btn.btn-icon, .btn-icon.introjs-button, .btn.drp-icon, .drp-icon.introjs-button {
    width: 45px;
    height: 45px;
    padding: 10px 12px; }
    .btn.btn-icon > i, .btn-icon.introjs-button > i, .btn.drp-icon > i, .drp-icon.introjs-button > i {
      margin-right: 0; }
  .btn.drp-icon.dropdown-toggle:after, .drp-icon.dropdown-toggle.introjs-button:after {
    display: none; }
  .btn.drp-icon + .dropdown-menu, .drp-icon.introjs-button + .dropdown-menu {
    margin-left: -10px; }
  .btn:active, .introjs-button:active, .btn:focus, .introjs-button:focus {
    box-shadow: none; }
  .btn-square {
    border-radius: 0; }
  .btn.disabled, .disabled.introjs-button {
    cursor: not-allowed;
    opacity: 0.55; }
  .btn-rounded {
    border-radius: 30px; }

.btn-group .btn, .btn-group .introjs-button {
  margin-bottom: 0;
  margin-right: 0; }

.btn-group-sm > .btn, .btn-group-sm > .introjs-button,
.btn-sm {
  padding: 6px 14px;
  font-size: 13px; }

.btn-group-lg > .btn, .btn-group-lg > .introjs-button,
.btn-lg {
  padding: 13px 23px;
  font-size: 16px; }

.shadow-1 {
  box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16); }

.shadow-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.shadow-3 {
  box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26); }

.shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3); }

.shadow-5 {
  box-shadow: 0 20px 24px 0 rgba(62, 57, 107, 0.36); }

/* Alternate buttons */
.btn-primary, .introjs-button {
  color: #fff;
  background-color: #15C042;
  border-color: #15C042; }
  .btn-primary:active, .introjs-button:active, .btn-primary:focus, .introjs-button:focus, .btn-primary:hover, .introjs-button:hover {
    color: #fff;
    background-color: #119e36;
    border-color: #109232; }
  .btn-primary.disabled, .disabled.introjs-button, .btn-primary:disabled, .introjs-button:disabled {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }
  .btn-primary:not(:disabled):not(.disabled).active, .introjs-button:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .introjs-button:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.introjs-button {
    color: #fff;
    background-color: #109232;
    border-color: #0f872e; }

.btn-secondary, .introjs-button.introjs-prevbutton {
  color: #fff;
  background-color: #748892;
  border-color: #748892; }
  .btn-secondary:active, .introjs-button.introjs-prevbutton:active, .btn-secondary:focus, .introjs-button.introjs-prevbutton:focus, .btn-secondary:hover, .introjs-button.introjs-prevbutton:hover {
    color: #fff;
    background-color: #62747d;
    border-color: #5d6e76; }
  .btn-secondary.disabled, .disabled.introjs-button.introjs-prevbutton, .btn-secondary:disabled, .introjs-button.introjs-prevbutton:disabled {
    color: #fff;
    background-color: #748892;
    border-color: #748892; }
  .btn-secondary:not(:disabled):not(.disabled).active, .introjs-button.introjs-prevbutton:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .introjs-button.introjs-prevbutton:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle,
  .show > .dropdown-toggle.introjs-button.introjs-prevbutton {
    color: #fff;
    background-color: #5d6e76;
    border-color: #57676f; }

.btn-success {
  color: #fff;
  background-color: #15C042;
  border-color: #15C042; }
  .btn-success:active, .btn-success:focus, .btn-success:hover {
    color: #fff;
    background-color: #119e36;
    border-color: #109232; }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }
  .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #109232;
    border-color: #0f872e; }

.btn-info {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea; }
  .btn-info:active, .btn-info:focus, .btn-info:hover {
    color: #fff;
    background-color: #1cb4e6;
    border-color: #18acdd; }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #3ebfea;
    border-color: #3ebfea; }
  .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #18acdd;
    border-color: #17a3d1; }

.btn-warning {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b; }
  .btn-warning:active, .btn-warning:focus, .btn-warning:hover {
    color: #fff;
    background-color: #ecb50c;
    border-color: #e0ab0c; }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #f4c22b;
    border-color: #f4c22b; }
  .btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #e0ab0c;
    border-color: #d4a20b; }

.btn-danger {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236; }
  .btn-danger:active, .btn-danger:focus, .btn-danger:hover {
    color: #fff;
    background-color: #f22012;
    border-color: #ea1b0d; }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }
  .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1b0d;
    border-color: #de190c; }

.btn-light {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .btn-light:active, .btn-light:focus, .btn-light:hover {
    color: #37474f;
    background-color: #dfdfdf;
    border-color: #d9d9d9; }
  .btn-light.disabled, .btn-light:disabled {
    color: #37474f;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active,
  .show > .btn-light.dropdown-toggle {
    color: #37474f;
    background-color: #d9d9d9;
    border-color: #d2d2d2; }

.btn-dark {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f; }
  .btn-dark:active, .btn-dark:focus, .btn-dark:hover {
    color: #fff;
    background-color: #273338;
    border-color: #222c31; }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #37474f;
    border-color: #37474f; }
  .btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #222c31;
    border-color: #1d2529; }

/* outline buttons */
.btn-outline-primary {
  color: #15C042;
  background-color: transparent;
  background-image: none;
  border-color: #15C042; }
  .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:hover {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }
  .btn-outline-primary.disable .btn-outline-primary:disabled {
    color: #15C042;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }

.btn-outline-secondary {
  color: #748892;
  background-color: transparent;
  background-image: none;
  border-color: #748892; }
  .btn-outline-secondary:active, .btn-outline-secondary:focus, .btn-outline-secondary:hover {
    color: #fff;
    background-color: #748892;
    border-color: #748892; }
  .btn-outline-secondary.disable .btn-outline-secondary:disabled {
    color: #748892;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #748892;
    border-color: #748892; }

.btn-outline-success {
  color: #15C042;
  background-color: transparent;
  background-image: none;
  border-color: #15C042; }
  .btn-outline-success:active, .btn-outline-success:focus, .btn-outline-success:hover {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }
  .btn-outline-success.disable .btn-outline-success:disabled {
    color: #15C042;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #15C042;
    border-color: #15C042; }

.btn-outline-info {
  color: #3ebfea;
  background-color: transparent;
  background-image: none;
  border-color: #3ebfea; }
  .btn-outline-info:active, .btn-outline-info:focus, .btn-outline-info:hover {
    color: #fff;
    background-color: #3ebfea;
    border-color: #3ebfea; }
  .btn-outline-info.disable .btn-outline-info:disabled {
    color: #3ebfea;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3ebfea;
    border-color: #3ebfea; }

.btn-outline-warning {
  color: #f4c22b;
  background-color: transparent;
  background-image: none;
  border-color: #f4c22b; }
  .btn-outline-warning:active, .btn-outline-warning:focus, .btn-outline-warning:hover {
    color: #fff;
    background-color: #f4c22b;
    border-color: #f4c22b; }
  .btn-outline-warning.disable .btn-outline-warning:disabled {
    color: #f4c22b;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f4c22b;
    border-color: #f4c22b; }

.btn-outline-danger {
  color: #f44236;
  background-color: transparent;
  background-image: none;
  border-color: #f44236; }
  .btn-outline-danger:active, .btn-outline-danger:focus, .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }
  .btn-outline-danger.disable .btn-outline-danger:disabled {
    color: #f44236;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44236;
    border-color: #f44236; }

.btn-outline-light {
  color: #f2f2f2;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2; }
  .btn-outline-light:active, .btn-outline-light:focus, .btn-outline-light:hover {
    color: #37474f;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-outline-light.disable .btn-outline-light:disabled {
    color: #f2f2f2;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #37474f;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }

.btn-outline-dark {
  color: #37474f;
  background-color: transparent;
  background-image: none;
  border-color: #37474f; }
  .btn-outline-dark:active, .btn-outline-dark:focus, .btn-outline-dark:hover {
    color: #fff;
    background-color: #37474f;
    border-color: #37474f; }
  .btn-outline-dark.disable .btn-outline-dark:disabled {
    color: #37474f;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #37474f;
    border-color: #37474f; }

/* glow buttons */
.btn-glow-primary {
  box-shadow: 0 1px 6px 2px rgba(21, 192, 66, 0.56), 0 6px 11px 2px rgba(21, 192, 66, 0.2); }
  .btn-glow-primary:hover {
    box-shadow: 0 1px 4px 2px rgba(21, 192, 66, 0.56), 0 4px 9px 2px rgba(21, 192, 66, 0.1); }
  .btn-glow-primary:not(:disabled):not(.disabled).active, .btn-glow-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-primary.dropdown-toggle, .btn-glow-primary.active, .btn-glow-primary:focus {
    box-shadow: none; }

.btn-glow-secondary {
  box-shadow: 0 1px 6px 2px rgba(116, 136, 146, 0.56), 0 6px 11px 2px rgba(116, 136, 146, 0.2); }
  .btn-glow-secondary:hover {
    box-shadow: 0 1px 4px 2px rgba(116, 136, 146, 0.56), 0 4px 9px 2px rgba(116, 136, 146, 0.1); }
  .btn-glow-secondary:not(:disabled):not(.disabled).active, .btn-glow-secondary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-secondary.dropdown-toggle, .btn-glow-secondary.active, .btn-glow-secondary:focus {
    box-shadow: none; }

.btn-glow-success {
  box-shadow: 0 1px 6px 2px rgba(21, 192, 66, 0.56), 0 6px 11px 2px rgba(21, 192, 66, 0.2); }
  .btn-glow-success:hover {
    box-shadow: 0 1px 4px 2px rgba(21, 192, 66, 0.56), 0 4px 9px 2px rgba(21, 192, 66, 0.1); }
  .btn-glow-success:not(:disabled):not(.disabled).active, .btn-glow-success:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-success.dropdown-toggle, .btn-glow-success.active, .btn-glow-success:focus {
    box-shadow: none; }

.btn-glow-info {
  box-shadow: 0 1px 6px 2px rgba(62, 191, 234, 0.56), 0 6px 11px 2px rgba(62, 191, 234, 0.2); }
  .btn-glow-info:hover {
    box-shadow: 0 1px 4px 2px rgba(62, 191, 234, 0.56), 0 4px 9px 2px rgba(62, 191, 234, 0.1); }
  .btn-glow-info:not(:disabled):not(.disabled).active, .btn-glow-info:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-info.dropdown-toggle, .btn-glow-info.active, .btn-glow-info:focus {
    box-shadow: none; }

.btn-glow-warning {
  box-shadow: 0 1px 6px 2px rgba(244, 194, 43, 0.56), 0 6px 11px 2px rgba(244, 194, 43, 0.2); }
  .btn-glow-warning:hover {
    box-shadow: 0 1px 4px 2px rgba(244, 194, 43, 0.56), 0 4px 9px 2px rgba(244, 194, 43, 0.1); }
  .btn-glow-warning:not(:disabled):not(.disabled).active, .btn-glow-warning:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-warning.dropdown-toggle, .btn-glow-warning.active, .btn-glow-warning:focus {
    box-shadow: none; }

.btn-glow-danger {
  box-shadow: 0 1px 6px 2px rgba(244, 66, 54, 0.56), 0 6px 11px 2px rgba(244, 66, 54, 0.2); }
  .btn-glow-danger:hover {
    box-shadow: 0 1px 4px 2px rgba(244, 66, 54, 0.56), 0 4px 9px 2px rgba(244, 66, 54, 0.1); }
  .btn-glow-danger:not(:disabled):not(.disabled).active, .btn-glow-danger:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-danger.dropdown-toggle, .btn-glow-danger.active, .btn-glow-danger:focus {
    box-shadow: none; }

.btn-glow-light {
  box-shadow: 0 1px 6px 2px rgba(242, 242, 242, 0.56), 0 6px 11px 2px rgba(242, 242, 242, 0.2); }
  .btn-glow-light:hover {
    box-shadow: 0 1px 4px 2px rgba(242, 242, 242, 0.56), 0 4px 9px 2px rgba(242, 242, 242, 0.1); }
  .btn-glow-light:not(:disabled):not(.disabled).active, .btn-glow-light:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-light.dropdown-toggle, .btn-glow-light.active, .btn-glow-light:focus {
    box-shadow: none; }

.btn-glow-dark {
  box-shadow: 0 1px 6px 2px rgba(55, 71, 79, 0.56), 0 6px 11px 2px rgba(55, 71, 79, 0.2); }
  .btn-glow-dark:hover {
    box-shadow: 0 1px 4px 2px rgba(55, 71, 79, 0.56), 0 4px 9px 2px rgba(55, 71, 79, 0.1); }
  .btn-glow-dark:not(:disabled):not(.disabled).active, .btn-glow-dark:not(:disabled):not(.disabled):active:focus,
  .show > .btn-glow-dark.dropdown-toggle, .btn-glow-dark.active, .btn-glow-dark:focus {
    box-shadow: none; }

.btn-group.btn-group-vertical > .btn-group:not(:first-child),
.btn-group.btn-group-vertical > .btn:not(:first-child),
.btn-group.btn-group-vertical > .introjs-button:not(:first-child) {
  margin-left: 0; }

/**====== Button css end ======**/
.swal-footer {
  text-align: center; }

/**  =====================
      Alert css start
==========================  **/
/* Base styles */
.alert {
  position: relative;
  padding: 15px 20px;
  border-radius: 0; }

/* Provide class for links that match alerts */
.alert-link {
  font-weight: 600;
  transition: all 0.3s ease-in-out; }
  .alert-link:hover {
    text-decoration: underline; }

.alert-dismissible {
  padding-right: 40px; }
  .alert-dismissible .close {
    padding: 14px 10px; }

/* Alternate styles
 Generate contextual modifier classes for colorizing the alert. */
.alert-primary {
  color: #258648;
  border-color: #bdedca; }
  .alert-primary hr {
    border-top-color: #a9e8ba; }
  .alert-primary .alert-link {
    color: #1a5e33; }

.alert-secondary {
  color: #576972;
  border-color: #d8dee0; }
  .alert-secondary hr {
    border-top-color: #cad2d5; }
  .alert-secondary .alert-link {
    color: #414e55; }

.alert-success {
  color: #258648;
  border-color: #bdedca; }
  .alert-success hr {
    border-top-color: #a9e8ba; }
  .alert-success .alert-link {
    color: #1a5e33; }

.alert-info {
  color: #3b85a0;
  border-color: #c9edf9; }
  .alert-info hr {
    border-top-color: #b2e5f6; }
  .alert-info .alert-link {
    color: #2d667b; }

.alert-warning {
  color: #99873c;
  border-color: #fceec4; }
  .alert-warning hr {
    border-top-color: #fbe7ac; }
  .alert-warning .alert-link {
    color: #74672e; }

.alert-danger {
  color: #994442;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #753433; }

.alert-light {
  color: #98a0a4;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #7d878c; }

.alert-dark {
  color: #37474f;
  border-color: #c7cbce; }
  .alert-dark hr {
    border-top-color: #b9bec2; }
  .alert-dark .alert-link {
    color: #222c31; }

/**====== Alert css end ======**/
/**  =====================
      Breadcrumbs & Pagination css start
==========================  **/
/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0; }

.breadcrumb-item + .breadcrumb-item {
  color: #15C042;
  text-transform: uppercase; }
  .breadcrumb-item + .breadcrumb-item::before {
    /* content: $breadcrumb-divider; */ }

.breadcrumb-item.active {
  color: #111; }

/* pagination */
.page-item.active .page-link {
  color: #fff;
  background-color: #15C042;
  border-color: #15C042; }

/**====== Breadcrumbs & Pagination css end ======**/
/**  =====================
      Progress css start
==========================  **/
@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0; }
  to {
    background-position: 0 0; } }

.progress {
  height: 12px;
  font-size: 10px;
  background-color: #F4FAFF;
  border-radius: 0;
  overflow: visible; }

.progress-bar {
  background-color: #15C042;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3); }
  .progress-bar.bg-c-blue, .progress-bar.progress-c-blue {
    background: #15C042; }
  .progress-bar.bg-c-red, .progress-bar.progress-c-red {
    background: #f44236; }
  .progress-bar.bg-c-green, .progress-bar.progress-c-green {
    background: #15C042; }
  .progress-bar.bg-c-yellow, .progress-bar.progress-c-yellow {
    background: #f4c22b; }
  .progress-bar.bg-c-purple, .progress-bar.progress-c-purple {
    background: #a389d4; }
  .progress-bar.bg-primary, .progress-bar.progress-primary {
    background: #15C042 !important; }
  .progress-bar.bg-danger, .progress-bar.progress-danger {
    background: #f44236 !important; }
  .progress-bar.bg-success, .progress-bar.progress-success {
    background: #15C042 !important; }
  .progress-bar.bg-warning, .progress-bar.progress-warning {
    background: #f4c22b !important; }
  .progress-bar.bg-info, .progress-bar.progress-info {
    background: #3ebfea !important; }
  .progress-bar.bg-dark, .progress-bar.progress-dark {
    background: #37474f !important; }
  .progress-bar.progress-c-theme {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }
  .progress-bar.progress-c-theme2 {
    background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }

/**====== Progress css end ======**/
/**  =====================
      tooltip css start
==========================  **/
.tooltip.show {
  opacity: 1; }

.tooltip .arrow {
  position: absolute;
  display: block; }
  .tooltip .arrow::before {
    content: "\6d";
    left: 0;
    font-family: "pct";
    font-size: 20px;
    line-height: 0.3;
    color: #000;
    border: none;
    text-shadow: 0 2px 3px rgba(220, 220, 220, 0.46); }

.bs-tooltip-right .arrow::before {
  content: "\6a";
  left: -7px;
  top: 2.6px;
  border: none;
  text-shadow: -2px 0 3px rgba(220, 220, 220, 0.46); }

.bs-tooltip-right .tooltip-inner {
  border-radius: 15px 20px 20px 15px; }

.bs-tooltip-bottom .arrow::before {
  content: "\6c";
  border: none;
  text-shadow: 0 -2px 3px rgba(220, 220, 220, 0.46); }

.bs-tooltip-left .arrow::before {
  content: "\6b";
  left: -8px;
  top: 3.6px;
  border: none;
  text-shadow: 2px 0 3px rgba(220, 220, 220, 0.46); }

.bs-tooltip-left .tooltip-inner {
  border-radius: 20px 15px 15px 20px; }

.tooltip-inner {
  border-radius: 20px;
  padding: 6px 15px;
  box-shadow: 0 0 15px #dcdcdc; }

/**====== tooltip css end ======**/
/**  =====================
      tooltip css start
==========================  **/
.popover {
  top: 0;
  left: 0;
  border: none;
  box-shadow: 0 0 15px #dcdcdc;
  border-radius: 15px; }
  .popover .arrow::after {
    display: none; }
  .popover .arrow::before {
    content: "\6d";
    top: -1px;
    left: 0;
    font-family: "pct";
    font-size: 20px;
    line-height: 0.3;
    color: #fff;
    border: none;
    text-shadow: 0 2px 3px rgba(220, 220, 220, 0.7); }
  .popover .popover-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: transparent;
    border-bottom-color: #F4FAFF; }

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  content: "\6d";
  text-shadow: 0 2px 3px rgba(220, 220, 220, 0.7); }

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  content: "\6a";
  left: -4px;
  top: 2.6px;
  border: none;
  text-shadow: -2px 0 3px rgba(220, 220, 220, 0.46); }

.bs-popover-bottom .arrow:before, .bs-popover-auto[x-placement^="bottom"] .arrow:before {
  content: "\6c";
  top: 3px;
  border: none;
  text-shadow: 0 -2px 3px rgba(220, 220, 220, 0.46); }

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  content: "\6b";
  left: -8px;
  top: 3.6px;
  border: none;
  text-shadow: 2px 0 3px rgba(220, 220, 220, 0.46); }

/**====== tooltip css end ======**/
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0px 0px; }
  .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    opacity: 0.7; }

/* ======================== Tabs =============================== */
.nav-tabs {
  border-bottom: none; }
  .nav-tabs .nav-link {
    border: none;
    color: #888; }
    .nav-tabs .nav-link:hover {
      color: #15C042; }
    .nav-tabs .nav-link.disabled {
      opacity: 0.7; }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    color: #111;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05); }

/* =======================   Pills ================== */
.nav-pills {
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05); }
  .nav-pills .nav-link {
    border-radius: 5px; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background: #15C042;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2); }

/* ==================== Justified variants ===================*/
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* Tabbable tabs */
/* Hide tabbable panes to start, show them when `.active` */
.tab-content {
  padding: 35px 30px;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05); }
  .tab-content > .tab-pane {
    display: none; }
  .tab-content > .active {
    display: block; }

@media screen and (max-width: 480px) {
  .modal-footer {
    justify-content: center; } }

/* General styles for the modal animate start*/
.modal.fade.modal-animate {
  /* Effect 1: Fade in and scale up */
  /* Effect 2: Slide from the right */
  /* Effect 3: Slide from the bottom */
  /* Effect 4: Newspaper */
  /* Effect 5: fall */
  /* Effect 6: side fall */
  /* Effect 7:  slide and stick to top */
  /* Effect 8: 3D flip horizontal */
  /* Effect 9: 3D flip vertical */
  /* Effect 10: 3D sign */
  /* Effect 11: Super scaled */
  /* Effect 12:  Just me */
  /* Effect 13: 3D slit */
  /* Effect 14:  3D Rotate from bottom */
  /* Effect 15:  3D Rotate in from left */
  /* Effect 16:  Blur */
  /* Effect 17:  Slide in from bottom with perspective on container */
  /* Effect 18:  Slide from right with perspective on container */
  /* Effect 19:  Slip in from the top with perspective on container */ }
  .modal.fade.modal-animate .modal-dialog {
    display: flex;
    align-items: center;
    height: calc(100% - 3.5rem); }
  .modal.fade.modal-animate.anim-fade-in-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-fade-in-scale.show .modal-dialog {
    transform: none;
    opacity: 1; }
  .modal.fade.modal-animate.anim-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9); }
  .modal.fade.modal-animate.anim-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
    transition: all 0.5s; }
  .modal.fade.modal-animate.anim-newspaper.show .modal-dialog {
    transform: none;
    opacity: 1; }
  .modal.fade.modal-animate.anim-fall {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-fall .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(600px) rotateX(20deg);
      opacity: 0;
      transition: all 0.5s; }
    .modal.fade.modal-animate.anim-fall.show .modal-dialog {
      transition: all 0.3s ease-in;
      transform: translateZ(0px) rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-side-fall {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-side-fall .modal-dialog {
      transform-style: preserve-3d;
      transform: translate(30%) translateZ(600px) rotate(10deg);
      opacity: 0;
      transition: all 0.3s ease-in; }
    .modal.fade.modal-animate.anim-side-fall.show .modal-dialog {
      transform: translate(0%) translateZ(0) rotate(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-sticky-up .modal-dialog {
    align-items: flex-start;
    margin-top: 0;
    transform: translateY(-200%);
    transition: all .3s;
    opacity: 0; }
  .modal.fade.modal-animate.anim-sticky-up.show .modal-dialog {
    transform: translateY(0%);
    border-radius: 0 0 3px 3px;
    opacity: 1; }
  .modal.fade.modal-animate.anim-3d-flip-horizontal {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-flip-horizontal .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateY(-70deg);
      transition: all 0.3s;
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-flip-horizontal.show .modal-dialog {
      transform: rotateY(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-flip-vertical {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-flip-vertical .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateX(-70deg);
      transition: all 0.3s;
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-flip-vertical.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-sign {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-sign .modal-dialog {
      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-3d-sign.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s; }
  .modal.fade.modal-animate.anim-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1; }
  .modal.fade.modal-animate.anim-just-me {
    background: #fff; }
    .modal.fade.modal-animate.anim-just-me .modal-dialog {
      transform: scale(0.8);
      opacity: 0;
      transition: all 0.3s;
      background: transparent; }
      .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content {
        border: none; }
        .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header {
          background: transparent !important; }
          .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .btn-close.btn-close-white {
            filter: none; }
          .modal.fade.modal-animate.anim-just-me .modal-dialog .modal-content .modal-header .text-white.modal-title {
            color: #111 !important; }
    .modal.fade.modal-animate.anim-just-me.show .modal-dialog {
      transform: scale(1);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-slit {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-slit .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(-3000px) rotateY(90deg);
      opacity: 0; }
    .modal.fade.modal-animate.anim-3d-slit.show .modal-dialog {
      animation: slit .7s forwards ease-out; }
  .modal.fade.modal-animate.anim-3d-rotate-bottom {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-rotate-bottom .modal-dialog {
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s ease-out; }
    .modal.fade.modal-animate.anim-3d-rotate-bottom.show .modal-dialog {
      transform: translateY(0%) rotateX(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-3d-rotate-InLeft {
    perspective: 1300px; }
    .modal.fade.modal-animate.anim-3d-rotate-InLeft .modal-dialog {
      transform-style: preserve-3d;
      transform: translateZ(100px) translateX(-30%) rotateY(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-3d-rotate-InLeft.show .modal-dialog {
      transform: translateZ(0px) translateX(0%) rotateY(0deg);
      opacity: 1; }
  .modal.fade.modal-animate.anim-blur {
    backdrop-filter: blur(8px); }
    .modal.fade.modal-animate.anim-blur .modal-dialog {
      transform: scale(0.7);
      opacity: 0;
      transition: all 0.3s; }
    .modal.fade.modal-animate.anim-blur.show .modal-dialog {
      transform: none;
      opacity: 1; }
  .modal.fade.modal-animate.anim-let-me-in .modal-dialog {
    opacity: 0;
    transform: translateY(200%);
    transition: all 0.3s 0.2s; }
  .modal.fade.modal-animate.anim-let-me-in.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-make-way .modal-dialog {
    opacity: 0;
    transform: translateX(200%);
    transition: all 0.3s 0.2s; }
  .modal.fade.modal-animate.anim-make-way.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }
  .modal.fade.modal-animate.anim-slip-from-top .modal-dialog {
    opacity: 0;
    transform: translateY(-200%);
    transition: all 0.5s 0.1s; }
  .modal.fade.modal-animate.anim-slip-from-top.show .modal-dialog {
    transform: translateY(0);
    opacity: 1; }

body {
  /* Effect 17:  Slide in from bottom with perspective on container */
  /* Effect 18:  Slide from right with perspective on container */
  /* Effect 19:  Slip in from the top with perspective on container */ }
  body.anim-let-me-in {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-let-me-in .header-user-list,
    body.anim-let-me-in .header-chat,
    body.anim-let-me-in .menu-styler {
      display: none; }
    body.anim-let-me-in .pcoded-navbar,
    body.anim-let-me-in .pcoded-header,
    body.anim-let-me-in .pcoded-main-container,
    body.anim-let-me-in .modal-backdrop {
      transition: transform 0.3s; }
    body.anim-let-me-in.modal-open .pcoded-navbar,
    body.anim-let-me-in.modal-open .pcoded-header,
    body.anim-let-me-in.modal-open .pcoded-main-container,
    body.anim-let-me-in.modal-open .modal-backdrop {
      transform: rotateX(-3deg);
      transform-origin: 50% 0%;
      transform-style: preserve-3d; }
  body.anim-make-way {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-make-way .header-user-list,
    body.anim-make-way .header-chat,
    body.anim-make-way .menu-styler {
      display: none; }
    body.anim-make-way .pcoded-navbar,
    body.anim-make-way .pcoded-header,
    body.anim-make-way .pcoded-main-container,
    body.anim-make-way .modal-backdrop {
      transition: all 0.5s; }
    body.anim-make-way.modal-open .pcoded-navbar,
    body.anim-make-way.modal-open .pcoded-header,
    body.anim-make-way.modal-open .pcoded-main-container,
    body.anim-make-way.modal-open .modal-backdrop {
      transform-style: preserve-3d;
      transform-origin: 0% 50%;
      animation: rotateRightSideFirst 0.5s forwards ease-in; }
  body.anim-slip-from-top {
    perspective: 600px;
    overflow: hidden;
    height: 100vh; }
    body.anim-slip-from-top .header-user-list,
    body.anim-slip-from-top .header-chat,
    body.anim-slip-from-top .menu-styler {
      display: none; }
    body.anim-slip-from-top .pcoded-navbar,
    body.anim-slip-from-top .pcoded-header,
    body.anim-slip-from-top .pcoded-main-container,
    body.anim-slip-from-top .modal-backdrop {
      transition: all 0.5s;
      transform-style: preserve-3d;
      transform-origin: 50% 100%;
      animation: OpenTop 1.5s forwards ease-in; }
    body.anim-slip-from-top.modal-open .pcoded-header {
      opacity: 0; }

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in; }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1; } }

@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out; }
  100% {
    transform: translateZ(-200px); } }

@keyframes OpenTop {
  50% {
    transform: rotateX(10deg);
    animation-timing-function: ease-out; } }

/* General styles for the modal animate  end */
.sequence-diagram .sequence-legend .title {
  font-size: 14px;
  font-weight: bolder;
  line-height: 1.5;
  text-transform: uppercase;
  color: #15C042;
  padding-bottom: 10px;
  padding-top: 15px; }

.sequence-diagram .sequence-legend ul.keys {
  list-style: none;
  padding-left: 0; }
  .sequence-diagram .sequence-legend ul.keys li.key {
    float: left;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    padding-right: 1em; }
    .sequence-diagram .sequence-legend ul.keys li.key span.chiclet {
      float: left;
      width: 20px;
      height: 20px;
      margin: 2px;
      border-radius: 6px;
      padding-top: 4px;
      user-select: none; }

.sequence-diagram .sequence-graph {
  padding-top: 15px;
  height: 80px;
  white-space: normal; }
  .sequence-diagram .sequence-graph ul.points {
    list-style: none;
    padding-left: 0; }
    .sequence-diagram .sequence-graph ul.points .point {
      position: relative;
      float: left;
      width: 120px;
      height: 40px;
      margin: 2px;
      border-radius: 6px;
      padding: 8px 8px 8px 8px;
      user-select: none;
      vertical-align: middle;
      cursor: pointer;
      color: white;
      text-align: right; }
      .sequence-diagram .sequence-graph ul.points .point .count {
        display: block;
        padding: 5px 10px 0 0;
        color: white;
        font-size: 14px;
        font-weight: bold;
        margin-right: 1rem;
        text-align: right;
        width: 100%;
        height: 100%;
        border-radius: 6px; }
    .sequence-diagram .sequence-graph ul.points #sequence-search-clear {
      width: 30px;
      height: 30px;
      position: relative;
      float: left; }
    .sequence-diagram .sequence-graph ul.points li.point:not(:first-child) {
      margin-left: -15px;
      z-index: 0; }

table.dataTable td > span.chiclet {
  float: left;
  width: 20px;
  height: 20px;
  margin: 2px;
  border-radius: 6px;
  padding-top: 4px;
  user-select: none; }

#animateModal.modal.notif-overlay {
  border-spacing: 0;
  border: 1px solid #15C042 !important;
  border-radius: 14px;
  overflow: hidden; }
  #animateModal.modal.notif-overlay .modal-dialog {
    max-width: 100%;
    transform: translate(0, 0);
    height: 500px; }
    #animateModal.modal.notif-overlay .modal-dialog .text-white {
      color: black; }
    #animateModal.modal.notif-overlay .modal-dialog .modal-content div.modal-header {
      background: white !important;
      display: block !important; }
    #animateModal.modal.notif-overlay .modal-dialog .modal-content .notif-btn-red {
      background: linear-gradient(180deg, #C8626A 48.78%, #C8424A 48.78%);
      font-weight: bold;
      color: white; }

.date-picker .ui-datepicker {
  width: max-content; }

.date-picker .ui-widget {
  font-size: 1rem;
  font-family: "GraphieFontBold", sans-serif; }

.date-picker .ui-widget-header {
  font-size: 1.3rem; }

.date-picker .ui-widget-content {
  background: #15C042;
  color: #f2f2f2; }

.date-picker .ui-widget.ui-widget-content {
  border: 0;
  border-radius: 2em;
  padding: 5px 17px; }

.date-picker .ui-datepicker-header {
  background: transparent;
  border: 0; }
  .date-picker .ui-datepicker-header span {
    color: #f2f2f2; }

.date-picker .ui-datepicker th {
  text-transform: uppercase; }

.date-picker .ui-datepicker-title {
  text-transform: uppercase; }

.date-picker .ui-state-default,
.date-picker .ui-widget-content .ui-state-default {
  border: 0;
  background: transparent;
  font-weight: normal;
  color: #f2f2f2; }

.date-picker .ui-datepicker tr {
  vertical-align: top; }

.date-picker .ui-datepicker th {
  height: 40px;
  font-size: 0.9rem; }

.date-picker .ui-datepicker td {
  padding: 3px !important;
  width: 42px; }

.date-picker .ui-datepicker td span,
.date-picker .ui-datepicker td a {
  text-align: center;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle; }

.date-picker .ui-datepicker .ui-datepicker-prev span,
.date-picker .ui-datepicker .ui-datepicker-next span {
  background-position: 0px 0px !important;
  background-size: contain;
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 26px; }

.date-picker .ui-datepicker .ui-datepicker-prev span {
  background-image: url("../images/prev.png") !important; }

.date-picker .ui-datepicker .ui-datepicker-next span {
  background-image: url("../images/next.png") !important; }

.date-picker .ui-datepicker .ui-datepicker-prev.ui-datepicker-prev-hover,
.date-picker .ui-datepicker .ui-datepicker-next.ui-datepicker-next-hover {
  background: transparent;
  border: 0;
  top: 2px;
  cursor: pointer; }

.date-picker .ui-datepicker .ui-datepicker-prev.ui-datepicker-prev-hover {
  left: 2px; }

.date-picker .ui-datepicker .ui-datepicker-next.ui-datepicker-next-hover {
  right: 2px; }

.date-picker .ui-state-active,
.date-picker .ui-widget-content .ui-state-active,
.date-picker .ui-widget-header .ui-state-active,
.date-picker a.ui-button:active,
.date-picker .ui-button:active,
.date-picker .ui-button.ui-state-active:hover {
  border-radius: 100px;
  background: #f2f2f2;
  color: #15C042;
  width: 100%;
  padding: 0;
  width: 36px;
  height: 36px;
  aspect-ratio: 1 / 1; }

.date-picker .ui-state-disabled, .date-picker .ui-widget-content .ui-state-disabled, .date-picker .ui-widget-header .ui-state-disabled {
  opacity: 0.5; }

.date-picker .ui-state-disabled span, .date-picker .ui-widget-content .ui-state-disabled span, .date-picker .ui-widget-header .ui-state-disabled span {
  color: #37474f; }

/**  =====================
      Chatting css start
==========================  **/
.header-chat,
.header-user-list {
  height: 100%;
  width: 350px;
  position: fixed;
  top: 0;
  right: -350px;
  border-radius: 0;
  z-index: 1030;
  background-color: #fff;
  transition: all 0.3s ease-in-out; }
  .header-chat .main-friend-cont,
  .header-user-list .main-friend-cont {
    height: calc(100vh - 66px); }
  .header-chat .h-list-header,
  .header-user-list .h-list-header {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1; }
  .header-chat .h-list-body,
  .header-user-list .h-list-body {
    padding: 20px 0; }
  .header-chat.open,
  .header-user-list.open {
    box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
    right: 0; }

.header-user-list.open .h-close-text {
  position: absolute;
  top: 111px;
  left: -99px; }
  .header-user-list.open .h-close-text i {
    position: absolute;
    top: 23px;
    left: 73px;
    font-size: 25px;
    z-index: 1003;
    color: #15C042; }
  .header-user-list.open .h-close-text:after {
    content: "\61";
    font-family: "pct";
    z-index: 1001;
    font-size: 150px;
    line-height: 0;
    color: #fff;
    position: absolute;
    top: 35px;
    left: 6px;
    text-shadow: -4px 0 7px rgba(69, 90, 100, 0.12); }

.header-user-list.open.msg-open:after {
  color: rgba(21, 192, 66, 0.1); }

.h-list-header .form-control:active, .h-list-header .form-control:focus, .h-list-header .form-control:hover {
  box-shadow: none;
  outline: none; }

.h-list-body {
  position: relative; }
  .h-list-body .userlist-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    position: relative; }
    .h-list-body .userlist-box:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background: #f3f4f9; }
    .h-list-body .userlist-box.active {
      background: #c2f8d0; }
    .h-list-body .userlist-box .media-left {
      padding-right: 10px; }
    .h-list-body .userlist-box .media-object {
      width: 50px;
      display: inline-block; }
    .h-list-body .userlist-box .chat-header {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0; }
      .h-list-body .userlist-box .chat-header small {
        margin-top: 5px;
        font-size: 90%; }
    .h-list-body .userlist-box .live-status {
      height: 25px;
      width: 25px;
      position: absolute;
      top: 35px;
      right: 20px;
      border-radius: 100%;
      color: #fff;
      padding: 2px 0;
      text-align: center;
      background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%); }

.header-chat .h-list-header {
  text-align: center;
  position: relative; }
  .header-chat .h-list-header h6 {
    margin: 5px 0; }
  .header-chat .h-list-header .h-back-user-list {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 40px;
    justify-content: center;
    font-size: 20px; }

.header-chat .main-chat-cont {
  height: calc(100vh - 166px); }

.header-chat .h-list-body {
  height: 100%;
  background: #c2f8d0; }

.header-chat .h-list-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 20px 15px;
  z-index: 10;
  background: #c2f8d0; }
  .header-chat .h-list-footer .input-group {
    background: #fff;
    border: none;
    display: inline-flex;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 7px;
    border-radius: 20px 0 10px 20px;
    width: calc(100% - 40px); }
    .header-chat .h-list-footer .input-group .form-control,
    .header-chat .h-list-footer .input-group .input-group-text {
      background: transparent;
      border: none;
      border-radius: 0;
      padding: 0; }
    .header-chat .h-list-footer .input-group .btn-send:active, .header-chat .h-list-footer .input-group .btn-send:focus, .header-chat .h-list-footer .input-group .btn-send:hover,
    .header-chat .h-list-footer .input-group .form-control:active,
    .header-chat .h-list-footer .input-group .form-control:focus,
    .header-chat .h-list-footer .input-group .form-control:hover {
      outline: none;
      box-shadow: none; }
    .header-chat .h-list-footer .input-group .btn-attach {
      border-radius: 50% !important;
      padding: 5px;
      margin-right: 5px;
      line-height: 1; }
      .header-chat .h-list-footer .input-group .btn-attach > i {
        margin-right: 0; }
    .header-chat .h-list-footer .input-group .btn-send {
      border-radius: 50% !important;
      padding: 10px;
      margin-left: 5px;
      position: absolute;
      right: -45px;
      top: 2px;
      z-index: 99;
      line-height: 1; }
      .header-chat .h-list-footer .input-group .btn-send i {
        margin-right: 0; }
      .header-chat .h-list-footer .input-group .btn-send .input-group-text {
        color: #fff; }
    .header-chat .h-list-footer .input-group .form-control {
      transition: all 0.3s ease-in-out;
      width: 0; }
    .header-chat .h-list-footer .input-group:after {
      content: "\67";
      font-family: "pct";
      z-index: 1001;
      font-size: 35px;
      line-height: 0;
      color: #fff;
      position: absolute;
      top: 18px;
      right: -23px;
      text-shadow: 4px 10px 20px rgba(0, 0, 0, 0.1); }

.h-list-body .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px; }
  .h-list-body .chat-messages .photo-table {
    padding-right: 15px; }
    .h-list-body .chat-messages .photo-table img {
      display: inline-block;
      width: 50px;
      margin-bottom: 5px; }
  .h-list-body .chat-messages .chat-menu-content > div,
  .h-list-body .chat-messages .chat-menu-reply > div {
    position: relative;
    overflow: visible;
    display: inline-block; }
    .h-list-body .chat-messages .chat-menu-content > div .chat-cont,
    .h-list-body .chat-messages .chat-menu-reply > div .chat-cont {
      padding: 15px 20px; }
  .h-list-body .chat-messages .chat-menu-content .chat-time,
  .h-list-body .chat-messages .chat-menu-reply .chat-time {
    margin: 9px 8px 0 10px; }
  .h-list-body .chat-messages .chat-menu-reply {
    text-align: right; }
    .h-list-body .chat-messages .chat-menu-reply > div p {
      background: #fff;
      border-radius: 4px;
      margin-bottom: 4px;
      margin-right: 25px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
      .h-list-body .chat-messages .chat-menu-reply > div p:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .h-list-body .chat-messages .chat-menu-reply > div p:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 0; }
    .h-list-body .chat-messages .chat-menu-reply > div:before {
      content: "\66";
      font-family: "pct";
      z-index: 5;
      font-size: 30px;
      line-height: 0;
      color: #fff;
      position: absolute;
      bottom: 19px;
      right: 5px;
      text-shadow: 7px 10px 20px rgba(0, 0, 0, 0.1); }
  .h-list-body .chat-messages .chat-menu-content > div p {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    color: #fff;
    border-radius: 4px;
    margin-bottom: 4px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
    .h-list-body .chat-messages .chat-menu-content > div p:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 10px; }
    .h-list-body .chat-messages .chat-menu-content > div p:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
  .h-list-body .chat-messages .chat-menu-content > div:before {
    content: "\68";
    font-family: "pct";
    z-index: 1001;
    font-size: 30px;
    line-height: 0;
    color: #1ec9de;
    position: absolute;
    top: 15px;
    left: -20px;
    text-shadow: -4px 4px 10px rgba(0, 0, 0, 0.15); }

/* massage page start */
.msg-card .msg-user-list {
  height: calc(100vh - 300px); }

.msg-card .msg-user-chat {
  background: #c2f8d0;
  height: calc(100vh - 330px);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 5px;
  padding-right: 5px; }

.msg-card .msg-block > .row > div:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #f1f1f1;
  position: absolute;
  top: 0; }

.msg-card .msg-block > .row > div:first-child:before {
  right: 0; }

.msg-card .msg-block > .row > div:last-child:before {
  left: -1px; }

.main-friend-chat {
  padding-bottom: 15px; }

@media screen and (max-width: 991px) {
  .msg-card .msg-block > .row > div:before {
    background: none; } }

/* massage page end */
/**====== Chat css end ======**/
/**  =====================
      Prism css start
==========================  **/
pre[class*="language-"] code {
  font-size: 14px; }

pre[class*="language-"] [class*="language-"],
pre[class*="language-"] .language-markup {
  background-color: transparent;
  display: block;
  padding: 10px 15px; }

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

pre.line-numbers > code {
  position: relative; }

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.line-numbers-rows > span:before {
  content: counter(linenumber);
  display: block;
  padding-right: 0.8em;
  text-align: right; }

div.code-toolbar {
  position: relative; }

div.code-toolbar > .toolbar {
  position: absolute;
  top: 0.3em;
  right: 0.2em;
  transition: opacity 0.3s ease-in-out;
  opacity: 0; }

div.code-toolbar:hover > .toolbar {
  opacity: 1; }

div.code-toolbar > .toolbar .toolbar-item {
  display: inline-block; }

div.code-toolbar > .toolbar a {
  cursor: pointer; }

div.code-toolbar > .toolbar button {
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible; }

div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
  color: #bbb;
  font-size: 0.8em;
  padding: 0 0.5em;
  background: #f5f2f0;
  background: rgba(224, 224, 224, 0.2);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5em; }

div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar span:focus,
div.code-toolbar > .toolbar span:hover {
  color: inherit;
  text-decoration: none; }

/**====== Prism css end ======**/
/**  =====================
      Toolbar css start
==========================  **/
.tool-bottom .tool-item:first-child,
.tool-top .tool-item:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.tool-bottom .tool-item:last-child,
.tool-top .tool-item:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.tool-left .tool-item:first-child,
.tool-left .tool-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

.tool-left .tool-item:last-child,
.tool-left .tool-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }

.toolbar-primary .tool-item {
  background: #15C042; }
  .toolbar-primary .tool-item.selected, .toolbar-primary .tool-item:hover {
    background: #109232; }

.toolbar-primary.tool-top .arrow {
  border-color: #15C042 transparent transparent; }

.toolbar-primary.tool-bottom .arrow {
  border-color: transparent transparent #15C042; }

.toolbar-primary.tool-left .arrow {
  border-color: transparent transparent transparent #15C042; }

.toolbar-primary.tool-right .arrow {
  border-color: transparent #15C042 transparent transparent; }

.btn-toolbar-primary.pressed {
  background-color: #15C042; }

.toolbar-warning .tool-item {
  background: #f4c22b; }
  .toolbar-warning .tool-item.selected, .toolbar-warning .tool-item:hover {
    background: #e0ab0c; }

.toolbar-warning.tool-top .arrow {
  border-color: #f4c22b transparent transparent; }

.toolbar-warning.tool-bottom .arrow {
  border-color: transparent transparent #f4c22b; }

.toolbar-warning.tool-left .arrow {
  border-color: transparent transparent transparent #f4c22b; }

.toolbar-warning.tool-right .arrow {
  border-color: transparent #f4c22b transparent transparent; }

.btn-toolbar-warning.pressed {
  background-color: #f4c22b; }

.toolbar-light .tool-item {
  background: #d6d6d6; }
  .toolbar-light .tool-item.selected, .toolbar-light .tool-item:hover {
    background: #bdbdbd; }

.toolbar-light.tool-top .arrow {
  border-color: #d6d6d6 transparent transparent; }

.toolbar-light.tool-bottom .arrow {
  border-color: transparent transparent #d6d6d6; }

.toolbar-light.tool-left .arrow {
  border-color: transparent transparent transparent #d6d6d6; }

.toolbar-light.tool-right .arrow {
  border-color: transparent #d6d6d6 transparent transparent; }

.btn-toolbar-light.pressed {
  background-color: #d6d6d6; }

.toolbar-danger .tool-item {
  background: #f44236; }
  .toolbar-danger .tool-item.selected, .toolbar-danger .tool-item:hover {
    background: #ea1b0d; }

.toolbar-danger.tool-top .arrow {
  border-color: #f44236 transparent transparent; }

.toolbar-danger.tool-bottom .arrow {
  border-color: transparent transparent #f44236; }

.toolbar-danger.tool-left .arrow {
  border-color: transparent transparent transparent #f44236; }

.toolbar-danger.tool-right .arrow {
  border-color: transparent #f44236 transparent transparent; }

.btn-toolbar-danger.pressed {
  background-color: #f44236; }

.toolbar-success .tool-item {
  background: #15C042; }
  .toolbar-success .tool-item.selected, .toolbar-success .tool-item:hover {
    background: #109232; }

.toolbar-success.tool-top .arrow {
  border-color: #15C042 transparent transparent; }

.toolbar-success.tool-bottom .arrow {
  border-color: transparent transparent #15C042; }

.toolbar-success.tool-left .arrow {
  border-color: transparent transparent transparent #15C042; }

.toolbar-success.tool-right .arrow {
  border-color: transparent #15C042 transparent transparent; }

.btn-toolbar-success.pressed {
  background-color: #15C042; }

.toolbar-dark .tool-item {
  background: #37474f; }
  .toolbar-dark .tool-item.selected, .toolbar-dark .tool-item:hover {
    background: #222c31; }

.toolbar-dark.tool-top .arrow {
  border-color: #37474f transparent transparent; }

.toolbar-dark.tool-bottom .arrow {
  border-color: transparent transparent #37474f; }

.toolbar-dark.tool-left .arrow {
  border-color: transparent transparent transparent #37474f; }

.toolbar-dark.tool-right .arrow {
  border-color: transparent #37474f transparent transparent; }

.btn-toolbar-dark.pressed {
  background-color: #37474f; }

.toolbar-info .tool-item {
  background: #3ebfea; }
  .toolbar-info .tool-item.selected, .toolbar-info .tool-item:hover {
    background: #18acdd; }

.toolbar-info.tool-top .arrow {
  border-color: #3ebfea transparent transparent; }

.toolbar-info.tool-bottom .arrow {
  border-color: transparent transparent #3ebfea; }

.toolbar-info.tool-left .arrow {
  border-color: transparent transparent transparent #3ebfea; }

.toolbar-info.tool-right .arrow {
  border-color: transparent #3ebfea transparent transparent; }

.btn-toolbar-info.pressed {
  background-color: #3ebfea; }

/**====== Toolbar css end ======**/
.alert-dismissable .close,
.alert-dismissible .close {
  color: inherit;
  text-shadow: none; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.alert-inverse {
  background-color: #333;
  border-color: transparent;
  color: #fff; }
  .alert-inverse .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: #fff;
    text-shadow: none; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.growl-animated.alert-inverse {
  box-shadow: 0 0 5px rgba(51, 51, 51, 0.5); }

.growl-animated.alert-info {
  box-shadow: 0 0 5px rgba(21, 192, 66, 0.5); }

.growl-animated.alert-success {
  box-shadow: 0 0 5px rgba(21, 192, 66, 0.5); }

.growl-animated.alert-warning {
  box-shadow: 0 0 5px rgba(244, 194, 43, 0.5); }

.growl-animated.alert-danger {
  box-shadow: 0 0 5px rgba(244, 66, 54, 0.5); }

[data-notify] .close {
  border: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  margin-top: 5px;
  color: transparent !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  filter: invert(1) grayscale(100%) brightness(200%); }

[data-notify="progressbar"] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; }

table.dataTable > td.center {
  text-align: center; }

table.dataTable > td.left {
  text-align: left; }

.cf:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

/**
 * Nestable
 */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.dd-list .dd-list {
  padding-left: 30px; }

.dd-collapsed .dd-list {
  display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px; }

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(to top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(to top, #fafafa 0%, #eee 100%);
  background: linear-gradient(to top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-handle:hover {
  color: #2ea8e5;
  background: #fff; }

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold; }

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0; }

.dd-item > button[data-action="collapse"]:before {
  content: '-'; }

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px; }

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999; }

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0; }

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }

/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd; }

#nestable-menu {
  padding: 0;
  margin: 20px 0; }

#nestable-output,
#nestable2-output {
  width: 100%;
  height: 7em;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: Consolas, monospace;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

#nestable2 .dd-handle {
  color: #fff;
  border: 1px solid #999;
  background: #bbb;
  background: -webkit-linear-gradient(to top, #bbb 0%, #999 100%);
  background: -moz-linear-gradient(to top, #bbb 0%, #999 100%);
  background: linear-gradient(to top, #bbb 0%, #999 100%); }

#nestable2 .dd-handle:hover {
  background: #bbb; }

#nestable2 .dd-item > button:before {
  color: #fff; }

@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 100%; }
  .dd + .dd {
    margin-left: 2%; } }

.dd-hover > .dd-handle {
  background: #2ea8e5 !important; }

/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(to top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(to top, #fafafa 0%, #eee 100%);
  background: linear-gradient(to top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff; }

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0; }

.dd3-item > button {
  margin-left: 30px; }

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(to top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(to top, #ddd 0%, #bbb 100%);
  background: linear-gradient(to top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal; }

.dd3-handle:hover {
  background: #ddd; }

[ui-pnotify].ui-pnotify .brighttheme {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }
  [ui-pnotify].ui-pnotify .brighttheme.ui-pnotify-container {
    padding: 1.3rem; }

[ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-confirm,
[ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-text,
[ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-title {
  margin-left: 1.8rem; }

[dir=rtl] [ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-confirm,
[dir=rtl] [ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-text,
[dir=rtl] [ui-pnotify].ui-pnotify-with-icon .brighttheme .ui-pnotify-title {
  margin-right: 1.8rem;
  margin-left: 0; }

[ui-pnotify].ui-pnotify .brighttheme-icon-notice:after {
  content: "!"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-info:after {
  content: "i"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-success:after {
  content: "\002713"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-error:after {
  content: "!"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-closer:after {
  content: "\002715"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-sticker:after {
  top: -1px;
  content: "\002016"; }

[ui-pnotify].ui-pnotify .brighttheme-icon-sticker.brighttheme-icon-stuck:after {
  content: "\00003E"; }

[data-pnotify].custom-elem {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
  line-height: 1.1; }

[data-pnotify] .custom-container {
  background-color: #404040;
  background-image: none;
  border: none;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  font-size: 15px; }

[data-pnotify] .custom-title,
[data-pnotify] .custom-text {
  margin-left: 50px;
  color: #FFF; }

[data-pnotify] .custom-title {
  font-weight: bold;
  font-size: 15px; }

[data-pnotify] .custom-icon {
  color: #FFA; }

.slider.slider-horizontal .slider-handle.triangle,
.slider.slider-horizontal .slider-tick.triangle {
  border-bottom-color: #15C042; }

.slider.slider-vertical .slider-handle.triangle,
.slider.slider-vertical .slider-tick.triangle {
  border-left-color: #15C042;
  border-right-color: #15C042; }

.slider.slider-disabled .slider-handle {
  background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
  background-repeat: repeat-x; }

.slider.slider-disabled .slider-track {
  background-image: linear-gradient(to bottom, #f4f7fa 0, #f4f7fa 100%);
  background-repeat: repeat-x; }

.slider-track {
  background-image: linear-gradient(to bottom, #f4f7fa 0, #f4f7fa 100%);
  background-repeat: repeat-x; }

.slider-selection {
  background-image: linear-gradient(to bottom, #66ee8a 0, #66ee8a 100%);
  background-repeat: repeat-x; }
  .slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, #4fec78 0, #4fec78 100%);
    background-repeat: repeat-x; }

.slider-handle {
  background-color: #15C042;
  background-image: linear-gradient(to bottom, #15C042 0, #15C042 100%);
  background-repeat: repeat-x; }

.slider-tick {
  background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
  background-repeat: repeat-x; }
  .slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #4fec78 0, #4fec78 100%);
    background-repeat: repeat-x; }

#ex7-enabled {
  position: relative;
  top: 4px;
  height: 18px;
  width: 18px; }

#RGB {
  height: 10px;
  background: gray; }

#RC .slider-selection {
  background: #FF8282; }

#RC .slider-handle {
  background: red; }

#GC .slider-selection {
  background: #428041; }

#GC .slider-handle {
  background: green; }

#BC .slider-selection {
  background: #8283FF; }

#BC .slider-handle {
  border-bottom-color: blue; }

#B,
#G,
#R {
  width: 300px; }

.slider-handle.custom {
  background: transparent none; }
  .slider-handle.custom::before {
    line-height: 15px;
    font-size: 28px;
    content: '\2605';
    color: #726204; }

#slider12a .slider-track-high,
#slider12c .slider-track-high {
  background: #15C042; }

#slider12b .slider-track-low {
  background: #f44236; }

#slider12c .slider-track-low {
  background: #f44236; }

#slider12c .slider-selection {
  background: #f4c22b; }

#slider22 .slider-rangeHighlight {
  background: #f70616; }
  #slider22 .slider-rangeHighlight.category1 {
    background: #FF9900; }
  #slider22 .slider-rangeHighlight.category2 {
    background: #99CC00; }

/**  =====================
      Switches css start
==========================  **/
.switch input[type=checkbox] {
  opacity: 0;
  position: absolute; }
  .switch input[type=checkbox] + .cr {
    position: relative;
    display: inline-block;
    transition: 0.4s ease;
    height: 20px;
    width: 35px;
    border: 1px solid #e9eaec;
    border-radius: 60px;
    cursor: pointer;
    z-index: 0;
    top: 4px; }
    .switch input[type=checkbox] + .cr:after, .switch input[type=checkbox] + .cr:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0; }
    .switch input[type=checkbox] + .cr:before {
      transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      height: 20px;
      width: 35px;
      border-radius: 30px; }
    .switch input[type=checkbox] + .cr:after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
      transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
      background: #f7f7f7;
      height: 19px;
      width: 19px;
      border-radius: 60px; }
  .switch input[type=checkbox]:checked + .cr:before {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1); }
  .switch input[type=checkbox]:checked + .cr:after {
    left: 16px; }
  .switch input[type=checkbox]:disabled + label {
    opacity: 0.5;
    filter: grayscale(0.4);
    cursor: not-allowed; }

.switch.switch-primary input[type=checkbox]:checked + .cr:before {
  background: #15C042; }

.switch.switch-danger input[type=checkbox]:checked + .cr:before {
  background: #f44236; }

.switch.switch-success input[type=checkbox]:checked + .cr:before {
  background: #15C042; }

.switch.switch-warning input[type=checkbox]:checked + .cr:before {
  background: #f4c22b; }

.switch.switch-info input[type=checkbox]:checked + .cr:before {
  background: #3ebfea; }

.switch.switch-dark input[type=checkbox]:checked + .cr:before {
  background: #37474f; }

.switch.switch-alternative input[type=checkbox]:checked + .cr:before {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%); }

.slow .switch-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s; }

.fast .switch-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s; }

.quick .switch-group {
  transition: none;
  -webkit-transition: none; }

.table.switch-table th,
.table.switch-table td {
  vertical-align: middle;
  padding: 0.45rem .25rem; }
  .table.switch-table th .btn, .table.switch-table th .introjs-button,
  .table.switch-table td .btn,
  .table.switch-table td .introjs-button {
    margin-bottom: 0; }

.switch.btn-outline-primary .switch-handle {
  background-color: #15C042 !important;
  border-color: #15C042 !important; }

.switch.btn-outline-danger .switch-handle {
  background-color: #f44236 !important;
  border-color: #f44236 !important; }

.switch.btn-outline-success .switch-handle {
  background-color: #15C042 !important;
  border-color: #15C042 !important; }

.switch.btn-outline-warning .switch-handle {
  background-color: #f4c22b !important;
  border-color: #f4c22b !important; }

.switch.btn-outline-info .switch-handle {
  background-color: #3ebfea !important;
  border-color: #3ebfea !important; }

.switch.btn-outline-dark .switch-handle {
  background-color: #37474f !important;
  border-color: #37474f !important; }

/**====== Switches css end ======**/
.divider-body {
  border-color: #fff;
  background: #fff; }

.sw .toolbar {
  padding: 1rem 1rem; }

.sw .toolbar > .btn.disabled, .sw .toolbar > .disabled.introjs-button,
.sw .toolbar > .btn:disabled,
.sw .toolbar > .introjs-button:disabled {
  opacity: 0.5; }

.sw > .nav .nav-link {
  padding: 1rem; }

.sw.sw-dark {
  color: rgba(255, 255, 255, 0.95);
  background: #212529; }
  .sw.sw-dark h4 {
    color: rgba(255, 255, 255, 0.95); }
  .sw.sw-dark .form-select,
  .sw.sw-dark .form-control {
    background: #282d31;
    border-color: #2f343a;
    color: rgba(255, 255, 255, 0.95); }
  .sw.sw-dark .form-check-input:not(:checked[type="radio"]) {
    background: #282d31; }
  .sw.sw-dark .divider-body {
    border-color: #2f343a !important;
    background: #2f343a; }

.sw.sw-loading::before {
  border-color: #dee2e6;
  border-top-color: 10px solid #0d6efd; }

/* SmartWizard Theme: Default */
.sw-theme-default {
  border: 1px solid #dee2e6; }
  .sw-theme-default > .tab-content > .tab-pane {
    padding: 1rem 1rem; }
  .sw-theme-default .toolbar > .btn, .sw-theme-default .toolbar > .introjs-button {
    color: #fff;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-weight: 400; }
  .sw-theme-default > .nav {
    box-shadow: none !important;
    border-bottom: 1px solid #dee2e6; }
    .sw-theme-default > .nav .nav-link::after {
      background: #fff; }
    .sw-theme-default > .nav .nav-link.inactive {
      color: #6c757d; }
    .sw-theme-default > .nav .nav-link.active {
      color: #0d6efd !important; }
      .sw-theme-default > .nav .nav-link.active::after {
        background: #0d6efd !important; }
    .sw-theme-default > .nav .nav-link.done {
      color: #0d6efd !important; }
      .sw-theme-default > .nav .nav-link.done::after {
        background: #cfe2ff; }
    .sw-theme-default > .nav .nav-link.danger {
      color: #dc3545 !important; }
      .sw-theme-default > .nav .nav-link.danger::after {
        background: #dc3545; }
  .sw-theme-default.sw-dark > .nav {
    box-shadow: none !important;
    border-bottom: 1px solid #dee2e6; }
    .sw-theme-default.sw-dark > .nav .nav-link.inactive {
      color: rgba(255, 255, 255, 0.5); }
    .sw-theme-default.sw-dark > .nav .nav-link.active {
      color: #fff !important; }
      .sw-theme-default.sw-dark > .nav .nav-link.active::after {
        background: #0d6efd !important; }
    .sw-theme-default.sw-dark > .nav .nav-link.done {
      color: rgba(255, 255, 255, 0.75) !important; }
      .sw-theme-default.sw-dark > .nav .nav-link.done::after {
        background: rgba(255, 255, 255, 0.75); }

/* SmartWizard Theme: Arrows */
.sw-theme-arrows {
  border: 1px solid #dee2e6; }
  .sw-theme-arrows > .tab-content > .tab-pane {
    padding: 1rem 1rem; }
  .sw-theme-arrows .toolbar > .btn, .sw-theme-arrows .toolbar > .introjs-button {
    color: #fff;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-weight: 400; }
  .sw-theme-arrows > .nav {
    border-bottom: 1px solid #dee2e6;
    background-color: #fff; }
    .sw-theme-arrows > .nav .nav-link {
      padding-left: 40px; }
      .sw-theme-arrows > .nav .nav-link::after {
        border-left-color: #fff; }
      .sw-theme-arrows > .nav .nav-link::before {
        border-left-color: #fff; }
      .sw-theme-arrows > .nav .nav-link.inactive {
        color: #6c757d;
        border-color: #fff;
        background: #fff; }
      .sw-theme-arrows > .nav .nav-link.active {
        color: #ffffff;
        border-color: #0d6efd;
        background: #0d6efd; }
        .sw-theme-arrows > .nav .nav-link.active::after {
          border-left-color: #0d6efd; }
      .sw-theme-arrows > .nav .nav-link.done {
        color: #0d6efd;
        border-color: #cfe2ff;
        background: #cfe2ff; }
        .sw-theme-arrows > .nav .nav-link.done::after {
          border-left-color: #cfe2ff; }
      .sw-theme-arrows > .nav .nav-link.danger {
        color: #ffffff;
        border-color: #dc3545;
        background: #dc3545; }
        .sw-theme-arrows > .nav .nav-link.danger::after {
          border-left-color: #dc3545; }
  .sw-theme-arrows.sw-dark {
    color: rgba(255, 255, 255, 0.95);
    background: #212529; }
    .sw-theme-arrows.sw-dark > .nav {
      border-bottom: 1px solid #383f45;
      background-color: #383f45; }
      .sw-theme-arrows.sw-dark > .nav .nav-link::after {
        border-left: 30px solid #212529; }
      .sw-theme-arrows.sw-dark > .nav .nav-link::before {
        border-left: 30px solid #212529; }
      .sw-theme-arrows.sw-dark > .nav .nav-link.inactive {
        color: white;
        border-color: #383f45;
        background: #383f45; }
        .sw-theme-arrows.sw-dark > .nav .nav-link.inactive::after {
          border-left-color: #383f45; }
      .sw-theme-arrows.sw-dark > .nav .nav-link.active {
        color: white;
        border-color: #212529;
        background: #212529; }
        .sw-theme-arrows.sw-dark > .nav .nav-link.active::after {
          border-left-color: #212529; }
      .sw-theme-arrows.sw-dark > .nav .nav-link.done {
        color: white;
        border-color: #282d31;
        background: #282d31; }
        .sw-theme-arrows.sw-dark > .nav .nav-link.done::after {
          border-left-color: #282d31; }
      .sw-theme-arrows.sw-dark > .nav .nav-link.danger {
        color: #ffffff;
        border-color: #dc3545;
        background: #dc3545; }
        .sw-theme-arrows.sw-dark > .nav .nav-link.danger::after {
          border-left-color: #dc3545; }

/* SmartWizard Theme: Dots */
.sw-theme-dots > .tab-content > .tab-pane {
  padding: 1rem 1rem; }

.sw-theme-dots .toolbar > .btn, .sw-theme-dots .toolbar > .introjs-button {
  color: #fff;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 400; }

.sw-theme-dots > .nav {
  margin-bottom: 0; }
  .sw-theme-dots > .nav::before {
    height: 5px;
    background-color: #dee2e6; }
  .sw-theme-dots > .nav .nav-link::before {
    background: #dee2e6;
    color: #0d6efd; }
  .sw-theme-dots > .nav .nav-link.inactive {
    color: #a7acb1; }
    .sw-theme-dots > .nav .nav-link.inactive::after {
      background-color: #e2e3e5; }
  .sw-theme-dots > .nav .nav-link.active {
    color: #0d6efd !important; }
    .sw-theme-dots > .nav .nav-link.active::after {
      background-color: #0d6efd !important; }
  .sw-theme-dots > .nav .nav-link.done {
    color: #6c757d; }
    .sw-theme-dots > .nav .nav-link.done::after {
      background-color: #6c757d; }
  .sw-theme-dots > .nav .nav-link.danger {
    color: #dc3545; }
    .sw-theme-dots > .nav .nav-link.danger::after {
      background-color: #dc3545; }

.sw-theme-dots.sw-dark {
  color: rgba(255, 255, 255, 0.5);
  background: #212529; }
  .sw-theme-dots.sw-dark > .nav::before {
    background-color: #2f343a; }
  .sw-theme-dots.sw-dark > .nav .nav-link::before {
    background: #2f343a;
    color: black; }
  .sw-theme-dots.sw-dark > .nav .nav-link.inactive {
    color: rgba(255, 255, 255, 0.5); }
    .sw-theme-dots.sw-dark > .nav .nav-link.inactive::after {
      background-color: #7b838a; }
  .sw-theme-dots.sw-dark > .nav .nav-link.active {
    color: #fff !important; }
    .sw-theme-dots.sw-dark > .nav .nav-link.active::after {
      background-color: #0d6efd !important; }
  .sw-theme-dots.sw-dark > .nav .nav-link.done {
    color: rgba(255, 255, 255, 0.85); }
    .sw-theme-dots.sw-dark > .nav .nav-link.done::after {
      background-color: #6ea8fe; }

/* SmartWizard Theme: Progress */
.sw-theme-progress {
  border: 1px solid #dee2e6; }
  .sw-theme-progress > .tab-content > .tab-pane {
    padding: 1rem 1rem; }
  .sw-theme-progress .toolbar > .btn, .sw-theme-progress .toolbar > .introjs-button {
    color: #fff;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-weight: 400; }
  .sw-theme-progress > .nav {
    box-shadow: none !important;
    border-bottom: 1px solid #dee2e6; }
    .sw-theme-progress > .nav .nav-link::after {
      background: #fff; }
    .sw-theme-progress > .nav .nav-link.inactive {
      color: #6c757d; }
    .sw-theme-progress > .nav .nav-link.active {
      color: #ffffff !important; }
      .sw-theme-progress > .nav .nav-link.active::after {
        background-color: #0d6efd;
        width: 100%; }
    .sw-theme-progress > .nav .nav-link.done {
      color: #ffffff !important; }
      .sw-theme-progress > .nav .nav-link.done::after {
        background: #0d6efd; }
    .sw-theme-progress > .nav .nav-link.danger {
      color: #ffffff !important;
      cursor: pointer; }
      .sw-theme-progress > .nav .nav-link.danger::after {
        background: #dc3545; }
  .sw-theme-progress.sw-dark {
    color: rgba(255, 255, 255, 0.5); }
    .sw-theme-progress.sw-dark > .nav {
      background: #2f343a;
      border-bottom-color: #2f343a; }
      .sw-theme-progress.sw-dark > .nav .nav-link.active {
        color: white; }
        .sw-theme-progress.sw-dark > .nav .nav-link.active::after {
          background-color: #212529; }
      .sw-theme-progress.sw-dark > .nav .nav-link.done {
        color: #ffffff !important; }
        .sw-theme-progress.sw-dark > .nav .nav-link.done::after {
          background: #212529; }

.tabcontrol,
.wizard {
  display: block;
  width: 100%;
  overflow: hidden; }

.tabcontrol a,
.wizard a {
  outline: 0; }

.tabcontrol ul,
.wizard ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.tabcontrol ul > li,
.wizard ul > li {
  display: block;
  padding: 0; }

/* Accessibility */
.tabcontrol > .content > .title,
.tabcontrol > .steps .current-info,
.wizard > .content > .title,
.wizard > .steps .current-info {
  position: absolute;
  left: -999em; }

/*
    Wizard
*/
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%; }

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%; }

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em;
  width: 65%; }

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%; }

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em; }

.wizard > .steps .number {
  font-size: 1.429em; }

.wizard > .steps > ul > li {
  width: 25%;
  float: left; }

.wizard > .actions > ul > li {
  float: left; }

.wizard > .steps a {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .steps a:active, .wizard > .steps a:hover {
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }

.wizard > .steps .disabled a {
  background: #fff;
  color: #212529;
  cursor: default;
  opacity: 0.6; }
  .wizard > .steps .disabled a:active, .wizard > .steps .disabled a:hover {
    background: #fff;
    color: #212529;
    cursor: default; }

.wizard > .steps .current a {
  background: #0d6efd;
  color: #fff;
  cursor: default; }
  .wizard > .steps .current a:active, .wizard > .steps .current a:hover {
    background: #0d6efd;
    color: #fff;
    cursor: default; }

.wizard > .steps .done a {
  background: rgba(13, 110, 253, 0.2);
  color: #0d6efd; }
  .wizard > .steps .done a:active, .wizard > .steps .done a:hover {
    background: rgba(13, 110, 253, 0.2);
    color: #0d6efd; }

.wizard > .steps .error a {
  background: #dc3545;
  color: #fff; }
  .wizard > .steps .error a:active, .wizard > .steps .error a:hover {
    background: #dc3545;
    color: #fff; }

.wizard > .content {
  background: white;
  display: block;
  margin: 0.5em;
  min-height: 8em;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: 5px; }

.wizard > .content > .body {
  padding: 25px; }
  .wizard > .content > .body ul {
    list-style: disc !important; }
    .wizard > .content > .body ul > li {
      display: list-item; }
  .wizard > .content > .body > iframe {
    border: 0 none;
    width: 100%;
    height: 100%; }
  .wizard > .content > .body input.error {
    background: #fbe3e4;
    border-color: #dc3545;
    color: #dc3545; }
  .wizard > .content > .body label {
    display: inline-block;
    margin-bottom: 0.5em; }
    .wizard > .content > .body label.error {
      color: #dc3545;
      display: inline-block;
      margin-left: 1.5em; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }

.wizard > .actions > ul {
  display: inline-block;
  text-align: right; }
  .wizard > .actions > ul > li {
    margin: 0 0.5em; }

.wizard > .actions a {
  background: #0d6efd;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .actions a:active, .wizard > .actions a:hover {
    background: #0d6efd;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    border-radius: 5px; }

.wizard > .actions .disabled a {
  background: #fff;
  color: #aaa; }
  .wizard > .actions .disabled a:active, .wizard > .actions .disabled a:hover {
    background: #fff;
    color: #aaa; }

@media (max-width: 768px) {
  .wizard .steps > ul > li {
    width: 100%; }
  .wizard.vertical > .steps,
  .wizard.vertical > .content {
    width: 100%; } }

.pc-wizard.wizard .number {
  display: none; }

.pc-wizard.wizard > .steps a {
  display: flex;
  align-items: center;
  margin: 0.5em;
  position: relative; }
  .pc-wizard.wizard > .steps a:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 20px;
    opacity: 0.5; }
  .pc-wizard.wizard > .steps a span b {
    display: block; }
  .pc-wizard.wizard > .steps a .num-icon {
    width: 35px;
    height: 35px;
    border-radius: 0.25rem;
    margin-right: 10px;
    background: #fff;
    color: #212529;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .pc-wizard.wizard > .steps a.disabled:hover {
    background: transparent; }
  .pc-wizard.wizard > .steps a:hover {
    display: flex; }

.pc-wizard.wizard > .steps > ul > li:last-child a:after {
  visibility: hidden; }

.pc-wizard.wizard > .steps .disabled a {
  background: transparent; }

.pc-wizard.wizard > .steps .current a {
  background: transparent;
  color: inherit; }
  .pc-wizard.wizard > .steps .current a .num-icon {
    background: #0d6efd;
    color: #fff; }

.pc-wizard.wizard > .steps .done a {
  background: transparent; }
  .pc-wizard.wizard > .steps .done a .num-icon {
    background: rgba(13, 110, 253, 0.2);
    color: #0d6efd; }
  .pc-wizard.wizard > .steps .done a span {
    color: #212529; }

.pc-wizard.wizard > .content {
  margin: 0;
  border-radius: 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6; }

.pc-wizard.wizard > .actions {
  padding: 15px 25px 10px; }

.pc-wizard.wizard.vertical > .actions,
.pc-wizard.wizard.vertical > .content {
  margin: 0;
  background: white;
  width: 70%;
  border-left: 1px solid #dee2e6; }

.pc-wizard.wizard.vertical > .content {
  padding: 25px 25px 0;
  border-radius: 0 0.25rem 0 0; }

.pc-wizard.wizard.vertical .actions {
  padding: 25px;
  border-radius: 0 0 0.25rem 0; }

.pc-wizard.wizard.vertical > .steps {
  padding: 35px 10px; }
  .pc-wizard.wizard.vertical > .steps a:after {
    content: "";
    right: 10px; }
  .pc-wizard.wizard.vertical > .steps > ul > li:last-child a:after {
    visibility: visible; }

@media (max-width: 768px) {
  .pc-wizard.wizard > .steps a:after {
    right: 15px; }
  .pc-wizard.wizard > .steps > ul > li:last-child a:after {
    visibility: visible; }
  .pc-wizard.wizard.vertical > .steps {
    padding: 0; }
  .pc-wizard.wizard.vertical > .content {
    padding: 0; }
  .pc-wizard.wizard.vertical > .actions,
  .pc-wizard.wizard.vertical > .content {
    width: 100%; } }

.pc-tab-control.wizard .number {
  display: none; }

.pc-tab-control.wizard > .steps a {
  display: flex;
  align-items: center;
  margin: 0.5em 0.5em 0;
  padding: 1em 1.6em;
  border-radius: 0.25rem 0.25rem 0 0;
  position: relative;
  background: rgba(255, 255, 255, 0.4); }
  .pc-tab-control.wizard > .steps a span {
    color: #212529; }
    .pc-tab-control.wizard > .steps a span b {
      display: block; }
  .pc-tab-control.wizard > .steps a .num-icon {
    width: 35px;
    height: 35px;
    border-radius: 0.25rem;
    margin-right: 10px;
    background: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .pc-tab-control.wizard > .steps a .num-icon .material-icons-two-tone {
      background-color: #212529; }
  .pc-tab-control.wizard > .steps a.disabled:hover {
    background: transparent; }
  .pc-tab-control.wizard > .steps a:hover {
    display: flex; }

.pc-tab-control.wizard > .steps .done a:active,
.pc-tab-control.wizard > .steps .done a:hover {
  background: rgba(255, 255, 255, 0.4); }

.pc-tab-control.wizard > .steps > ul > li:last-child a {
  margin-right: 0; }

.pc-tab-control.wizard > .steps > ul > li:first-child a {
  margin-left: 0; }

.pc-tab-control.wizard > .steps > ul > li:last-child a:after {
  visibility: hidden; }

.pc-tab-control.wizard > .steps .disabled a {
  background: transparent; }

.pc-tab-control.wizard > .steps .current a {
  background: #fff;
  color: inherit; }
  .pc-tab-control.wizard > .steps .current a .num-icon {
    background: #0d6efd;
    color: #fff; }
    .pc-tab-control.wizard > .steps .current a .num-icon .material-icons-two-tone {
      background-color: #fff; }

.pc-tab-control.wizard > .content {
  margin: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  background: #fff;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.8);
  margin-bottom: 30px; }

.pc-tab-control.wizard > .actions {
  padding: 15px 25px 10px; }

@media (max-width: 768px) {
  .pc-tab-control.wizard > .steps a {
    margin: 0;
    border-radius: 0.25rem; } }

.wizard-step .error {
  color: #dc3545; }

.progress {
  height: 6px; }
  .progress .ui-progressbar-value {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 6px 6px;
    background-color: #0d6efd;
    animation: 1s linear infinite progress-bar-stripes; }

.step-app > .step-steps {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden; }
  .step-app > .step-steps > li {
    list-style: none;
    flex: 1;
    cursor: pointer;
    display: block;
    padding: 20px 25px;
    background: #fff;
    color: #212529;
    text-decoration: none;
    border-radius: 0.25rem; }
    .step-app > .step-steps > li + li {
      margin-left: 15px; }
    .step-app > .step-steps > li:hover {
      background-color: #cfe2ff;
      color: #0d6efd; }
    .step-app > .step-steps > li.active {
      background-color: #0d6efd;
      color: #fff; }
    .step-app > .step-steps > li.error {
      background-color: #f8d7da;
      color: #dc3545; }
    .step-app > .step-steps > li.done {
      background-color: #cfe2ff;
      color: #0d6efd; }
    .step-app > .step-steps > li > .number {
      background: #fff;
      padding: 0 8px;
      display: inline-block;
      text-align: center;
      margin-right: 15px;
      border-radius: 3px;
      color: #333; }

.step-app > .step-content {
  padding: 20px 25px;
  background: white;
  border-radius: 0.25rem;
  margin-top: 15px; }
  .step-app > .step-content > .step-tab-panel {
    display: none; }
    .step-app > .step-content > .step-tab-panel.active {
      display: block; }

.step-app > .step-footer {
  margin-top: 15px;
  margin-bottom: 15px; }

/**  =====================
      Chart css start
==========================  **/
.nvtooltip {
  position: fixed !important; }

.peity-chart + .peity {
  width: 100%;
  height: 250px; }

/* Radial Chart Start */
.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box; }
  .radial-bar:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px;
    content: attr(data-label);
    background-color: #fff;
    z-index: 55; }
  .radial-bar > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    width: 56px;
    height: 56px;
    margin-left: 12px;
    margin-top: 12px;
    line-height: 56px; }
  .radial-bar > img {
    z-index: 102; }
  .radial-bar.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-50 {
    background-image: linear-gradient(270deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-55 {
    background-image: linear-gradient(288deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-60 {
    background-image: linear-gradient(306deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-65 {
    background-image: linear-gradient(324deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-70 {
    background-image: linear-gradient(342deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-75 {
    background-image: linear-gradient(360deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-80 {
    background-image: linear-gradient(378deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-85 {
    background-image: linear-gradient(396deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-90 {
    background-image: linear-gradient(414deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-95 {
    background-image: linear-gradient(432deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-100 {
    background-image: linear-gradient(450deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-50 {
    background-image: linear-gradient(270deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-55 {
    background-image: linear-gradient(288deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-60 {
    background-image: linear-gradient(306deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-65 {
    background-image: linear-gradient(324deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-70 {
    background-image: linear-gradient(342deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-75 {
    background-image: linear-gradient(360deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-80 {
    background-image: linear-gradient(378deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-85 {
    background-image: linear-gradient(396deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-90 {
    background-image: linear-gradient(414deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-95 {
    background-image: linear-gradient(432deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-primary.radial-bar-100 {
    background-image: linear-gradient(450deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-50 {
    background-image: linear-gradient(270deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-55 {
    background-image: linear-gradient(288deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-60 {
    background-image: linear-gradient(306deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-65 {
    background-image: linear-gradient(324deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-70 {
    background-image: linear-gradient(342deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-75 {
    background-image: linear-gradient(360deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-80 {
    background-image: linear-gradient(378deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-85 {
    background-image: linear-gradient(396deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-90 {
    background-image: linear-gradient(414deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-95 {
    background-image: linear-gradient(432deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-danger.radial-bar-100 {
    background-image: linear-gradient(450deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-50 {
    background-image: linear-gradient(270deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-55 {
    background-image: linear-gradient(288deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-60 {
    background-image: linear-gradient(306deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-65 {
    background-image: linear-gradient(324deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-70 {
    background-image: linear-gradient(342deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-75 {
    background-image: linear-gradient(360deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-80 {
    background-image: linear-gradient(378deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-85 {
    background-image: linear-gradient(396deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-90 {
    background-image: linear-gradient(414deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-95 {
    background-image: linear-gradient(432deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-success.radial-bar-100 {
    background-image: linear-gradient(450deg, #15C042 50%, transparent 50%, transparent), linear-gradient(270deg, #15C042 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-50 {
    background-image: linear-gradient(270deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-55 {
    background-image: linear-gradient(288deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-60 {
    background-image: linear-gradient(306deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-65 {
    background-image: linear-gradient(324deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-70 {
    background-image: linear-gradient(342deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-75 {
    background-image: linear-gradient(360deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-80 {
    background-image: linear-gradient(378deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-85 {
    background-image: linear-gradient(396deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-90 {
    background-image: linear-gradient(414deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-95 {
    background-image: linear-gradient(432deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-warning.radial-bar-100 {
    background-image: linear-gradient(450deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-50 {
    background-image: linear-gradient(270deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-55 {
    background-image: linear-gradient(288deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-60 {
    background-image: linear-gradient(306deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-65 {
    background-image: linear-gradient(324deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-70 {
    background-image: linear-gradient(342deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-75 {
    background-image: linear-gradient(360deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-80 {
    background-image: linear-gradient(378deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-85 {
    background-image: linear-gradient(396deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-90 {
    background-image: linear-gradient(414deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-95 {
    background-image: linear-gradient(432deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-info.radial-bar-100 {
    background-image: linear-gradient(450deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-0 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-5 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-10 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-15 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-20 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-25 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-30 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-35 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-40 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-45 {
    background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-50 {
    background-image: linear-gradient(270deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-55 {
    background-image: linear-gradient(288deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-60 {
    background-image: linear-gradient(306deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-65 {
    background-image: linear-gradient(324deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-70 {
    background-image: linear-gradient(342deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-75 {
    background-image: linear-gradient(360deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-80 {
    background-image: linear-gradient(378deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-85 {
    background-image: linear-gradient(396deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-90 {
    background-image: linear-gradient(414deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-95 {
    background-image: linear-gradient(432deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }
  .radial-bar.radial-bar-dark.radial-bar-100 {
    background-image: linear-gradient(450deg, #37474f 50%, transparent 50%, transparent), linear-gradient(270deg, #37474f 50%, #d6d6d6 50%, #d6d6d6); }

.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px; }
  .radial-bar-lg > img, .radial-bar-lg:after {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-top: 15px;
    line-height: 70px; }

.radial-bar-sm {
  width: 60px;
  height: 60px;
  font-size: 12px; }
  .radial-bar-sm > img, .radial-bar-sm:after {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 10px;
    line-height: 42px; }

.radial-bar-xs {
  width: 35px;
  height: 35px;
  font-size: 10px; }
  .radial-bar-xs > img, .radial-bar-xs:after {
    width: 25px;
    height: 25px;
    margin-left: 5.5px;
    margin-top: 4.5px;
    line-height: 25px; }

/* Radial Chart End */
/**====== Chart css end ======**/
/**  =====================
      Icon layouts css start
==========================  **/
.i-main .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #f1f1f1;
  position: relative;
  cursor: pointer; }
  .i-main .i-block i {
    font-size: 30px; }
  .i-main .i-block label {
    margin-bottom: 0;
    display: none; }
  .i-main .i-block span.ic-badge {
    position: absolute;
    bottom: 0;
    right: 0; }
  .i-main .i-block .flag-icon-background {
    width: 40px;
    height: 40px; }

/**====== Icon layouts css end ======**/
/**  =====================
      Calendar css start
==========================  **/
.external-events .fc-event {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 5px;
  background: #15C042;
  border-color: #15C042; }

.calendar a:not([href]):not([tabindex]),
.calendar a:not([href]):not([tabindex]):focus,
.calendar a:not([href]):not([tabindex]):hover {
  color: #fff; }

.calendar .fc-event,
.calendar .fc-event:hover {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 2px;
  background: #15C042;
  border-color: #15C042; }

/**====== Calendar css end ======**/
/**  =====================
      File Upload css start
==========================  **/
.dropzone {
  min-height: 150px;
  border: 1px solid rgba(42, 42, 42, 0.05);
  background: rgba(204, 204, 204, 0.15);
  padding: 20px;
  border-radius: 5px;
  box-shadow: inset 0 0 5px 0 rgba(43, 43, 43, 0.1); }
  .dropzone.dz-clickable {
    cursor: pointer; }
    .dropzone.dz-clickable * {
      cursor: default; }
    .dropzone.dz-clickable .dz-message {
      cursor: pointer; }
      .dropzone.dz-clickable .dz-message * {
        cursor: pointer; }
  .dropzone.dz-started .dz-message {
    display: none; }
  .dropzone.dz-drag-hover {
    border-style: solid; }
    .dropzone.dz-drag-hover .dz-message {
      opacity: 0.5; }
  .dropzone .dz-message {
    text-align: center;
    margin: 4em 0;
    color: #888;
    font-size: 18px; }
  .dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0.5rem;
    min-height: 100px;
    box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px; }
    .dropzone .dz-preview:hover {
      z-index: 1000; }
      .dropzone .dz-preview:hover .dz-details {
        opacity: 1; }
    .dropzone .dz-preview.dz-file-preview .dz-image {
      border-radius: 5px;
      background: #999;
      background: linear-gradient(to bottom, #eee, #ddd); }
    .dropzone .dz-preview.dz-file-preview .dz-details {
      opacity: 1; }
    .dropzone .dz-preview.dz-image-preview {
      background: white; }
      .dropzone .dz-preview.dz-image-preview .dz-details {
        transition: opacity 0.2s linear; }
    .dropzone .dz-preview .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none; }
      .dropzone .dz-preview .dz-remove:hover {
        text-decoration: underline; }
    .dropzone .dz-preview:hover .dz-details {
      opacity: 1; }
    .dropzone .dz-preview .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%; }
      .dropzone .dz-preview .dz-details .dz-size {
        margin-bottom: 1em;
        font-size: 16px; }
      .dropzone .dz-preview .dz-details .dz-filename {
        white-space: nowrap; }
        .dropzone .dz-preview .dz-details .dz-filename:hover span {
          border: 1px solid rgba(200, 200, 200, 0.8);
          background-color: rgba(255, 255, 255, 0.8); }
        .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis; }
          .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
            border: 1px solid transparent; }
        .dropzone .dz-preview .dz-details .dz-filename span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px; }
      .dropzone .dz-preview .dz-details .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px; }
    .dropzone .dz-preview:hover .dz-image img {
      transform: scale(1.05, 1.05);
      filter: blur(8px); }
    .dropzone .dz-preview .dz-image {
      border-radius: 5px;
      overflow: hidden;
      width: 120px;
      height: 120px;
      position: relative;
      display: block;
      z-index: 10; }
      .dropzone .dz-preview .dz-image img {
        display: block;
        display: block;
        padding: 4px;
        width: 100%; }
    .dropzone .dz-preview.dz-success .dz-success-mark {
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview.dz-error .dz-error-mark {
      opacity: 1;
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview .dz-error-mark,
    .dropzone .dz-preview .dz-success-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px; }
    .dropzone .dz-preview .dz-error-mark svg,
    .dropzone .dz-preview .dz-success-mark svg {
      display: block;
      width: 54px;
      height: 54px; }
    .dropzone .dz-preview.dz-processing .dz-progress {
      opacity: 1;
      transition: all 0.2s linear; }
    .dropzone .dz-preview.dz-complete .dz-progress {
      opacity: 0;
      transition: opacity 0.4s ease-in; }
    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
      animation: pulse 6s ease infinite; }
    .dropzone .dz-preview .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      -webkit-transform: scale(1);
      border-radius: 5px;
      overflow: hidden; }
      .dropzone .dz-preview .dz-progress .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out; }
    .dropzone .dz-preview.dz-error .dz-error-message {
      display: block; }
    .dropzone .dz-preview.dz-error:hover .dz-error-message {
      opacity: 1;
      pointer-events: auto; }
    .dropzone .dz-preview .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 5px;
      font-size: 13px;
      top: 130px;
      left: -10px;
      width: 140px;
      background: #f44236;
      background: linear-gradient(to bottom, #f44236, #f44236);
      padding: 0.5em 1.2em;
      color: white; }
      .dropzone .dz-preview .dz-error-message:after {
        content: '';
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #f44236; }

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px); }
  30%,
  70% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-40px); } }

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px); }
  30% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  10% {
    transform: scale(1.1); }
  20% {
    transform: scale(1); } }

/**====== File Upload css end ======**/
.swal-icon--error {
  border-color: #f44236; }

.swal-icon--error__line {
  background-color: #f44236; }

.swal-icon--warning {
  border-color: #f4c22b; }

.swal-icon--warning__body {
  background-color: #f4c22b; }

.swal-icon--warning__dot {
  background-color: #f4c22b; }

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #f5c943; }
  to {
    border-color: #f4c22b; } }

@keyframes pulseWarning {
  0% {
    border-color: #f5c943; }
  to {
    border-color: #f4c22b; } }

.swal-icon--success {
  border-color: #15C042; }

.swal-icon--success__ring {
  border: 4px solid rgba(21, 192, 66, 0.2); }

.swal-icon--success__line {
  background-color: #15C042; }

.swal-icon--info {
  border-color: #3ebfea; }
  .swal-icon--info:after, .swal-icon--info:before {
    background-color: #3ebfea; }

.swal-title {
  color: rgba(0, 0, 0, 0.65); }

.swal-text {
  color: rgba(0, 0, 0, 0.64); }

.swal-button {
  background-color: #15C042;
  margin: 0 10px; }
  .swal-button:not([disabled]):hover {
    background-color: #12a93a; }
  .swal-button:active {
    background-color: #12a93a; }
  .swal-button:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(21, 192, 66, 0.29); }

.swal-button--cancel {
  color: #555;
  background-color: #efefef;
  margin: 0 10px; }
  .swal-button--cancel:not([disabled]):hover {
    background-color: #e8e8e8; }
  .swal-button--cancel:active {
    background-color: #d7d7d7; }
  .swal-button--cancel:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(116, 136, 150, 0.29); }

.swal-button--danger {
  background-color: #f44236;
  margin: 0 10px; }
  .swal-button--danger:not([disabled]):hover {
    background-color: #f32b1e; }
  .swal-button--danger:active {
    background-color: #f32b1e; }
  .swal-button--danger:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(244, 66, 54, 0.29); }

.swal-footer {
  text-align: center; }

.swal-content__input:focus {
  border-color: rgba(21, 192, 66, 0.29); }

.swal-content__textarea:focus {
  border-color: rgba(21, 192, 66, 0.29); }

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4); }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #15C042 !important; }

.swal2-loader {
  border-color: #15C042 transparent #15C042 transparent !important; }

.swal2-styled {
  padding: 10px 20px  !important;
  border-radius: 0.25rem !important;
  font-size: 14px !important; }
  .swal2-styled.swal2-confirm {
    background-color: #15C042 !important; }
  .swal2-styled.swal2-deny {
    background-color: #f44236 !important; }
  .swal2-styled:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(21, 192, 66, 0.4) !important; }

.swal2-close:hover {
  color: #f44236 !important; }

.swal2-content {
  color: #545454; }

.swal2-file,
.swal2-input,
.swal2-textarea {
  border: 1px solid #d9d9d9; }

.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f44236 !important;
  box-shadow: 0 0 2px #f44236 !important; }

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  box-shadow: 0 0 3px #c4e6f5; }

.swal2-validation-message {
  background: #f0f0f0;
  color: #666; }
  .swal2-validation-message::before {
    background-color: #f44236; }

.swal2-icon.swal2-error {
  border-color: #f8867f !important;
  color: #f44236 !important; }
  .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: #f44236 !important; }

.swal2-icon.swal2-warning {
  border-color: #f8d774 !important;
  color: #f4c22b !important; }

.swal2-icon.swal2-info {
  border-color: #83d6f2 !important;
  color: #3ebfea !important; }

.swal2-icon.swal2-question {
  border-color: #cfc1e8 !important;
  color: #a389d4 !important; }

.swal2-icon.swal2-success {
  border-color: #38e967 !important;
  color: #15C042 !important; }
  .swal2-icon.swal2-success .swal2-success-ring {
    border: 0.25em solid rgba(21, 192, 66, 0.3) !important; }
  .swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #15C042 !important; }

.swal2-progress-steps .swal2-progress-step {
  background: #15C042;
  color: #fff; }
  .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
    background: #15C042; }
    .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
      background: #3ebfea;
      color: #fff; }
    .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
      background: #3ebfea; }

.swal2-progress-steps .swal2-progress-step-line {
  background: #15C042; }

.swal2-actions .btn, .swal2-actions .introjs-button {
  margin: 0 10px; }

/**  =====================
      other css start
==========================  **/
.validate-me .error {
  border-color: #dc3545; }

.error-message {
  color: #dc3545; }

.latest-update-card .card-body {
  padding-top: 0; }

.latest-update-card .card-body .latest-update-box {
  position: relative; }

.latest-update-card .card-body .latest-update-box:after {
  content: "";
  position: absolute;
  background: #ebebeb;
  height: 100%;
  width: 3px;
  top: 0;
  left: 11px;
  z-index: 1; }

.latest-update-card .card-body .latest-update-box .update-meta {
  z-index: 2; }

.latest-update-card .card-body .latest-update-box .update-meta .update-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding: 7px 8px;
  font-size: 16px;
  color: #fff; }

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.ring {
  border-width: 3px solid #04a9f5;
  width: 15px;
  height: 15px;
  padding: 0;
  display: block;
  background: #fff; }

.latest-update-card .card-body .latest-update-box .update-meta i.update-icon {
  margin-left: 4px;
  margin-right: 4px; }

.latest-update-card .card-body .latest-update-box .update-meta img.update-icon {
  padding: 0;
  width: 40px;
  height: 40px; }

.latest-update-card.update-card .card-body .latest-update-box:after {
  left: 19px; }

/**  =====================
Help Desk Application css start
==========================  **/
.hd-body .excerpt {
  padding: 15px;
  background: #eff3f6;
  border: 1px solid #e2e5e8; }

.hd-body .ticket-customer,
.hd-body .ticket-type-icon {
  display: block;
  text-transform: capitalize; }

.hd-body:hover .hover-blk {
  -webkit-transform: scale(1);
  transform: scale(1); }

.sm-view .card-body .excerpt,
.sm-view .card-body ul.list-inline {
  display: none; }

.md-view .excerpt {
  display: none; }

.md-view .col-auto ul li:nth-child(1),
.md-view .col-auto ul li:nth-child(3),
.sm-view .col-auto ul li:nth-child(1),
.sm-view .col-auto ul li:nth-child(3) {
  display: none; }

.hover-blk {
  background: #fff;
  width: 270px;
  left: calc(100% + 10px);
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 5;
  transition: all 0.1s ease-out; }

.hover-blk .img-txt p {
  display: inline-block; }

.topic-name h1 {
  float: left;
  font-weight: normal; }

.topic-name .btn-star {
  float: right; }

.dicon-blk {
  top: 0;
  right: 0; }

.dicon-blk li {
  display: inline-block; }

.hd-detail .col-right .edit-del {
  opacity: 0; }

.hd-detail .col-right .edit-del i {
  opacity: 0.2; }

.hd-detail .col-right .edit-del i:hover {
  opacity: 1; }

.hd-detail:hover .edit-del {
  opacity: 1; }

.hdd-user i {
  bottom: 0; }

.ticket-block .hd-body {
  border-left: 3px solid #ccc; }

.ticket-block .col.border-right {
  border-color: #ccc !important; }

.ticket-block .ticket-type-icon {
  font-weight: 500;
  color: #111; }

.ticket-block .excerpt h6 {
  color: #686c71;
  font-weight: 500; }

.ticket-block .excerpt h6 a {
  color: #686c71; }

.ticket-block .excerpt h6 a:hover {
  text-decoration: underline !important;
  color: #111; }

.ticket-block.open-tic .hd-body {
  border-color: #ea394a; }

.ticket-block.close-tic .hd-body {
  border-color: #5AC17F; }

.right-col .card-footer label {
  font-weight: 500; }

.q-view {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1030;
  top: 0;
  right: 0;
  visibility: hidden; }

.q-view .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.q-view .content {
  width: 585px;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0;
  background-color: #fff;
  padding-top: 20px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
  right: -765px;
  transition: right 0.195s ease-in, visibility 0s linear 0.195s;
  min-height: 100vh;
  overflow: auto; }

.q-view.active {
  visibility: visible; }

.q-view.active .content {
  right: 0;
  transition: right 0.225s ease-out; }

.select2-container--default {
  margin-bottom: 10px; }

.select2-container--default .select2-selection {
  border-color: #e2e5e8; }

.hdd-right-side {
  width: 495px; }

.hdd-right-side .hdd-right-inner {
  position: fixed;
  width: 465px; }

.span-content a {
  padding: 0 7px; }

.file-btn {
  position: relative;
  overflow: hidden; }

.file-btn input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0; }

@media (max-width: 992px) {
  .hd-detail .col-right .edit-del {
    opacity: 1; } }

@media only screen and (max-width: 767px) {
  .hdd-right-side {
    width: 100%; }
  .hdd-right-side .hdd-right-inner {
    position: static;
    width: 100%; } }

@media (max-width: 575px) {
  .q-view .content {
    width: 450px; } }

@media (max-width: 380px) {
  .q-view .content {
    width: 300px; } }

/**====== Help Desk Application css start ======**/
/**====== other css end ======**/
.bootstrap-maxlength:not(.badge) {
  background: #fff;
  margin-top: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.5);
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2); }

.datepicker-view .week {
  color: #0d6efd; }

.datepicker-cell.selected {
  background-color: #0d6efd;
  color: #fff; }
  .datepicker-cell.selected:hover {
    background-color: #0d6efd;
    color: #fff; }

.datepicker-cell.disabled {
  color: #d0f2d9;
  background-color: #fff; }

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: #d0f2d9;
  color: #0d6efd;
  border-radius: 0; }
  .datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: #d0f2d9;
    color: #0d6efd; }
  .datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: #0d6efd;
    color: #fff; }

.datepicker-input.in-edit {
  border-color: #0d6efd; }

.modal-lightbox .modal-body {
  border: 4px solid #fff;
  border-radius: 4px; }

/* nav */
.tns-controls {
  text-align: center;
  margin-bottom: 10px; }
  .tns-controls [aria-controls] {
    font-size: 15px;
    margin: 0 5px;
    padding: 0 1em;
    height: 2.5em;
    color: #fff;
    background: #0d6efd;
    border-radius: 3px;
    border: 0; }

.tns-controls [disabled] {
  color: #999999;
  background: #B3B3B3;
  cursor: not-allowed !important; }

.tns-nav {
  text-align: center;
  margin: 10px 0; }
  .tns-nav > [aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    background: #ddd;
    border: 0; }
  .tns-nav > .tns-nav-active {
    background: #999; }

.thumbnails {
  margin: 20px 0;
  text-align: center; }
  .thumbnails li {
    display: inline-block;
    cursor: pointer;
    border: 5px solid #fff; }
  .thumbnails .tns-nav-active {
    background: none;
    border-color: #0d6efd; }
  .thumbnails img {
    height: auto;
    width: 46px; }

.customize-tools {
  position: relative; }

.controls {
  text-align: center; }
  .controls li {
    display: block;
    position: absolute;
    top: 50%;
    height: 60px;
    line-height: 60px;
    margin-top: -30px;
    padding: 0 15px;
    cursor: pointer;
    font-size: 30px;
    transition: background 0.3s; }
  .controls .prev {
    left: 0; }
  .controls .next {
    right: 0; }
  .controls li:hover {
    background: #f2f2f2; }

.vtree a.vtree-leaf-label {
  color: #212529; }
  .vtree a.vtree-leaf-label:hover, .vtree a.vtree-leaf-label:focus, .vtree a.vtree-leaf-label:active {
    color: #0d6efd; }

.typeahead {
  position: relative; }
  .typeahead > ul {
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.17);
    display: none;
    float: left;
    font-size: 14px;
    left: 0;
    list-style: none;
    margin: 2px 0 0;
    min-width: 170px;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000; }
    .typeahead > ul > li > a {
      clear: both;
      color: #333;
      cursor: default;
      display: block;
      font-weight: 400;
      line-height: 1.42857143;
      padding: 3px 20px;
      white-space: nowrap; }
    .typeahead > ul > li.active > a,
    .typeahead > ul > li > a:hover {
      background-color: #3F9BFA;
      color: #FFFFFF;
      text-decoration: none; }

.introjs-button {
  text-shadow: none !important;
  margin-right: 0 !important; }

/**  =====================
      user card css start
==========================  **/
.user-card .dropdown-toggle {
  cursor: pointer; }

.user-card .cover-img-block {
  position: relative; }
  .user-card .cover-img-block > img {
    width: 100%; }
  .user-card .cover-img-block .change-cover {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
    opacity: 0;
    transition: all 0.3s ease-in-out; }
    .user-card .cover-img-block .change-cover .dropdown-toggle {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-card .cover-img-block .change-cover .dropdown-toggle:after {
        display: none; }
  .user-card .cover-img-block .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out; }
  .user-card .cover-img-block:hover .change-cover,
  .user-card .cover-img-block:hover .overlay {
    opacity: 1; }

.user-card .user-about-block {
  margin-top: -20px;
  z-index: 1;
  position: relative; }
  .user-card .user-about-block img {
    box-shadow: 0 0 0 5px #fff; }
  .user-card .user-about-block .certificated-badge {
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 5px 3px; }
    .user-card .user-about-block .certificated-badge .bg-icon {
      font-size: 22px; }
    .user-card .user-about-block .certificated-badge .front-icon {
      font-size: 11px;
      position: absolute;
      top: 11px;
      left: 9px; }

.user-card .hover-data {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 46, 62, 0.92);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.3s ease-in-out; }

.user-card:hover .hover-data {
  transform: scale(1); }

.user-card .change-profile .dropdown-toggle::after {
  display: none; }

.user-card .change-profile .profile-dp {
  position: relative;
  overflow: hidden;
  padding: 5px;
  width: 110px;
  height: 110px;
  border-radius: 50%; }
  .user-card .change-profile .profile-dp .overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50%;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out; }
    .user-card .change-profile .profile-dp .overlay span {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0 0 5px; }
  .user-card .change-profile .profile-dp:hover .overlay {
    opacity: 1; }

@media only screen and (max-width: 992px) {
  .user-card .user-about-block {
    margin-top: -10px; }
  .user-card .wid-80 {
    width: 60px; }
  .user-card .change-profile .profile-dp {
    width: 90px;
    height: 90px; }
  .user-card.user-card-2.shape-right .cover-img-block {
    clip-path: none; }
  .user-card .cover-img-block .change-cover .dropdown-toggle {
    padding: 5px 7px; } }

.tab-content .btn.btn-sm.float-end i, .tab-content .btn-sm.float-end.introjs-button i {
  font-size: 14px; }

.user-card-2 .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%); }

.user-card-2.shape-right .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%); }

.user-card-2.shape-center .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%); }

.user-card-3 .certificated-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  padding: 5px 3px; }
  .user-card-3 .certificated-badge .bg-icon {
    font-size: 30px; }
  .user-card-3 .certificated-badge .front-icon {
    font-size: 14px;
    position: absolute;
    top: 14px;
    left: 13px; }

.user-card-3 .social-top-link {
  position: absolute;
  top: 20px;
  left: 20px; }

.user-card-3.social-hover .social-top-link {
  top: 20px;
  left: 20px;
  overflow: hidden; }
  .user-card-3.social-hover .social-top-link .btn, .user-card-3.social-hover .social-top-link .introjs-button,
  .user-card-3.social-hover .social-top-link li {
    margin-left: -100px;
    transition: all 0.2s ease-in-out; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(1), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(1),
    .user-card-3.social-hover .social-top-link li:nth-child(1) {
      transition-delay: 0.05s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(2), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(2),
    .user-card-3.social-hover .social-top-link li:nth-child(2) {
      transition-delay: 0.1s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(3), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(3),
    .user-card-3.social-hover .social-top-link li:nth-child(3) {
      transition-delay: 0.15s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(4), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(4),
    .user-card-3.social-hover .social-top-link li:nth-child(4) {
      transition-delay: 0.2s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(5), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(5),
    .user-card-3.social-hover .social-top-link li:nth-child(5) {
      transition-delay: 0.25s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(6), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(6),
    .user-card-3.social-hover .social-top-link li:nth-child(6) {
      transition-delay: 0.3s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(7), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(7),
    .user-card-3.social-hover .social-top-link li:nth-child(7) {
      transition-delay: 0.35s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(8), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(8),
    .user-card-3.social-hover .social-top-link li:nth-child(8) {
      transition-delay: 0.4s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(9), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(9),
    .user-card-3.social-hover .social-top-link li:nth-child(9) {
      transition-delay: 0.45s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(10), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(10),
    .user-card-3.social-hover .social-top-link li:nth-child(10) {
      transition-delay: 0.5s; }

.user-card-3.social-hover:hover .social-top-link .btn, .user-card-3.social-hover:hover .social-top-link .introjs-button,
.user-card-3.social-hover:hover .social-top-link li {
  margin-left: 0; }

.user-profile {
  margin-top: -85px;
  margin-left: -30px;
  margin-right: -30px;
  background: #fff; }
  .user-profile .card-body {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px -2px rgba(54, 80, 138, 0.3); }
    .user-profile .card-body .overlay > span {
      cursor: pointer; }
  .user-profile .cover-img-block:hover > img {
    filter: grayscale(1) blur(1px); }
  .user-profile .profile-dp .overlay {
    text-align: left; }
  .user-profile .user-about-block .certificated-badge {
    bottom: 8px;
    right: 0;
    z-index: 2; }
  .user-profile .profile-tabs {
    border-bottom: 0;
    margin-top: 30px; }
    .user-profile .profile-tabs .nav-item {
      margin-bottom: -0px; }
      .user-profile .profile-tabs .nav-item .nav-link {
        position: relative; }
        .user-profile .profile-tabs .nav-item .nav-link:after {
          content: "";
          background: #15C042;
          position: absolute;
          transition: all 0.3s ease-in-out;
          left: 50%;
          right: 50%;
          bottom: -1px;
          height: 2px;
          border-radius: 2px 2px 0 0; }
    .user-profile .profile-tabs .nav-link:hover {
      border-color: transparent; }
    .user-profile .profile-tabs .nav-item.show .nav-link,
    .user-profile .profile-tabs .nav-link.active {
      border-color: transparent; }
      .user-profile .profile-tabs .nav-item.show .nav-link:after,
      .user-profile .profile-tabs .nav-link.active:after {
        left: 0;
        right: 0; }
  @media only screen and (max-width: 575px) {
    .user-profile {
      margin-top: -30px;
      margin-left: -15px;
      margin-right: -15px; } }

.user-profile-list {
  background: transparent;
  box-shadow: none; }
  .user-profile-list table {
    border-spacing: 0 10px;
    width: calc(100% - 10px);
    margin: 0 5px; }
    .user-profile-list table tbody tr {
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
      border-radius: 0;
      position: relative; }
      .user-profile-list table tbody tr .overlay-edit {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        background: #15C042;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .user-profile-list table tbody tr .overlay-edit .btn, .user-profile-list table tbody tr .overlay-edit .introjs-button {
          margin: 0 3px;
          width: 35px;
          height: 35px;
          display: inline-flex;
          align-items: center;
          justify-content: center; }
      .user-profile-list table tbody tr:hover {
        box-shadow: 0 0 6px 0 #15C042; }
        .user-profile-list table tbody tr:hover td {
          cursor: pointer;
          color: #fff;
          background: #15C042; }
          .user-profile-list table tbody tr:hover td h6 {
            color: #fff; }
          .user-profile-list table tbody tr:hover td .overlay-edit {
            opacity: 1; }
    .user-profile-list table tr td,
    .user-profile-list table tr th {
      vertical-align: middle;
      border: none; }
    .user-profile-list table tr td {
      background: #fff;
      position: relative; }
      .user-profile-list table tr td:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .user-profile-list table tr td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }

.widget-profile-card-1 {
  position: relative;
  margin-bottom: 50px; }
  .widget-profile-card-1 .middle-user {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    bottom: -60px; }
    .widget-profile-card-1 .middle-user img {
      width: 115px;
      border-radius: 50%; }
  .widget-profile-card-1 ~ .card-footer [class*=col-] {
    border-right: 1px solid #fff; }
    .widget-profile-card-1 ~ .card-footer [class*=col-]:last-child {
      border: none; }
  @media only screen and (max-width: 767px) {
    .widget-profile-card-1 ~ .card-footer [class*=col-]:nth-child(2) {
      border: 0; }
    .widget-profile-card-1 ~ .card-footer [class*=col-]:last-child {
      margin-top: 10px; } }

.widget-profile-card-2 img.user-img {
  width: 115px; }

.widget-profile-card-2 a {
  color: #fff;
  transition: all ease-in 0.3s; }
  .widget-profile-card-2 a:hover {
    opacity: 0.7; }

.widget-profile-card-3 {
  background-size: cover;
  padding: 50px 0;
  text-align: center; }
  .widget-profile-card-3 img {
    width: 120px;
    border-radius: 50%; }

.social-res-card .progress {
  height: 6px; }

.profile-card {
  position: relative;
  min-height: 410px;
  overflow: hidden; }
  .profile-card img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .profile-card .card-body {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.72)); }
    .profile-card .card-body h3 {
      font-weight: 600; }
  .profile-card ~ .card-footer [class*="col-"] {
    padding: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.57); }
    .profile-card ~ .card-footer [class*="col-"] h4 {
      font-size: 18px;
      font-weight: 600; }
    .profile-card ~ .card-footer [class*="col-"]:last-child {
      border: none; }
    .profile-card ~ .card-footer [class*="col-"] span {
      text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .profile-card ~ .card-footer [class*="col-"]:nth-child(2) {
      border: none; } }

.blur-user-card {
  color: #fff;
  text-align: center;
  padding-top: 45px;
  background-image: url("../images/widget/blur-bg.png");
  background-size: cover; }
  .blur-user-card h3 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600; }
  .blur-user-card p {
    font-size: 18px;
    font-weight: 300; }
  .blur-user-card .blur-footer [class*="col-"] {
    padding: 1em;
    border-right: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 30px; }
    .blur-user-card .blur-footer [class*="col-"]:last-child {
      border-right: none; }
  @media only screen and (max-width: 767px) {
    .blur-user-card .blur-footer [class*="col-"]:nth-child(2) {
      border: 0; }
    .blur-user-card .blur-footer [class*="col-"]:last-child {
      margin-top: 0;
      padding-top: 0; } }

/**====== user card css end ======**/
/**  =====================
      user card css start
==========================  **/
.user-card .dropdown-toggle {
  cursor: pointer; }

.user-card .cover-img-block {
  position: relative; }
  .user-card .cover-img-block > img {
    width: 100%; }
  .user-card .cover-img-block .change-cover {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5;
    opacity: 0;
    transition: all 0.3s ease-in-out; }
    .user-card .cover-img-block .change-cover .dropdown-toggle {
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-card .cover-img-block .change-cover .dropdown-toggle:after {
        display: none; }
  .user-card .cover-img-block .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out; }
  .user-card .cover-img-block:hover .change-cover,
  .user-card .cover-img-block:hover .overlay {
    opacity: 1; }

.user-card .user-about-block {
  margin-top: -20px;
  z-index: 1;
  position: relative; }
  .user-card .user-about-block img {
    box-shadow: 0 0 0 5px #fff; }
  .user-card .user-about-block .certificated-badge {
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 5px 3px; }
    .user-card .user-about-block .certificated-badge .bg-icon {
      font-size: 22px; }
    .user-card .user-about-block .certificated-badge .front-icon {
      font-size: 11px;
      position: absolute;
      top: 11px;
      left: 9px; }

.user-card .hover-data {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 46, 62, 0.92);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.3s ease-in-out; }

.user-card:hover .hover-data {
  transform: scale(1); }

.user-card .change-profile .dropdown-toggle::after {
  display: none; }

.user-card .change-profile .profile-dp {
  position: relative;
  overflow: hidden;
  padding: 5px;
  width: 110px;
  height: 110px;
  border-radius: 50%; }
  .user-card .change-profile .profile-dp .overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50%;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-in-out; }
    .user-card .change-profile .profile-dp .overlay span {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0 0 5px; }
  .user-card .change-profile .profile-dp:hover .overlay {
    opacity: 1; }

@media only screen and (max-width: 992px) {
  .user-card .user-about-block {
    margin-top: -10px; }
  .user-card .wid-80 {
    width: 60px; }
  .user-card .change-profile .profile-dp {
    width: 90px;
    height: 90px; }
  .user-card.user-card-2.shape-right .cover-img-block {
    clip-path: none; }
  .user-card .cover-img-block .change-cover .dropdown-toggle {
    padding: 5px 7px; } }

.tab-content .btn.btn-sm.float-end i, .tab-content .btn-sm.float-end.introjs-button i {
  font-size: 14px; }

.user-card-2 .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%); }

.user-card-2.shape-right .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%); }

.user-card-2.shape-center .cover-img-block {
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%); }

.user-card-3 .certificated-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  padding: 5px 3px; }
  .user-card-3 .certificated-badge .bg-icon {
    font-size: 30px; }
  .user-card-3 .certificated-badge .front-icon {
    font-size: 14px;
    position: absolute;
    top: 14px;
    left: 13px; }

.user-card-3 .social-top-link {
  position: absolute;
  top: 20px;
  left: 20px; }

.user-card-3.social-hover .social-top-link {
  top: 20px;
  left: 20px;
  overflow: hidden; }
  .user-card-3.social-hover .social-top-link .btn, .user-card-3.social-hover .social-top-link .introjs-button,
  .user-card-3.social-hover .social-top-link li {
    margin-left: -100px;
    transition: all 0.2s ease-in-out; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(1), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(1),
    .user-card-3.social-hover .social-top-link li:nth-child(1) {
      transition-delay: 0.05s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(2), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(2),
    .user-card-3.social-hover .social-top-link li:nth-child(2) {
      transition-delay: 0.1s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(3), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(3),
    .user-card-3.social-hover .social-top-link li:nth-child(3) {
      transition-delay: 0.15s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(4), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(4),
    .user-card-3.social-hover .social-top-link li:nth-child(4) {
      transition-delay: 0.2s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(5), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(5),
    .user-card-3.social-hover .social-top-link li:nth-child(5) {
      transition-delay: 0.25s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(6), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(6),
    .user-card-3.social-hover .social-top-link li:nth-child(6) {
      transition-delay: 0.3s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(7), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(7),
    .user-card-3.social-hover .social-top-link li:nth-child(7) {
      transition-delay: 0.35s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(8), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(8),
    .user-card-3.social-hover .social-top-link li:nth-child(8) {
      transition-delay: 0.4s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(9), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(9),
    .user-card-3.social-hover .social-top-link li:nth-child(9) {
      transition-delay: 0.45s; }
    .user-card-3.social-hover .social-top-link .btn:nth-child(10), .user-card-3.social-hover .social-top-link .introjs-button:nth-child(10),
    .user-card-3.social-hover .social-top-link li:nth-child(10) {
      transition-delay: 0.5s; }

.user-card-3.social-hover:hover .social-top-link .btn, .user-card-3.social-hover:hover .social-top-link .introjs-button,
.user-card-3.social-hover:hover .social-top-link li {
  margin-left: 0; }

.user-profile {
  margin-top: -85px;
  margin-left: -30px;
  margin-right: -30px;
  background: #fff; }
  .user-profile .card-body {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px -2px rgba(54, 80, 138, 0.3); }
    .user-profile .card-body .overlay > span {
      cursor: pointer; }
  .user-profile .cover-img-block:hover > img {
    filter: grayscale(1) blur(1px); }
  .user-profile .profile-dp .overlay {
    text-align: left; }
  .user-profile .user-about-block .certificated-badge {
    bottom: 8px;
    right: 0;
    z-index: 2; }
  .user-profile .profile-tabs {
    border-bottom: 0;
    margin-top: 30px; }
    .user-profile .profile-tabs .nav-item {
      margin-bottom: -0px; }
      .user-profile .profile-tabs .nav-item .nav-link {
        position: relative; }
        .user-profile .profile-tabs .nav-item .nav-link:after {
          content: "";
          background: #15C042;
          position: absolute;
          transition: all 0.3s ease-in-out;
          left: 50%;
          right: 50%;
          bottom: -1px;
          height: 2px;
          border-radius: 2px 2px 0 0; }
    .user-profile .profile-tabs .nav-link:hover {
      border-color: transparent; }
    .user-profile .profile-tabs .nav-item.show .nav-link,
    .user-profile .profile-tabs .nav-link.active {
      border-color: transparent; }
      .user-profile .profile-tabs .nav-item.show .nav-link:after,
      .user-profile .profile-tabs .nav-link.active:after {
        left: 0;
        right: 0; }
  @media only screen and (max-width: 575px) {
    .user-profile {
      margin-top: -30px;
      margin-left: -15px;
      margin-right: -15px; } }

.user-profile-list {
  background: transparent;
  box-shadow: none; }
  .user-profile-list table {
    border-spacing: 0 10px;
    width: calc(100% - 10px);
    margin: 0 5px; }
    .user-profile-list table tbody tr {
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
      border-radius: 0;
      position: relative; }
      .user-profile-list table tbody tr .overlay-edit {
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        background: #15C042;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .user-profile-list table tbody tr .overlay-edit .btn, .user-profile-list table tbody tr .overlay-edit .introjs-button {
          margin: 0 3px;
          width: 35px;
          height: 35px;
          display: inline-flex;
          align-items: center;
          justify-content: center; }
      .user-profile-list table tbody tr:hover {
        box-shadow: 0 0 6px 0 #15C042; }
        .user-profile-list table tbody tr:hover td {
          cursor: pointer;
          color: #fff;
          background: #15C042; }
          .user-profile-list table tbody tr:hover td h6 {
            color: #fff; }
          .user-profile-list table tbody tr:hover td .overlay-edit {
            opacity: 1; }
    .user-profile-list table tr td,
    .user-profile-list table tr th {
      vertical-align: middle;
      border: none; }
    .user-profile-list table tr td {
      background: #fff;
      position: relative; }
      .user-profile-list table tr td:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .user-profile-list table tr td:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }

.widget-profile-card-1 {
  position: relative;
  margin-bottom: 50px; }
  .widget-profile-card-1 .middle-user {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    bottom: -60px; }
    .widget-profile-card-1 .middle-user img {
      width: 115px;
      border-radius: 50%; }
  .widget-profile-card-1 ~ .card-footer [class*=col-] {
    border-right: 1px solid #fff; }
    .widget-profile-card-1 ~ .card-footer [class*=col-]:last-child {
      border: none; }
  @media only screen and (max-width: 767px) {
    .widget-profile-card-1 ~ .card-footer [class*=col-]:nth-child(2) {
      border: 0; }
    .widget-profile-card-1 ~ .card-footer [class*=col-]:last-child {
      margin-top: 10px; } }

.widget-profile-card-2 img.user-img {
  width: 115px; }

.widget-profile-card-2 a {
  color: #fff;
  transition: all ease-in 0.3s; }
  .widget-profile-card-2 a:hover {
    opacity: 0.7; }

.widget-profile-card-3 {
  background-size: cover;
  padding: 50px 0;
  text-align: center; }
  .widget-profile-card-3 img {
    width: 120px;
    border-radius: 50%; }

.social-res-card .progress {
  height: 6px; }

.profile-card {
  position: relative;
  min-height: 410px;
  overflow: hidden; }
  .profile-card img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .profile-card .card-body {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.72)); }
    .profile-card .card-body h3 {
      font-weight: 600; }
  .profile-card ~ .card-footer [class*="col-"] {
    padding: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.57); }
    .profile-card ~ .card-footer [class*="col-"] h4 {
      font-size: 18px;
      font-weight: 600; }
    .profile-card ~ .card-footer [class*="col-"]:last-child {
      border: none; }
    .profile-card ~ .card-footer [class*="col-"] span {
      text-transform: uppercase; }
  @media only screen and (max-width: 767px) {
    .profile-card ~ .card-footer [class*="col-"]:nth-child(2) {
      border: none; } }

.blur-user-card {
  color: #fff;
  text-align: center;
  padding-top: 45px;
  background-image: url("../images/widget/blur-bg.png");
  background-size: cover; }
  .blur-user-card h3 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600; }
  .blur-user-card p {
    font-size: 18px;
    font-weight: 300; }
  .blur-user-card .blur-footer [class*="col-"] {
    padding: 1em;
    border-right: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 30px; }
    .blur-user-card .blur-footer [class*="col-"]:last-child {
      border-right: none; }
  @media only screen and (max-width: 767px) {
    .blur-user-card .blur-footer [class*="col-"]:nth-child(2) {
      border: 0; }
    .blur-user-card .blur-footer [class*="col-"]:last-child {
      margin-top: 0;
      padding-top: 0; } }

.list-pills.nav-pills .nav-link {
  border-radius: 0; }

.list-pills.nav-pills .nav-link.active,
.list-pills.nav-pills .show > .nav-link {
  color: #fff;
  box-shadow: none;
  border-color: transparent;
  background: #15C042; }
  .list-pills.nav-pills .nav-link.active .h5,
  .list-pills.nav-pills .nav-link.active .h6,
  .list-pills.nav-pills .show > .nav-link .h5,
  .list-pills.nav-pills .show > .nav-link .h6 {
    color: #fff; }

.user-card .nav-pills {
  padding: 0;
  box-shadow: none; }

.user-about-block .nav-tabs .nav-item.show .nav-link, .user-about-block .nav-tabs .nav-link.active {
  box-shadow: none; }

.tab-content.shadow-none .btn i, .tab-content.shadow-none .introjs-button i {
  margin-right: 0; }

.new-cust-card img {
  width: 40px; }

.new-cust-card h6 {
  margin-bottom: 0; }

.new-cust-card .align-middle {
  position: relative; }
  .new-cust-card .align-middle .status {
    position: absolute;
    right: 0;
    top: 19px;
    font-size: 13px; }
    .new-cust-card .align-middle .status.active {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #15C042; }

/**====== user card css end ======**/
.btn_style, .btn-outline-secondary {
  color: white;
  font-weight: 700;
  padding: 10px 51px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  width: 189px;
  background: linear-gradient(180deg, #15C042 48.78%, #0BBB39 48.78%);
  border-radius: 10px; }

.text-right {
  text-align: right; }

.btn-primary, .introjs-button {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5; }

.select-selected {
  border: 1px solid rgba(203, 203, 203, 0.5);
  border-radius: 3px;
  position: relative;
  width: 74px;
  height: 32px; }

.select-items div, .select-selected {
  padding: 6px 16px;
  cursor: pointer;
  user-select: none; }

table.dataTable {
  width: 100% !important;
  border-spacing: 0;
  border: 1px solid #15C042;
  border-radius: 14px;
  overflow: hidden;
  border-right-width: 1px !important; }

.table-striped > tbody > tr.odd > * {
  box-shadow: none; }

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  text-align: center !important;
  padding: 7px 10px !important;
  vertical-align: middle; }

table.table-bordered.dataTable th {
  height: 32px;
  background-color: #14c041 !important;
  border-color: #14c041 !important;
  color: white !important;
  font-weight: bold !important;
  font-family: "GraphieFontBold"; }

table.table-bordered.dataTable thead {
  background-color: #F4F5F7; }

table.table-bordered.dataTable tbody {
  background-color: white; }

.lineAreaDashboard g text title, tspan {
  font: 14px normal GraphieFont, sans-serif; }

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #E8F9ED; }

.page-item.active .page-link {
  background: #15C042;
  border-color: #15C042; }

.page-link {
  color: #15C042; }

.page-link:hover {
  color: #15C042; }

.dataTables_length {
  position: relative; }

.dataTables_length select {
  display: none; }

.select-selected {
  position: relative;
  width: 74px;
  height: 32px; }

.select-selected:after {
  position: absolute;
  content: "";
  top: 12px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #212529 transparent transparent transparent; }

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #212529 transparent;
  top: 7px; }

.select-items div, .select-selected {
  padding: 6px 16px;
  cursor: pointer;
  user-select: none; }

.select-selected {
  border: 1px solid rgba(203, 203, 203, 0.5);
  border-radius: 3px; }

.select-items {
  position: absolute;
  color: #212529;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  width: 74px;
  border: 1px solid #15C042; }

.select-selected.select-arrow-active {
  border-color: #15C042; }

.select-hide {
  display: none; }

.select-items div:hover,
.same-as-selected {
  background-color: #15C042; }

.card-dashboard {
  height: 107px;
  text-align: center;
  border-radius: 14px 14px 53.5px 14px;
  padding-top: 21px; }

.card-pt-info {
  border-radius: 14px 14px 14px 14px;
  padding: 21px 10px 11px 0; }

.card-pt-info p {
  margin-bottom: 10px;
  color: black; }

.card-block h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 !important; }

.card-dashboard-num {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  font-family: "GraphieFontBold", serif;
  letter-spacing: 0.01em;
  color: #FFFFFF; }

.card-dashboard-num:hover {
  color: white; }

.card-block.card-1 {
  background-color: #FFC04D; }

.card-block.card-2 {
  background-color: #EF3A3E; }

.card-block.card-3 {
  background-color: #5D97D1; }

.card-block.card-4 {
  background-color: #FF6F51; }

.card {
  box-shadow: none;
  background-color: transparent;
  border: none;
  color: black;
  border-radius: 14px; }

div.cart_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 349px;
  border: 1px solid #BFBFBF;
  padding: 5px;
  margin-top: 250px; }

div.cart_explain {
  width: 268px; }

div.cart_part img {
  height: 40px; }

div.cart_explain bold {
  margin-left: 51px;
  margin-top: 25px;
  font-size: 15px;
  color: #6CA1FA; }

td:nth-of-type(3) a {
  color: #0a58ca; }

td:nth-of-type(3) a:hover {
  text-decoration: underline; }

/*td:nth-of-type(4) a {
  width: 79px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 6px 12px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: $d-btn-color;
}

td:nth-of-type(4) a:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}*/
.child_row {
  position: relative;
  min-height: 30px; }

.btn_groups {
  justify-content: center !important; }

.btn_groups button {
  width: 79px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
  margin-right: 10px; }

.btn_groups button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.btn_groups button.green {
  background: linear-gradient(180deg, #15C042 48.78%, #0BBB39 48.78%) !important; }

.btn_groups .open_btn {
  cursor: not-allowed; }

.btn_groups .open_btn:hover {
  box-shadow: none; }

.btn_groups button.yellow:hover {
  box-shadow: none; }

.btn_groups button.yellow {
  white-space: pre-wrap !important;
  background: #ffc107 !important;
  cursor: not-allowed; }

.btn_groups button.red {
  background: linear-gradient(180deg, #DC3545 48.78%, #d92638 48.78%) !important;
  white-space: pre-wrap !important; }

.btn_groups button.default_btn {
  background-color: gray; }

tbody tr {
  cursor: pointer; }

.btn-action {
  color: white;
  font-weight: 700;
  padding: 10px 41px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  width: 189px;
  background: linear-gradient(180deg, #15C042 48.78%, #0BBB39 48.78%);
  border-radius: 10px; }

.btn-inline-action {
  color: #7d939c;
  padding: 0;
  border: 0;
  font-size: 16px;
  background: transparent;
  outline-color: transparent;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "GraphieFontBold", sans-serif;
  border: none; }

.piechat-header_items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 13px;
  padding-bottom: 5px; }

.add_other_box {
  margin: initial; }

.reports .title {
  margin-bottom: 34px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #000000;
  font-family: GraphieFontBold, sans-serif; }

.reports .reports_search_form label {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: GraphieFontBold, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: #000000; }

.reports table th {
  height: 32px; }

.reports table th,
.reports table td {
  vertical-align: middle; }

.reports .search_result table {
  width: 100% !important;
  border-spacing: 0;
  border: 1px solid #15C042;
  border-radius: 14px;
  overflow: hidden;
  border-right-width: 1px !important; }

.reports .search_result th,
.reports .search_result td {
  text-align: center !important;
  padding: 8px 11px !important;
  vertical-align: middle; }

.reports .search_result th {
  font-family: GraphieFontBold; }

.reports .search_result th:nth-of-type(2), .reports .search_result th:nth-of-type(3) {
  padding-right: 28px !important; }

.reports .search_result thead {
  background-color: #F4F5F7; }

.reports .search_result tbody {
  background-color: white; }

.reports .search_result .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #E8F9ED; }

.reports .search_result table#total_plans_table tbody td:nth-of-type(1),
.reports .search_result table#total_balance_table tbody td:nth-of-type(1) {
  text-align: left !important; }

.reports .search_result table#total_balance_table thead {
  display: none; }

.reports #div_id_show_all {
  margin-top: 2.5rem; }

.reports .invalid-feedback {
  margin-top: -0.75rem;
  margin-bottom: 0.25rem; }

.reports table.dataTable {
  margin-top: 15px !important; }

.select2-container--default .select2-selection--multiple {
  height: 40px; }

#add_fund .select2-container .select2-selection--single, .reports .select2-container .select2-selection--single, #manage_funds .select2-container .select2-selection--single {
  height: 40px; }

#add_fund .select2-container--default .select2-selection--single .select2-selection__rendered, .reports .select2-container--default .select2-selection--single .select2-selection__rendered, #manage_funds .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px; }

#add_fund .select2-container--default .select2-selection--single .select2-selection__arrow, .reports .select2-container--default .select2-selection--single .select2-selection__arrow, #manage_funds .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px; }

.page-link {
  color: #15C042; }
  .page-link.active {
    color: #15C042;
    background-color: white;
    border-color: #dee2e6; }
    .page-link.active:hover {
      background-color: #e9ecef; }

.div__logout__btn {
  text-align: center;
  width: 189px;
  margin: 19px auto; }

.div_breadcrumb {
  display: flex;
  align-items: center; }

.page_content {
  width: 100%;
  background-color: white;
  border-radius: 14px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08) !important;
  padding: 20px; }

.master_setting_title {
  text-align: center;
  text-transform: initial !important;
  margin-top: 14px;
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #15C042;
  font-family: GraphieFontBold; }

.master_setting .form-group {
  display: flex;
  align-items: center; }
  .master_setting .form-group input {
    margin: 0; }
  .master_setting .form-group .title {
    text-align: end; }
  .master_setting .form-group .input {
    display: flex;
    align-items: center; }

.master_setting .form-action {
  display: flex;
  justify-content: center; }

.master_setting .toggle {
  --width: 80px;
  --height: calc(var(--width) / 3);
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer; }

.master_setting .toggle input {
  display: none; }

.master_setting .toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #15C042;
  transition: all 0.4s ease-in-out; }

.master_setting .toggle .slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--height));
  height: calc(var(--height));
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out; }

.master_setting .toggle input:checked + .slider {
  background-color: #15C042; }

.master_setting .toggle input:checked + .slider::before {
  transform: translateX(calc(var(--width) - var(--height))); }

.master_setting .toggle .labels {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out; }

.master_setting .toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  color: #ffffff;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out; }

.master_setting .toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out; }

.master_setting .toggle input:checked ~ .labels::after {
  opacity: 0; }

.master_setting .toggle input:checked ~ .labels::before {
  opacity: 1; }

.master_setting .btn-notification {
  background: linear-gradient(180deg, #DC3545 48.78%, #d92638 48.78%);
  margin-left: 10px; }

.master_setting .btn-fee {
  background: linear-gradient(180deg, #dcd935 48.78%, #d9c726 48.78%);
  margin-left: 10px; }

.master_setting .btn-permission {
  background: linear-gradient(180deg, #3562dc 48.78%, #2650d9 48.78%);
  margin-left: 10px; }

.pcoded-main-container.payroll form {
  width: 100%; }

.pcoded-main-container.pa_bank form {
  width: 100%;
  box-shadow: none; }

.pcoded-main-container.pa_bank .btn-part {
  display: flex; }

.pcoded-main-container.pa_bank .delete_bank_btn {
  background: linear-gradient(180deg, #DC3545 48.78%, #d92638 48.78%); }

.auth-wrapper {
  background: #F4FAFF; }
  .auth-wrapper .card-body {
    text-align: center; }
  .auth-wrapper .content {
    width: 100%;
    text-align: center; }
  .auth-wrapper form {
    width: 80%;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    padding: 53px 41px 29px 41px;
    margin: auto; }
  .auth-wrapper .input-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 80%;
    margin: auto; }
  .auth-wrapper svg {
    margin-right: 14px;
    z-index: 99; }
  .auth-wrapper img {
    width: 346px;
    margin: auto; }
  .auth-wrapper .form-control {
    display: inline !important;
    width: 388px !important;
    height: 40px !important;
    background: #F4FAFF;
    border: 1px solid #E0E0E0 !important;
    border-radius: 10px !important;
    padding-left: 40px; }
  .auth-wrapper .login_title {
    font-family: GraphieFontBold;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #15C042;
    margin: auto;
    margin-bottom: 40px;
    padding: 0; }
  .auth-wrapper button {
    padding: 10px;
    gap: 10px;
    background: linear-gradient(180deg, #15C042 48.78%, #0BBB39 48.78%);
    border-radius: 10px;
    border-color: #15C042; }
  .auth-wrapper .save_credential {
    padding: 0;
    margin: 29px 50px; }
  .auth-wrapper svg {
    position: absolute;
    left: 7px; }
  .auth-wrapper .form-control:focus {
    background-color: unset; }
  .auth-wrapper .password svg {
    left: 12px !important; }

.pdf-preview-dialog {
  max-width: 90vw; }
  .pdf-preview-dialog .modal-content {
    height: 100%; }
    .pdf-preview-dialog .modal-content .preview-header {
      height: 64px;
      padding: 12px 7px 12px 12px;
      background: #000000b3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 7px 7px 0 0; }
      .pdf-preview-dialog .modal-content .preview-header .title {
        font-size: 15px;
        font-weight: bold;
        color: white; }
    .pdf-preview-dialog .modal-content .preview-action {
      height: 64px;
      border-radius: 0;
      border-top-color: grey;
      border-top-width: 1px;
      border-top-style: solid;
      color: white;
      margin: 0;
      padding: 12px 7px 12px 12px;
      background: #000000b3;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .pdf-preview-dialog .modal-content .preview-action input {
        height: 34px;
        margin-left: 5px; }
    .pdf-preview-dialog .modal-content .preview-footer {
      height: 52px;
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: #000000b3;
      border-radius: 0 0 7px 7px; }

.btn.btn-save, .btn-save.introjs-button {
  background: white;
  padding: 8px 16px; }

.bg-green {
  background-color: #15C042; }

.bg-red {
  background-color: #dd3546; }

.bg-yellow {
  background-color: #e4ae28; }

.bg-dark-yellow {
  background-color: #c69419; }

.w-max-content {
  width: max-content; }

.text-secondary-2 {
  color: #7d939c; }

.text-yellow {
  color: #FFC04D; }

.graphie-bold {
  font-family: "GraphieFontBold", sans-serif; }

a.danger,
button.danger {
  background: linear-gradient(180deg, #DC3545 48.78%, #d92638 48.78%); }
  a.danger:hover,
  button.danger:hover {
    color: #fff !important; }

a.disabled,
button.disabled {
  cursor: not-allowed;
  opacity: 0.6; }

.fixed-width-cta-group {
  width: max-content; }
  .fixed-width-cta-group a,
  .fixed-width-cta-group button {
    width: auto;
    min-width: 300px;
    max-width: 350px;
    text-align: center; }
  .fixed-width-cta-group-medium a,
  .fixed-width-cta-group-medium button {
    max-width: 290px;
    padding: 8px 20px;
    line-height: 1.2; }
  .fixed-width-cta-group-small a,
  .fixed-width-cta-group-small button {
    min-width: 100px;
    max-width: 250px; }

.currency-input {
  min-width: 150px;
  width: 30% !important;
  font-size: 25px;
  border-radius: 0 !important; }
  .currency-input.small {
    font-size: 17px; }

.colored-bullet-list {
  list-style: none; }
  .colored-bullet-list li {
    display: flex;
    align-items: flex-start;
    gap: 5px; }
    .colored-bullet-list li::before {
      content: "";
      height: 8px;
      width: 8px;
      background-color: #15C042;
      display: inline-block;
      border-radius: 50%;
      margin-top: 6px;
      flex-shrink: 0; }

.dataTable.w-max-content {
  max-width: max-content !important; }

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none; }
  .hide-scrollbar::-webkit-scrollbar {
    display: none; }

.amount-to-be-invested-value {
  display: inline-block;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #15C042; }

.hsa-table-container {
  width: max-content !important;
  min-width: 700px; }
  .hsa-table-container .table-heading {
    background-color: #e4ae28;
    color: #fff; }
  .hsa-table-container .table-sub-heading {
    border: 1px solid #e4ae28;
    border-bottom: none; }
  .hsa-table-container table.table-bordered.dataTable {
    margin-top: 0 !important;
    border: 1px solid #e4ae28;
    border-radius: 0; }
    .hsa-table-container table.table-bordered.dataTable th {
      background-color: #fff !important;
      color: #e4ae28 !important;
      border-color: #fff !important; }
    .hsa-table-container table.table-bordered.dataTable td {
      border: none;
      background-color: #fff !important; }

#recurring-payment-form {
  color: #fff;
  background-color: #077D22;
  padding: 10px 30px 30px 100px;
  margin-left: -80px;
  z-index: 0;
  border-radius: 2em;
  font-family: "GraphieFontBold", sans-serif;
  line-height: 1.75; }
  #recurring-payment-form p {
    color: #fff;
    margin-bottom: 0; }
  #recurring-payment-form .disabled p {
    color: #d6d6d6; }
  #recurring-payment-form input,
  #recurring-payment-form select {
    font-family: "GraphieFont", sans-serif;
    line-height: 1.5; }
  #recurring-payment-form input.small {
    padding: 0;
    max-width: 25px;
    text-align: center;
    margin: 0; }

.option-set {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 12px; }
  .option-set input[type="radio"] {
    margin-top: 5px; }

#calendar-payment-date {
  position: relative;
  z-index: 1; }

.amount-box-container {
  display: flex;
  gap: 10px;
  align-items: center; }

.amount-list-box {
  border: 1px solid #FFC04D;
  width: 210px; }
  .amount-list-box > p {
    color: #FFC04D;
    font-family: "GraphieFontBold", sans-serif;
    padding: 5px 10px;
    margin: 0; }

.amount-container {
  height: 120px;
  overflow: auto;
  font-size: 20px; }
  .amount-container div {
    padding: 0 10px; }
    .amount-container div.receipt-amount {
      cursor: pointer; }
    .amount-container div.--selected {
      background-color: #FFC04D;
      color: #fff; }

.add-arrow {
  position: relative;
  background-color: #15C042;
  color: #fff;
  font-family: "GraphieFontBold", sans-serif;
  font-size: 10px;
  padding: 8px 0 8px 10px;
  margin-right: 25px; }
  .add-arrow span {
    position: relative; }
  .add-arrow::before {
    content: "";
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 30px solid #15C042;
    position: absolute;
    z-index: 0;
    left: 75%;
    top: 50%;
    transform: translateY(-50%); }

#animateModal .btn.btn-outline-secondary, #animateModal .btn-outline-secondary.introjs-button {
  background: linear-gradient(180deg, #DC3545 48.78%, #d92638 48.78%);
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  width: auto; }

#animateModal .modal-header {
  background: #15C042; }

#animateModal .modal-body {
  font-size: 16px;
  text-transform: uppercase;
  color: #000000; }

#animateModal .btn.btn-outline-secondary:hover, #animateModal .btn-outline-secondary.introjs-button:hover,
.yes_btn:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.yes_btn {
  background: linear-gradient(180deg, #15C042 48.78%, #0BBB39 48.78%);
  color: #fff !important;
  border-radius: 10px;
  border: none; }
