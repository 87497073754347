.card-dashboard {
  height: 107px;
  text-align: center;
  border-radius: 14px 14px 53.5px 14px;
  padding-top: 21px;
}

.card-pt-info {
  border-radius: 14px 14px 14px 14px;
  padding: 21px 10px 11px 0;
}

.card-pt-info p {
  margin-bottom: 10px;
  color: black;
}

.card-block h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 !important;
}

.card-dashboard-num {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  font-family: "GraphieFontBold", serif;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.card-dashboard-num:hover {
  color: white;
}

.card-block.card-1 {
  background-color: $card_bg_yellow;
}

.card-block.card-2 {
  background-color: $card_bg_red;
}

.card-block.card-3 {
  background-color: $card_bg_blue;
}

.card-block.card-4 {
  background-color: $card_bg_orange;
}

.card {
  box-shadow: none;
  background-color: transparent;
  border: none;
  color: black;
  border-radius: 14px;
}

div.cart_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 349px;
  border: 1px solid #BFBFBF;
  padding: 5px;
  margin-top: 250px;
}

div.cart_explain {
  width: 268px;
}

div.cart_part img {
  height: 40px;
}

div.cart_explain bold {
  margin-left: 51px;
  margin-top: 25px;
  font-size: 15px;
  color: #6CA1FA;
}


td:nth-of-type(3) a {
  color: #0a58ca;
}

td:nth-of-type(3) a:hover {
  text-decoration: underline;
}

/*td:nth-of-type(4) a {
  width: 79px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 6px 12px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: $d-btn-color;
}

td:nth-of-type(4) a:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}*/

.child_row {
  position: relative;
  min-height: 30px;
}

.btn_groups {
  justify-content: center !important;
}

.btn_groups button {
  width: 79px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
  margin-right: 10px;
}

.btn_groups button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn_groups button.green {
  background: $btn-color !important;
}

.btn_groups .open_btn {
  cursor: not-allowed;
}

.btn_groups .open_btn:hover {
  box-shadow: none;
}

.btn_groups button.yellow:hover {
  box-shadow: none;
}

.btn_groups button.yellow {
  white-space: pre-wrap !important;
  background: rgba(255,193,7, 1) !important;
  cursor: not-allowed;
}

.btn_groups button.red {
  background: $d-btn-color !important;
  white-space: pre-wrap !important;
}

.btn_groups button.default_btn {
  background-color: gray;
}

tbody tr {
  cursor: pointer;
}

.btn-action {
  color: white;
  font-weight: 700;
  padding: 10px 41px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  width: 189px;
  background: $btn-color;
  border-radius: 10px;
}

.btn-inline-action {
  color: $gray-color;
  padding: 0;
  border: 0;
  font-size: 16px;
  background: transparent;
  outline-color: transparent;
  font-weight: 400;
  text-transform: uppercase;
  font-family: $graphie-bold;
  border: none;
}

.piechat-header_items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 13px;
  padding-bottom: 5px;
}

.add_other_box {
  margin: initial;
}