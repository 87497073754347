.amount-box-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.amount-list-box {
  border: 1px solid $card_bg_yellow;
  width: 210px;

  & > p {
    color: $card_bg_yellow;
    font-family: $graphie-bold;
    padding: 5px 10px;
    margin: 0;
  }
}

.amount-container {
  height: 120px;
  overflow: auto;
  font-size: 20px;

  div {
    padding: 0 10px;

    &.receipt-amount {
      cursor: pointer;
    }

    &.--selected {
      background-color: $card_bg_yellow;
      color: $white-color;
    }
  }
}

.add-arrow {
  position: relative;
  background-color: $success-color;
  color: $white-color;
  font-family: $graphie-bold;
  font-size: 10px;
  padding: 8px 0 8px 10px;
  margin-right: 25px;

  span {
    position: relative;
  }

  &::before {
    content: "";
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 30px solid $success-color;
    position: absolute;
    z-index: 0;
    left: 75%;
    top: 50%;
    transform: translateY(-50%);
  }
}
