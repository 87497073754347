.pcoded-main-container.pa_bank {
    form {
        width: 100%;
        box-shadow: none;
    }
    .btn-part {
        display: flex;
    }
    .delete_bank_btn {
        background: $d-btn-color;
    }
}