.amount-to-be-invested-value {
  display: inline-block;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid $primary-color;
}

.hsa-table-container {
  width: max-content !important;
  min-width: 700px;

  .table-heading {
    background-color: $yellow-color;
    color: $header-light-background;
  }

  .table-sub-heading {
    border: 1px solid $yellow-color;
    border-bottom: none;
  }

  table.table-bordered.dataTable {
    margin-top: 0 !important;
    border: 1px solid $yellow-color;
    border-radius: 0;

    th {
      background-color: $header-light-background !important;
      color: $yellow-color !important;
      border-color: $header-light-background !important;
    }

    td {
      border: none;
      background-color: $header-light-background !important;
    }
  }
}
