.page_content {
    width: 100%;
    background-color: white;
    border-radius: 14px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08) !important;
    padding: 20px;
}

.master_setting_title {
    text-align: center;
    text-transform: initial !important;
    margin-top: 14px;
    margin-bottom: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #15C042;
    font-family: GraphieFontBold;
}

.master_setting {
    .form-group {
        display: flex;
        align-items: center;

        input {
            margin: 0;
        }

        .title {
            text-align: end;
        }

        .input {
            display: flex;
            align-items: center;
        }
    }

    .form-action {
        display: flex;
        justify-content: center;
    }

    .toggle {
        --width: 80px;
        --height: calc(var(--width) / 3);

        position: relative;
        display: inline-block;
        width: var(--width);
        height: var(--height);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: var(--height);
        cursor: pointer;
    }

    .toggle input {
        display: none;
    }

    .toggle .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--height);
        background-color: #15C042;
        transition: all 0.4s ease-in-out;
    }

    .toggle .slider::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(var(--height));
        height: calc(var(--height));
        border-radius: calc(var(--height) / 2);
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider {
        background-color: #15C042;
    }

    .toggle input:checked+.slider::before {
        transform: translateX(calc(var(--width) - var(--height)));
    }

    .toggle .labels {
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-family: sans-serif;
        transition: all 0.4s ease-in-out;
    }

    .toggle .labels::after {
        content: attr(data-off);
        position: absolute;
        right: 5px;
        color: #ffffff;
        opacity: 1;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        transition: all 0.4s ease-in-out;
    }

    .toggle .labels::before {
        content: attr(data-on);
        position: absolute;
        left: 5px;
        color: #ffffff;
        opacity: 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        transition: all 0.4s ease-in-out;
    }

    .toggle input:checked~.labels::after {
        opacity: 0;
    }

    .toggle input:checked~.labels::before {
        opacity: 1;
    }
    
    .btn-notification {
        background: $d-btn-color;
        margin-left: 10px;
    }

    .btn-fee {
        background: $w-btn-color;
        margin-left: 10px;
    }

    .btn-permission {
        background: $p-btn-color;
        margin-left: 10px;
    }
}