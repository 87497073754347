#animateModal.modal.notif-overlay {
    border-spacing: 0;
    border: 1px solid $primary-color !important;
    border-radius: 14px;
    overflow: hidden;

    .modal-dialog {
        max-width: 100%;
        transform: translate(0,0);
        height: 500px;
        .text-white {
            color: black;
        }
        .modal-content {
            div.modal-header {
                background: white !important;
                display: block !important;
            }
            .notif-btn-red {
                background: linear-gradient(180deg, #C8626A 48.78%, #C8424A 48.78%);
                font-weight: bold;
                color: white;
            }
        }
    }
}
