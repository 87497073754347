#animateModal .btn.btn-outline-secondary {
  background: $d-btn-color;
  color: $white-color;
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  width: auto;
}

#animateModal .modal-header {
  background: $primary-color;
}

#animateModal .modal-body {
  font-size: 16px;
  text-transform: uppercase;
  color: $black-color;
}

#animateModal .btn.btn-outline-secondary:hover,
.yes_btn:hover {
  box-shadow: 0px 4px 4px $btn-shadow-color;
}

.yes_btn {
  background: $btn-color;
  color: $white-color !important;
  border-radius: 10px;
  border: none;
}
