// Background color styles
.bg {
  &-green {
    background-color: $primary-color;
  }

  &-red {
    background-color: $red-color;
  }

  &-yellow {
    background-color: $yellow-color;
  }

  &-dark-yellow {
    background-color: $dark-yellow-color;
  }
}

// Width styles
.w {
  &-max-content {
    width: max-content;
  }
}

// text styles
.text-secondary-2 {
  color: $gray-color;
}

.text-yellow {
  color: $card_bg_yellow;
}

.graphie-bold {
  font-family: $graphie-bold;
}

// CTA styles
a,
button {
  &.danger {
    background: $d-btn-color;

    &:hover {
      color: $header-dark-text-color !important;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.fixed-width-cta-group {
  width: max-content;

  a,
  button {
    width: auto;
    min-width: 300px;
    max-width: 350px;
    text-align: center;
  }

  &-medium {
    a,
    button {
      max-width: 290px;
      padding: 8px 20px;
      line-height: 1.2;
    }
  }

  &-small {
    a,
    button {
      min-width: 100px;
      max-width: 250px;
    }
  }
}

// Input styles
.currency-input {
  min-width: 150px;
  width: 30% !important;
  font-size: 25px;
  border-radius: 0 !important;

  &.small {
    font-size: 17px;
  }
}

// List styles
.colored-bullet-list {
  list-style: none;

  li {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    &::before {
      content: "";
      height: 8px;
      width: 8px;
      background-color: $primary-color;
      display: inline-block;
      border-radius: 50%;
      margin-top: 6px;
      flex-shrink: 0;
    }
  }
}

// table styles
.dataTable.w-max-content {
  max-width: max-content !important;
}

// Scrollbar styles
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

